var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"0","color":"deepBlack"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-fley align-center"},[_c('v-text-field',{attrs:{"label":"Search Images","prepend-icon":"mdi-magnify","color":"secondary"},model:{value:(_vm.nameSearch),callback:function ($$v) {_vm.nameSearch=$$v},expression:"nameSearch"}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center"},[_c('v-btn',{staticClass:"mr-4",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('getFiles')}}},[_c('v-icon',{class:_vm.rotateClass,attrs:{"color":"secondary"}},[_vm._v("mdi-refresh")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"deepBlack",attrs:{"multi-sort":"","search":_vm.nameSearch,"loading":_vm.cdnTable.loading,"headers":_vm.cdnTable.headers,"items":_vm.cdnTable.items},scopedSlots:_vm._u([{key:"item.totalSize",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatBytes(item.totalSize))+" ")])]}},{key:"item.highlight",fn:function(ref){
var item = ref.item;
return [(item.highlight != false && item.highlight != true)?_c('span',[_vm._v("N.A.")]):(item.highlight)?_c('v-icon',[_vm._v("mdi-check")]):_c('span',[_vm._v("-")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [(item.title != false && item.title != true)?_c('span',[_vm._v("N.A.")]):(item.title)?_c('v-icon',[_vm._v("mdi-check")]):_c('span',[_vm._v("-")])]}},{key:"item.variants",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","color":"background"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('v-card',{attrs:{"color":"deepBlack"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('span',{style:(_vm.headlineTextStyle)},[_vm._v("File Variants - "+_vm._s(item.name)+" ")])])],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"0","color":"deepBlack"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-fley align-center"},[_c('v-text-field',{attrs:{"label":"Search Variants","prepend-icon":"mdi-magnify","color":"secondary"},model:{value:(_vm.variantSearch),callback:function ($$v) {_vm.variantSearch=$$v},expression:"variantSearch"}})],1)],1)],1),_c('v-data-table',{staticClass:"deepBlack",attrs:{"disable-pagination":"","multi-sort":"","hide-default-footer":"","search":_vm.variantSearch,"headers":_vm.variantHeaders,"items":item.variants},scopedSlots:_vm._u([{key:"item.breakpoint",fn:function(ref){
var item = ref.item;
return [(item.breakpoint === 'og')?_c('span',[_vm._v("Original")]):_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(item.breakpoint)+" ")])]}},{key:"item.modified",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.modified))+" ")])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatBytes(item.size))+" ")])]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","color":"background"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.handleImageView(item.path)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-link ")])],1)]}}],null,true)},[_c('v-img',{attrs:{"src":_vm.$cdnUrl + item.path,"width":"200","contain":""}})],1)]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)]}}],null,true)},[_c('v-img',{attrs:{"src":_vm.$cdnUrl + item.variants[0].path,"width":"200","contain":""}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(item.showDeleteDialog),callback:function ($$v) {_vm.$set(item, "showDeleteDialog", $$v)},expression:"item.showDeleteDialog"}},[_c('v-card',{attrs:{"color":"deepBlack"}},[_c('v-card-title',[_vm._v(" Delete Confirmation ")]),_c('v-container',[_c('v-row',[_c('v-col',[_vm._v(" Are you sure you want to delete "+_vm._s(item.name)+" from the CDN and its project? ")])],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){item.showDeleteDialog = false}}},[_vm._v("No, Cancel!")]),_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.deleteFile(item)}}},[_vm._v("Yes, delete it!")])],1)],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }