<template>
  <div>
    <v-card elevation="0" color="deepBlack">
      <v-card-title>
        <v-row>
          <v-col class="d-fley align-center">
            <v-text-field
              label="Search Images"
              v-model="nameSearch"
              prepend-icon="mdi-magnify"
              color="secondary"
            ></v-text-field>
          </v-col>
          <v-col class="d-flex justify-end align-center">
            <v-btn icon @click="$emit('getFiles')" class="mr-4">
              <v-icon :class="rotateClass" color="secondary"
                >mdi-refresh</v-icon
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        class="deepBlack"
        multi-sort
        :search="nameSearch"
        :loading="cdnTable.loading"
        :headers="cdnTable.headers"
        :items="cdnTable.items"
      >
        <template v-slot:[`item.totalSize`]="{ item }">
          <span> {{ formatBytes(item.totalSize) }} </span>
        </template>
        <template v-slot:[`item.highlight`]="{ item }">
          <span v-if="item.highlight != false && item.highlight != true"
            >N.A.</span
          >
          <v-icon v-else-if="item.highlight">mdi-check</v-icon>
          <span v-else>-</span>
        </template>
        <template v-slot:[`item.title`]="{ item }">
          <span v-if="item.title != false && item.title != true">N.A.</span>
          <v-icon v-else-if="item.title">mdi-check</v-icon>
          <span v-else>-</span>
        </template>
        <template v-slot:[`item.variants`]="{ item }">
          <v-tooltip left color="background">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-dialog>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                  </template>
                  <v-card color="deepBlack">
                    <v-container>
                      <v-row>
                        <v-col class="d-flex justify-center">
                          <span :style="headlineTextStyle"
                            >File Variants - {{ item.name }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-card elevation="0" color="deepBlack">
                            <v-card-title>
                              <v-row>
                                <v-col class="d-fley align-center">
                                  <v-text-field
                                    label="Search Variants"
                                    v-model="variantSearch"
                                    prepend-icon="mdi-magnify"
                                    color="secondary"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card-title>
                            <v-data-table
                              class="deepBlack"
                              disable-pagination
                              multi-sort
                              hide-default-footer
                              :search="variantSearch"
                              :headers="variantHeaders"
                              :items="item.variants"
                            >
                              <template v-slot:[`item.breakpoint`]="{ item }">
                                <span v-if="item.breakpoint === 'og'"
                                  >Original</span
                                >
                                <span v-else class="text-uppercase">
                                  {{ item.breakpoint }}
                                </span>
                              </template>
                              <template v-slot:[`item.modified`]="{ item }">
                                <span> {{ formatDate(item.modified) }} </span>
                              </template>
                              <template v-slot:[`item.size`]="{ item }">
                                <span> {{ formatBytes(item.size) }} </span>
                              </template>
                              <template v-slot:[`item.link`]="{ item }">
                                <v-tooltip left color="background">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      color="primary"
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="handleImageView(item.path)"
                                    >
                                      <v-icon> mdi-link </v-icon>
                                    </v-btn>
                                  </template>
                                  <v-img
                                    :src="$cdnUrl + item.path"
                                    width="200"
                                    contain
                                  ></v-img>
                                </v-tooltip>
                              </template>
                            </v-data-table>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-dialog>
              </div>
            </template>
            <v-img
              :src="$cdnUrl + item.variants[0].path"
              width="200"
              contain
            ></v-img>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-dialog v-model="item.showDeleteDialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-on="on" v-bind="attrs">
                <v-icon color="error">mdi-delete</v-icon>
              </v-btn>
            </template>
            <v-card color="deepBlack">
              <v-card-title> Delete Confirmation </v-card-title>
              <v-container>
                <v-row>
                  <v-col>
                    Are you sure you want to delete {{ item.name }} from the CDN
                    and its project?
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex justify-end">
                    <v-btn
                      outlined
                      color="primary"
                      class="mr-4"
                      @click="item.showDeleteDialog = false"
                      >No, Cancel!</v-btn
                    >
                    <v-btn outlined @click="deleteFile(item)" color="error"
                      >Yes, delete it!</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { DeleteObjectCommand, ListObjectsCommand } from "@aws-sdk/client-s3";
import { createClient, bucketName } from "../plugins/s3/s3client";
import { supabase } from "../plugins/supabase/supabase";
export default {
  name: "cdnTable",
  data() {
    return {
      files: [],
      variants: this.$globalState.imageVariants,
      projects: [],
      nameSearch: null,
      variantSearch: null,
      ignorePath: "/public/image/",
      deleteConfirmationDialog: false,
      variantHeaders: [
        {
          text: "Breakpoint",
          align: "center",
          sortable: true,
          value: "breakpoint",
        },
        {
          text: "Modified",
          align: "center",
          sortable: true,
          value: "modified",
        },
        {
          text: "Size",
          align: "center",
          sortable: true,
          value: "size",
        },
        {
          text: "Link",
          align: "center",
          sortable: true,
          value: "link",
        },
      ],
      cdnTable: {
        loading: true,
        items: [],
        headers: [
          {
            text: "Name",
            align: "center",
            sortable: true,
            value: "name",
          },
          {
            text: "Project",
            align: "center",
            sortable: true,
            value: "project",
          },
          {
            text: "Total Size",
            align: "center",
            sortable: true,
            value: "totalSize",
          },
          {
            text: "Highlight",
            align: "center",
            sortable: true,
            value: "highlight",
          },
          {
            text: "Title",
            align: "center",
            sortable: true,
            value: "title",
          },
          {
            text: "Variants",
            align: "center",
            sortable: true,
            value: "variants",
          },
          {
            text: "Actions",
            align: "center",
            sortable: false,
            value: "actions",
          },
        ],
      },
    };
  },
  mounted() {
    this.fillTable();
  },
  computed: {
    rotateClass: function () {
      if (this.cdnTable.loading) {
        return "rotate";
      }
      return "";
    },
    headlineTextStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "letter-spacing: 0.3em; font-size: 1.2em; font-weight: bold;";
        case "sm":
          return "letter-spacing: 0.3em; font-size: 1.5em; font-weight: bold;";
        case "md":
          return "letter-spacing: 0.3em; font-size: 2.1em; font-weight: bold;";
        case "lg":
          return "letter-spacing: 0.3em; font-size: 2.3em; font-weight: bold;";
        case "xl":
          return "letter-spacing: 0.3em; font-size: 2.3em; font-weight: bold;";
      }
      return "letter-spacing: 0.3em; font-size: 2.3em; font-weight: bold;";
    },
  },
  methods: {
    getImages: async function () {
      let images = await supabase.from("image").select();
      if (images.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "",
          message: "Failed to load images from Supabase",
        };
        this.$globalState.addNotification(notify);
      } else if (images.data) {
        return images.data;
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "",
          message: "No images found in Supabase",
        };
        this.$globalState.addNotification(notify);
      }
    },
    getProjects: async function () {
      let response = await supabase.from("project").select();

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Loading Projects",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        this.projects = response.data;
      }
    },
    listBucketItems: async function () {
      const bucketParams = { Bucket: bucketName };
      let s3Client = await createClient();
      try {
        const data = await s3Client.send(new ListObjectsCommand(bucketParams));
        if (data && data.Contents) {
          this.files = [];
          for (let i = 0; i < data.Contents.length; i++) {
            this.files.push({
              path: data.Contents[i].Key,
              modified: data.Contents[i].LastModified,
              size: data.Contents[i].Size,
            });
          }
        } else {
          this.files = [];
        }
      } catch (err) {
        console.log("Error", err);
      }
    },
    fillTable: async function () {
      await this.listBucketItems();
      await this.getProjects();
      let images = await this.getImages();

      let parsedFiles = [];
      let validExtension = ".webp";
      // Filter for valid roots in paths
      let filteredFiles = this.files.filter((file) => {
        return (
          file.path.includes(validExtension) &&
          file.path.includes("public/dynamic/")
        );
      });
      for (let i = 0; i < filteredFiles.length; i++) {
        let file = filteredFiles[i];
        let pathItems = file.path.split("/");

        let isHighlight = null;
        let isTitle = null;

        //get image from images where id is pathItems[5]
        let image = images.filter((image) => {
          return image.id == pathItems[5];
        })[0];

        if (image) {
          isHighlight = image.highlight;
          isTitle = image.title;
        }

        parsedFiles.push({
          id: pathItems[5],
          breakpoint: pathItems[6].split(".")[0],
          project: this.getProjectName(pathItems[4]),
          genre: pathItems[3],
          modified: file.modified,
          size: file.size,
          path: file.path,
          highlight: isHighlight,
          title: isTitle,
        });
      }

      let nameArray = [];
      for (let i = 0; i < parsedFiles.length; i++) {
        nameArray.push(parsedFiles[i].id + parsedFiles[i].project);
      }

      let uniqueNameArray = nameArray.filter(function (item, pos) {
        return nameArray.indexOf(item) == pos;
      });

      let groupedFiles = [];
      for (let i = 0; i < uniqueNameArray.length; i++) {
        //get image from images where id is parsedFiles[0].id
        let image = images.filter((image) => {
          return image.id == parsedFiles[0].id;
        })[0];

        let name = "Failed to Fetch";
        if (image) {
          name = image.name;
        }
        let key = parsedFiles[0].id + parsedFiles[0].project;
        let group = parsedFiles.filter((file) => {
          return file.id + file.project === key;
        });

        let groupedItem = {
          name: name,
          id: parsedFiles[0].id,
          project: parsedFiles[0].project,
          highlight: parsedFiles[0].highlight,
          title: parsedFiles[0].title,
          variants: [],
          totalSize: 0,
          showDeleteDialog: false,
        };

        for (let k = 0; k < this.variants.length; k++) {
          if (
            group.filter((file) => {
              return file.breakpoint === this.variants[k].breakpoint;
            }).length > 0
          ) {
            let selectedFile = group.filter((file) => {
              return file.breakpoint === this.variants[k].breakpoint;
            })[0];
            groupedItem.totalSize = groupedItem.totalSize + selectedFile.size;
            groupedItem.variants.push({
              path: selectedFile.path,
              breakpoint: selectedFile.breakpoint,
              modified: selectedFile.modified,
              size: selectedFile.size,
            });
          }
        }

        for (let k = 0; k < group.length; k++) {
          parsedFiles = parsedFiles.filter((file) => {
            return file.path !== group[k].path;
          });
        }
        groupedFiles.push(groupedItem);
      }
      this.cdnTable.items = groupedFiles;
      this.cdnTable.loading = false;
    },
    handleImageView: function (path) {
      window.open(this.$cdnUrl + path, "_blank").focus();
    },
    deleteFile: async function (item) {
      let s3Client = await createClient();
      //CDN
      for (let i = 0; i < item.variants.length; i++) {
        const bucketParams = { Bucket: bucketName, Key: item.variants[i].path };
        try {
          await s3Client.send(new DeleteObjectCommand(bucketParams));
        } catch (err) {
          console.log("Error", err);
        }
      }

      //SUPABASE
      let response = await supabase
        .from("image")
        .delete()
        .match({ id: item.id });

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Image Deletion",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        this.$emit("getFiles");
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Image Deletion",
          message: "Successfully deleted Image '" + item.name + "'",
        };
        this.$globalState.addNotification(notify);
      }

      item.showDeleteDialog = false;
    },
    formatBytes: function (bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    formatDate: function (dateString) {
      let date = new Date(dateString);
      let day = ("0" + date.getDate()).slice(-2);
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let year = date.getFullYear();
      let hours = ("0" + date.getHours()).slice(-2);
      let minutes = ("0" + date.getMinutes()).slice(-2);
      let seconds = ("0" + (date.getSeconds() + 1)).slice(-2);

      let timestamp =
        day +
        "." +
        month +
        "." +
        year +
        " - " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;

      return timestamp;
    },
    getProjectName: function (id) {
      return this.projects.filter((project) => {
        return project.id === id;
      })[0].title;
    },
  },
};
</script>
<style scoped>
.rotate {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
