var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"greeting text-h4"},[_vm._v(" "+_vm._s(_vm.greeting() + " " + this.$globalState.profile.first_name)+"! ")]),_c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"dashboard-row"},[_c('div',{staticClass:"dashboard-card-wrapper todo-card"},[_c('div',{staticClass:"dashboard-card"},[_c('div',{staticClass:"card-headline"},[_c('span',[_vm._v("Last 5 Open ToDos")]),_c('v-btn',{attrs:{"small":"","outlined":"","color":"primary","to":"/todos"}},[_vm._v("Show ToDos")])],1),_c('v-data-table',{staticClass:"deepBlack",attrs:{"headers":_vm.todoTable.headers,"items":_vm.todoTable.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.done",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.done ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.done ? "mdi-checkbox-marked-outline" : "mdi-checkbox-blank-outline")+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"dashboard-card-wrapper tickets-card"},[_c('div',{staticClass:"dashboard-card"},[_c('div',{staticClass:"card-headline"},[_c('span',[_vm._v("5 Last Updated Tickets")]),_c('v-btn',{attrs:{"small":"","outlined":"","color":"primary","to":"/ticketoverview"}},[_vm._v("Show Tickets")])],1),_c('v-data-table',{staticClass:"deepBlack",attrs:{"headers":_vm.lastUpdatedTicketsTable.headers,"items":_vm.lastUpdatedTicketsTable.items,"hide-default-footer":""},on:{"click:row":_vm.handleTicketView},scopedSlots:_vm._u([{key:"item.done",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-n2"},[(item.done)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-all")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-alert-circle")])],1)]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name + " " + item.last_name)+" ")]}}],null,true)})],1)]),_c('div',{staticClass:"dashboard-card-wrapper tickets-card"},[_c('div',{staticClass:"dashboard-card"},[_c('div',{staticClass:"card-headline"},[_c('span',[_vm._v("5 Oldest Open Tickets")]),_c('v-btn',{attrs:{"small":"","outlined":"","color":"primary","to":"/ticketoverview"}},[_vm._v("Show Tickets")])],1),_c('v-data-table',{staticClass:"deepBlack",attrs:{"headers":_vm.oldestOpenTicketsTable.headers,"items":_vm.oldestOpenTicketsTable.items,"hide-default-footer":""},on:{"click:row":_vm.handleTicketView},scopedSlots:_vm._u([{key:"item.done",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-n2"},[(item.done)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-all")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-alert-circle")])],1)]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name + " " + item.last_name)+" ")]}}],null,true)})],1)])]),_c('div',{staticClass:"dashboard-row"},[_c('div',{staticClass:"dashboard-card-wrapper last-added-projects-card"},[_c('div',{staticClass:"dashboard-card"},[_c('div',{staticClass:"card-headline"},[_c('span',[_vm._v("Last 5 Added Projects")]),_c('v-btn',{attrs:{"small":"","outlined":"","color":"primary","to":"/projectmanager"}},[_vm._v("Show Projects")])],1),_c('v-data-table',{staticClass:"deepBlack",attrs:{"headers":_vm.projectTable.headers,"items":_vm.projectTable.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.genre_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getGenreById(item.genre_id))+" ")]}}],null,true)})],1)]),_c('div',{staticClass:"dashboard-card-wrapper last-ticket-entries-card"},[_c('div',{staticClass:"dashboard-card"},[_c('div',{staticClass:"card-headline"},[_c('span',[_vm._v("Last 5 Ticket Activites")]),_c('v-btn',{attrs:{"small":"","outlined":"","color":"primary","to":"/ticketoverview"}},[_vm._v("Show Tickets")])],1),_c('v-data-table',{staticClass:"deepBlack",attrs:{"headers":_vm.lastTicketEntriesTable.headers,"items":_vm.lastTicketEntriesTable.items,"hide-default-footer":""},on:{"click:row":_vm.handleEntryView},scopedSlots:_vm._u([{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [(item.user_id === _vm.$globalState.profile.id)?_c('div',[_vm._v("You")]):_c('div',[_vm._v(_vm._s(_vm.getUserById(item.user_id).display_name))])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseMessage(item.message, item.mark))+" ")]}}],null,true)})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }