<template>
  <div>
    <v-card min-width="700">
      <v-container>
        <v-card-title>
          <span class="headline">Upload Attachment</span>
        </v-card-title>
        <v-row>
          <v-col>
            <v-form ref="attachmentForm">
              <v-file-input
                label="Attachments"
                clearable
                show-size
                color="secondary"
                v-model="files"
                multiple
              ></v-file-input>
            </v-form>
          </v-col>
        </v-row>
        <div v-if="files.length > 0" class="pa-4">
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row class="font-weight-bold">
            <v-col> Name </v-col>
            <v-col cols="4"> Type </v-col>
          </v-row>
          <v-row no-gutters v-for="file in files" :key="file.name">
            <v-col>
              {{ file.name }}
            </v-col>
            <v-col cols="4">
              {{ file.type }}
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn color="error" @click="$emit('cancelUpload')" outlined text
              >Cancel</v-btn
            >
            <v-btn
              class="ml-4"
              color="secondary"
              @click="uploadAttachment"
              :loading="loadingUpload"
              >Upload</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { uploadTicketFile } from "../plugins/supabase/helpers.js";

export default {
  name: "uploadAttachmentForm",
  props: {
    ticketId: String,
  },
  data() {
    return {
      files: [],
      loadingUpload: false,
    };
  },
  methods: {
    uploadAttachment: async function () {
      this.loadingUpload = true;
      const files = this.files;
      const ticketId = this.ticketId;
      for (let i = 0; i < files.length; i++) {
        await uploadTicketFile(files[i], files[i].name, ticketId);
      }
      this.loadingUpload = false;
      this.$emit("uploaded");
      this.$emit("pullEntries");
      this.$refs.attachmentForm.reset();
    },
  },
};
</script>
<style scoped>
.cancel {
  outline: solid;
  outline-width: 2px;
  outline-color: var(--v-primary-base);
}
</style>
