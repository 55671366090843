export default {
  primary: {
    base: "#012120",
  },
  secondary: {
    base: "#13d389",
  },
  error: {
    base: "#B00020",
  },
  warning: {
    base: "#FFC400",
  },
  info: {
    base: "#000000",
  },
  success: {
    base: "#009649",
  },
  pending: {
    base: "#929292",
  },
  background: {
    base: "#ffffff",
  },
  text: {
    base: "#000000",
  },
};
