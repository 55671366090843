var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prevent-full-width mx-auto mt-4"},[_c('div',{staticClass:"glow"},[_c('v-card',{attrs:{"color":"deepBlack"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('span',{staticClass:"text-h4 font-weight-bold"},[_vm._v("Ticket Overview")])])],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"0","color":"deepBlack"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-fley align-center"},[_c('v-text-field',{attrs:{"label":"Search Subject","prepend-icon":"mdi-magnify","color":"secondary"},model:{value:(_vm.subjectSearch),callback:function ($$v) {_vm.subjectSearch=$$v},expression:"subjectSearch"}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center"},[_c('v-select',{staticClass:"pr-2",attrs:{"label":"Filter","items":_vm.filterItems,"item-text":"text","item-value":"value","color":"secondary"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-btn',{staticClass:"mr-4",attrs:{"icon":""},on:{"click":_vm.getTickets}},[_c('v-icon',{class:_vm.rotateClass,attrs:{"color":"secondary"}},[_vm._v("mdi-refresh")])],1),_c('v-dialog',{attrs:{"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"text"}},[_vm._v(" mdi-plus ")]),_c('span',[_vm._v("Add")])],1)]}}]),model:{value:(_vm.newTicketDialog),callback:function ($$v) {_vm.newTicketDialog=$$v},expression:"newTicketDialog"}},[_c('createTicketForm',{attrs:{"title":"Create a new Ticket","cancelButton":""},on:{"createdTicket":_vm.getTickets,"cancelNewTicket":function($event){_vm.newTicketDialog = false}}})],1)],1)],1)],1),_c('v-data-table',{staticClass:"deepBlack",attrs:{"disable-pagination":"","multi-sort":"","hide-default-footer":"","search":_vm.subjectSearch,"loading":_vm.tickets.loading,"headers":_vm.tickets.headers,"items":_vm.tickets.items},on:{"click:row":_vm.handleTicketView},scopedSlots:_vm._u([{key:"item.done",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-n2"},[(item.done)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-all")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-alert-circle")])],1)]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-n2"},[_vm._v(" "+_vm._s(_vm.buildDate(item.created))+" ")])]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-n2"},[_vm._v(" "+_vm._s(_vm.buildDate(item.updated))+" ")])]}}],null,true)})],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }