<template>
  <div>
    <svg
      viewBox="0 0 952.08 168"
      :width="svgWidth"
      :height="svgHeight"
      @click="handleLink()"
    >
      <svg
        id="Ebene_1"
        data-name="Ebene 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="370 0 200 170"
      >
        <path
          :class="colorClass"
          d="M86.06,59.62C77.73,50.48,62.08,48,50.42,56.96c-11.37,8.74-13.8,25.12-5.46,36.81c9.14,12.83,27.66,15.27,39.72,4.58
		c1.7,2.05,3.4,4.1,5.1,6.15c-9.52,9.01-26.11,11.78-39.45,4.56c-14.66-7.93-21.61-24.74-16.97-40.93
		c3.85-13.41,18.36-27.37,39.15-24.47c-0.18-0.2-0.28-0.35-0.41-0.44c-9.66-6.89-19.34-13.77-28.98-20.69
		c-0.92-0.66-1.67-0.76-2.72-0.34c-3.36,1.35-6.05,3.56-8.35,6.27c-4.79,5.64-7.68,12.29-9.97,19.23c-0.39,1.17-0.73,2.36-1.15,3.73
		c-4.07-2.33-8.1-4.63-12.34-7.05c1.39-3.15,2.65-6.3,4.15-9.32c4.15-8.37,9.26-16.06,16.41-22.23c3.41-2.94,7.2-5.29,11.34-7.09
		c0.92-0.4,1.57-0.34,2.39,0.25C58.6,17.3,74.37,28.59,90.09,39.93c0.94,0.68,1.48,0.58,2.35-0.04
		c15.76-11.35,31.56-22.66,47.32-34.01c0.75-0.54,1.32-0.57,2.16-0.22c8.15,3.44,14.53,9.05,19.79,16.04
		c5.2,6.91,9.18,14.48,12.18,22.74c-4.06,2.32-8.09,4.63-12.1,6.93c-1.17-3.28-2.2-6.51-3.47-9.64c-2.34-5.73-5.3-11.1-9.84-15.45
		c-2.05-1.96-4.4-3.44-7.08-4.37c-0.37-0.13-0.96-0.06-1.27,0.16c-9.98,7.07-19.93,14.18-29.89,21.28c-0.06,0.04-0.1,0.12-0.31,0.37
		c9.59-1.37,18.32,0.49,26.1,6.04c7.79,5.55,12.63,13.09,14.15,22.53c2.53,15.79-5.04,30.46-19.02,37.33
		c-13.65,6.72-30.23,3.77-40.71-7.22C79.17,90.59,77.86,72.81,86.06,59.62z M115.66,105.05c14.76,0.02,26.8-11.96,26.85-26.72
		c0.05-14.85-11.91-26.91-26.73-26.95c-14.83-0.04-26.88,11.94-26.92,26.77C88.82,92.95,100.85,105.03,115.66,105.05z"
        />
        <path
          :class="colorClass"
          d="M332.41,53.87c3.09,4.04,4.58,8.53,4.46,13.56c-0.24,10.79-9.4,19.86-20.19,19.92c-7.04,0.04-14.08,0.1-21.11-0.02
		c-8.17-0.14-14.4-3.83-18.18-11.06c-3.8-7.27-3.19-14.49,1.31-21.35c0.22-0.33,0.46-0.65,0.74-1.04c-0.24-0.35-0.48-0.7-0.72-1.05
		c-4.51-6.86-5.14-14.08-1.34-21.35c3.77-7.22,9.98-10.95,18.16-11.08c6.84-0.11,13.68-0.07,20.51-0.01
		c8.33,0.08,14.66,3.8,18.48,11.16c3.82,7.38,3.09,14.66-1.57,21.54C332.8,53.32,332.64,53.55,332.41,53.87z M305.93,46.09
		c3.59,0,7.19,0,10.78,0c2.61,0,4.8-2.33,4.8-5.1c0-2.78-2.16-5.08-4.8-5.08c-7.19-0.01-14.37-0.01-21.56,0
		c-2.64,0-4.81,2.31-4.81,5.08c0,2.76,2.2,5.09,4.81,5.09C298.74,46.09,302.34,46.09,305.93,46.09z M305.87,71.81
		c3.63,0,7.27,0.01,10.9,0c2.6-0.01,4.77-2.37,4.74-5.15c-0.03-2.72-2.19-5.01-4.75-5.01c-7.23-0.01-14.45-0.01-21.68,0
		c-2.56,0-4.72,2.29-4.74,5.02c-0.03,2.77,2.15,5.13,4.75,5.14C298.68,71.82,302.28,71.81,305.87,71.81z"
        />
        <path
          :class="colorClass"
          d="M472.36,54c0.08,18.23-14.82,33.27-33.07,33.36c-18.43,0.09-33.44-14.72-33.54-33.12c-0.1-18.39,14.72-33.35,33.18-33.48
		C457.24,20.62,472.27,35.57,472.36,54z M439.02,34.66c-10.75,0.05-19.41,8.8-19.36,19.55c0.05,10.65,8.82,19.28,19.54,19.23
		c10.58-0.05,19.29-8.83,19.25-19.42C458.4,43.35,449.64,34.61,439.02,34.66z"
        />
        <path
          :class="colorClass"
          d="M622.8,54.09c-0.05,18.47-15.02,33.33-33.52,33.27C571,87.3,556.12,72.27,556.19,53.91c0.07-18.32,15.14-33.28,33.39-33.15
		C607.99,20.89,622.85,35.79,622.8,54.09z M589.45,34.66c-10.75,0.06-19.41,8.8-19.35,19.56c0.06,10.65,8.83,19.27,19.55,19.23
		c10.58-0.05,19.29-8.83,19.24-19.42C608.85,43.34,600.07,34.61,589.45,34.66z"
        />
        <path
          :class="colorClass"
          d="M388.95,51.37c-5.32,5.32-10.42,10.43-15.62,15.63c-0.45-0.42-0.77-0.7-1.07-0.99c-9.53-9.53-19.05-19.06-28.59-28.57
		c-0.58-0.58-0.82-1.16-0.82-1.99c0.04-5.08,0.02-10.15,0.02-15.29c1.93-0.2,3.53-0.28,5.12,1.35c7.95,8.16,16.06,16.17,24.11,24.23
		c0.34,0.34,0.61,0.73,0.96,1.15c0.46-0.44,0.78-0.73,1.08-1.04c8.11-8.11,16.27-16.19,24.3-24.38c1.38-1.41,2.77-1.63,4.56-1.31
		c0,22.34,0,44.67,0,67.1c-4.63,0-9.28,0-14.06,0C388.95,75.4,388.95,63.51,388.95,51.37z"
        />
        <path
          :class="colorClass"
          d="M254.52,87.27c0-11.93,0-23.79,0-35.58c-5.12,5.12-10.23,10.24-15.41,15.42c-0.57-0.52-0.86-0.77-1.13-1.04
		c-9.56-9.55-19.11-19.11-28.68-28.66c-0.54-0.54-0.81-1.07-0.8-1.87c0.04-4.76,0.02-9.52,0.02-14.28c0-0.36,0-0.71,0-1.12
		c1.94-0.21,3.55-0.26,5.13,1.37c7.95,8.16,16.06,16.16,24.11,24.23c0.34,0.34,0.58,0.77,1.07,1.43c0.46-0.64,0.68-1.03,0.98-1.34
		c8.1-8.12,16.26-16.19,24.29-24.39c1.4-1.43,2.79-1.57,4.55-1.32c0,22.37,0,44.7,0,67.13C264,87.27,259.35,87.27,254.52,87.27z"
        />
        <path
          :class="colorClass"
          d="M682.97,87.36c-0.81,0-1.53-0.05-2.25,0.01c-0.78,0.07-1.33-0.22-1.88-0.78c-10.28-10.31-20.58-20.6-30.87-30.89
		c-6.11-6.11-12.21-12.23-18.34-18.31c-0.56-0.55-0.77-1.11-0.77-1.88c0.03-4.72,0.02-9.44,0.02-14.16c0-0.39,0-0.78,0-1.19
		c2.05-0.29,3.64-0.14,5.24,1.49c11.08,11.26,22.29,22.39,33.45,33.57c0.33,0.33,0.57,0.77,0.85,1.16c0.13-0.07,0.26-0.15,0.39-0.22
		c0-11.88,0-23.75,0-35.68c4.8,0,9.42,0,14.15,0C682.97,42.71,682.97,64.96,682.97,87.36z"
        />
        <path
          :class="colorClass"
          d="M535.88,20.45c4.71,0,9.34,0,14.03,0c0,22.27,0,44.5,0,66.81c-4.63,0-9.29,0-14.03,0
		C535.88,65.05,535.88,42.8,535.88,20.45z"
        />
        <path
          :class="colorClass"
          d="M469.63,34.67c0-4.72,0-9.34,0-14.04c20.02,0,39.99,0,60.05,0c0,4.65,0,9.3,0,14.04
		C509.72,34.67,489.72,34.67,469.63,34.67z"
        />
        <path
          :class="colorClass"
          d="M752.54,39.19c1.51,0,2.94,0.02,4.37-0.01c0.82-0.02,0.74,0.53,0.74,1.05c0,3.08,0,6.16,0,9.24c0,0.44,0,0.87,0,1.52
		c7.25,0,14.43,0,21.6,0c7.15,0,14.3,0,21.53,0c0,1.73,0,3.35,0,5.11c-14.3,0-28.63,0-43.04,0c0,8.72,0,17.29,0,26.02
		c16.48,0,32.96,0,49.54,0c0,1.8,0,3.45,0,5.17c-18.23,0-36.44,0-54.75,0C752.54,71.33,752.54,55.35,752.54,39.19z"
        />
        <path
          :class="colorClass"
          d="M810.35,29.91c0-1.76,0-3.39,0-5.13c0.32-0.03,0.66-0.08,1-0.08c7.92,0,15.83-0.05,23.75,0.02
		c16.11,0.14,29.4,12.29,31.05,28.31c1.72,16.65-10.33,32.04-26.84,34.28c-0.32,0.04-0.64,0.05-0.96,0.05c-9.04,0-18.07,0-27.11,0
		c-0.27,0-0.55-0.02-0.88-0.04c0-1.68,0-3.3,0-5.08c0.44-0.02,0.89-0.06,1.35-0.06c7.96,0,15.91-0.1,23.87,0.03
		c6.16,0.1,11.5-1.98,16.04-5.92c8.18-7.1,11.28-16.14,8.57-26.64c-2.73-10.6-9.9-16.96-20.57-19.31c-1.51-0.33-3.09-0.4-4.65-0.41
		c-7.72-0.04-15.43-0.02-23.15-0.02C811.35,29.91,810.88,29.91,810.35,29.91z"
        />
        <path
          :class="colorClass"
          d="M746.9,87.3c-1.8,0-3.5,0-5.3,0c0-16.25,0-32.43,0-48.59c-7.72,7.73-15.41,15.42-23.15,23.16
		c-0.56-0.53-0.86-0.78-1.14-1.06c-9.19-9.19-18.37-18.39-27.58-27.56c-0.61-0.61-0.91-1.2-0.88-2.08c0.07-2.06,0.02-4.12,0.02-6.46
		c9.89,9.89,19.57,19.57,29.36,29.37c9.59-9.59,19.07-19.08,28.66-28.67C746.9,46.09,746.9,66.65,746.9,87.3z"
        />
        <path
          :class="colorClass"
          d="M124.46,132.42c5.25,4.91,10.43,9.72,15.57,14.59c0.59,0.56,1.04,0.66,1.77,0.4c3.53-1.27,6.31-3.56,8.71-6.36
		c4.17-4.86,6.87-10.55,9.06-16.5c0.16-0.45,0.33-0.89,0.55-1.49c3.7,2.11,7.35,4.2,11.17,6.38c-1.05,2.11-2.01,4.2-3.09,6.22
		c-4.74,8.82-10.79,16.54-19.18,22.17c-2.27,1.53-4.84,2.62-7.26,3.92c-0.69,0.37-1.18,0.28-1.79-0.28
		c-5-4.67-10.04-9.28-15.05-13.94c-0.31-0.29-0.62-0.78-0.63-1.18c-0.04-4.52-0.02-9.04-0.01-13.56
		C124.29,132.72,124.35,132.66,124.46,132.42z"
        />
        <path
          :class="colorClass"
          d="M11.78,130.35c3.68-2.11,7.31-4.18,10.95-6.26c1.1,2.52,2.08,4.99,3.23,7.39c2.18,4.53,4.86,8.73,8.63,12.14
		c1.89,1.71,3.99,3.08,6.44,3.83c0.38,0.12,1,0.07,1.26-0.18c3.41-3.14,6.76-6.34,10.14-9.52c1.88-1.77,3.78-3.51,5.89-5.47
		c0,2.01,0,3.71,0,5.41c0,2.8,0.03,5.6-0.03,8.4c-0.01,0.48-0.26,1.09-0.62,1.42c-5,4.67-10.04,9.28-15.04,13.95
		c-0.6,0.56-1.07,0.61-1.79,0.31c-7.13-2.92-12.86-7.68-17.85-13.43C18.36,142.97,14.77,136.95,11.78,130.35z"
        />
        <path
          :class="colorClass"
          d="M492.18,44.67c4.71,0,9.33,0,14.03,0c0,14.2,0,28.37,0,42.61c-4.65,0-9.3,0-14.03,0
		C492.18,73.12,492.18,58.96,492.18,44.67z"
        />
        <path
          :class="colorClass"
          d="M208.56,87.29c0-12.2,0-24.29,0-36.57c0.31,0.26,0.52,0.41,0.7,0.59c4.27,4.26,8.55,8.51,12.8,12.8
		c0.33,0.33,0.62,0.87,0.62,1.32c0.04,7.11,0.03,14.22,0.02,21.34c0,0.15-0.04,0.31-0.06,0.53
		C217.96,87.29,213.31,87.29,208.56,87.29z"
        />
        <path
          :class="colorClass"
          d="M356.98,87.32c-4.7,0-9.32,0-14.02,0c0-12.16,0-24.29,0-36.55c0.3,0.24,0.54,0.41,0.75,0.62
		c4.24,4.23,8.49,8.46,12.71,12.71c0.33,0.33,0.62,0.87,0.63,1.31c0.04,7.15,0.02,14.3,0.02,21.46
		C357.06,86.98,357.02,87.09,356.98,87.32z"
        />
        <path
          :class="colorClass"
          d="M628.97,50.63c1.89,1.89,3.6,3.62,5.32,5.34c2.71,2.71,5.44,5.41,8.13,8.15c0.33,0.33,0.63,0.87,0.63,1.31
		c0.04,7.12,0.02,14.23,0.02,21.35c0,0.12-0.04,0.23-0.08,0.44c-4.64,0-9.29,0-14.02,0C628.97,75.1,628.97,63.02,628.97,50.63z"
        />
        <path
          :class="colorClass"
          d="M8.53,122.19c-3.69-10.56-5.59-21.42-6.07-32.63c4.79,0,9.48,0,14.27,0c0.17,2.34,0.32,4.68,0.5,7.01
		c0.48,6.06,1.41,12.05,2.83,17.97c0.15,0.61,0.26,1.07-0.48,1.48c-3.56,1.98-7.08,4.02-10.62,6.04
		C8.88,122.12,8.75,122.13,8.53,122.19z"
        />
        <path
          :class="colorClass"
          d="M174.37,121.31c-3.84-2.2-7.59-4.31-11.29-6.51c-0.26-0.16-0.36-0.9-0.27-1.31c1.43-6.41,2.32-12.89,2.76-19.43
		c0.1-1.43,0.17-2.87,0.26-4.4c4.75,0,9.47,0,14.31,0C179.67,100.46,177.87,110.98,174.37,121.31z"
        />
        <path
          :class="colorClass"
          d="M915.15,25.08c10.84,20.85,21.56,41.45,32.39,62.28c-2.06,0-3.95,0.05-5.83-0.05c-0.31-0.01-0.67-0.59-0.87-0.98
		c-4.34-8.32-8.67-16.64-13-24.97c-5.08-9.78-10.16-19.56-15.27-29.32c-0.4-0.76-0.42-1.34,0-2.08
		C913.44,28.44,914.21,26.87,915.15,25.08z"
        />
        <path
          :class="colorClass"
          d="M227.87,130.63c-1.63,3.6-3.17,7-4.7,10.41c-0.18,0.4-0.29,0.85-0.55,1.19c-0.29,0.4-0.7,0.7-1.06,1.05
		c-0.32-0.34-0.77-0.62-0.95-1.02c-3.01-6.7-5.99-13.41-8.97-20.12c-0.34-0.76-0.67-1.51-1.08-2.46c1.81,0,3.44-0.04,5.06,0.04
		c0.29,0.01,0.66,0.49,0.82,0.84c1.55,3.37,3.06,6.76,4.59,10.15c0.18,0.39,0.37,0.77,0.64,1.32c0.24-0.48,0.42-0.82,0.58-1.17
		c1.54-3.42,3.07-6.85,4.65-10.25c0.18-0.39,0.69-0.86,1.03-0.86c0.35,0.01,0.83,0.5,1.01,0.89c1.73,3.73,3.4,7.48,5.17,11.4
		c0.23-0.42,0.41-0.71,0.54-1.01c1.56-3.46,3.13-6.91,4.65-10.38c0.32-0.73,0.72-1.02,1.53-0.98c1.39,0.07,2.79,0.02,4.4,0.02
		c-0.21,0.52-0.34,0.9-0.51,1.27c-3.2,7.14-6.39,14.28-9.62,21.41c-0.17,0.37-0.64,0.9-0.89,0.86c-0.38-0.06-0.83-0.49-1-0.88
		c-1.6-3.48-3.15-6.99-4.72-10.48C228.31,131.5,228.13,131.16,227.87,130.63z"
        />
        <path
          :class="colorClass"
          d="M176.5,52.96c2.36,9.21,3.58,18.51,3.71,28.05c-4.82,0-9.54,0-14.3,0c-0.18-2.7-0.27-5.37-0.56-8.02
		c-0.44-4.01-1.02-8-1.5-12c-0.04-0.37,0.14-0.97,0.41-1.13c3.89-2.28,7.81-4.51,11.73-6.74C176.11,53.05,176.27,53.03,176.5,52.96z
		"
        />
        <path
          :class="colorClass"
          d="M6.17,52.87c4.31,2.46,8.53,4.87,12.5,7.14c-0.73,7.06-1.45,13.92-2.18,20.9c-4.51,0-9.24,0-13.97,0
		C1.86,76.84,4.02,60.06,6.17,52.87z"
        />
        <path
          :class="colorClass"
          d="M872.03,24.58c1.68,0,3.3,0,5.02,0c0,20.88,0,41.73,0,62.69c-1.63,0-3.28,0-5.02,0C872.03,66.41,872.03,45.53,872.03,24.58
		z"
        />
        <path
          :class="colorClass"
          d="M775.42,126.45c-1.65,0-3.2,0-4.83,0c-0.25-1.49-1.22-1.84-2.6-1.79c-2,0.08-4,0.01-6,0.02c-1.75,0.01-2.75,0.81-2.72,2.16
		c0.02,1.31,0.98,2.07,2.67,2.08c2,0.01,4-0.01,6,0.01c4.23,0.03,7.4,3.12,7.39,7.19c-0.01,4.07-3.16,7.13-7.41,7.16
		c-2.4,0.02-4.8,0.05-7.2-0.02c-3.63-0.11-6.63-3.33-6.36-6.75c0.14-0.03,0.29-0.09,0.44-0.09c1.4-0.01,2.79,0,4.27,0
		c0.28,1.55,1.28,1.92,2.71,1.85c2.03-0.09,4.08-0.01,6.12-0.03c1.48-0.01,2.37-0.82,2.38-2.11c0.01-1.28-0.88-2.13-2.35-2.16
		c-2.12-0.04-4.24,0.01-6.36-0.02c-4.19-0.05-7.31-3.08-7.34-7.1c-0.03-3.94,3.02-7.08,7.14-7.19c2.71-0.07,5.44-0.1,8.15,0.09
		C772.85,119.99,775.4,122.97,775.42,126.45z"
        />
        <path
          :class="colorClass"
          d="M901.94,119.63c0.51,4.6-1.08,8.23-4.57,11.17c2.49,4.1,4.98,8.18,7.59,12.48c-1.98,0-3.75,0.04-5.52-0.04
		c-0.31-0.01-0.67-0.48-0.88-0.82c-1.79-2.89-3.56-5.78-5.3-8.7c-0.35-0.59-0.71-0.81-1.42-0.74c-1.5,0.16-3.02,0.18-4.67,0.26
		c0,3.29,0,6.59,0,9.96c-1.72,0-3.3,0-4.97,0c0-4.97,0-9.95,0-15.09c1.33,0,2.63,0.02,3.93-0.01c1.84-0.04,3.69,0.02,5.5-0.21
		c1.95-0.25,3.36-1.44,4.55-3.29c-4.81,0-9.35,0-13.98,0c0-1.71,0-3.32,0-4.98C888.79,119.63,895.29,119.63,901.94,119.63z"
        />
        <path
          :class="colorClass"
          d="M689.36,124.59c-4.83,0-9.35,0-13.97,0c0-1.66,0-3.27,0-4.94c6.58,0,13.11,0,19.63,0c0.82,3.85-0.69,7.63-4.46,11.17
		c2.47,4.06,4.95,8.14,7.58,12.46c-2,0-3.8,0.03-5.6-0.04c-0.27-0.01-0.59-0.47-0.78-0.79c-1.78-2.89-3.56-5.78-5.3-8.7
		c-0.38-0.64-0.78-0.84-1.53-0.76c-1.46,0.16-2.94,0.17-4.56,0.25c0,3.28,0,6.58,0,9.96c-1.72,0-3.3,0-4.99,0c0-4.94,0-9.91,0-15.08
		c0.56,0,1.14,0,1.72,0c2.08,0,4.16,0.04,6.24-0.01C685.86,128.05,687.83,126.98,689.36,124.59z"
        />
        <path
          :class="colorClass"
          d="M597.85,119.75c6.47,0.02,11.73,5.28,11.74,11.75c0.01,6.51-5.3,11.8-11.83,11.78c-6.46-0.01-11.72-5.29-11.74-11.76
		C586.02,125.02,591.32,119.73,597.85,119.75z M604.65,131.47c-0.02-3.76-3.17-6.86-6.91-6.8c-3.74,0.06-6.76,3.1-6.76,6.83
		c-0.01,3.79,3.07,6.86,6.85,6.85C601.6,138.34,604.66,135.25,604.65,131.47z"
        />
        <path
          :class="colorClass"
          d="M848.85,119.75c6.46,0.02,11.73,5.31,11.72,11.77c-0.01,6.52-5.31,11.78-11.85,11.76c-6.45-0.02-11.72-5.32-11.71-11.78
		C837.01,124.98,842.31,119.72,848.85,119.75z M841.95,131.56c0.02,3.78,3.14,6.84,6.92,6.78c3.75-0.05,6.75-3.09,6.76-6.82
		c0-3.77-3.1-6.88-6.86-6.86C844.99,124.68,841.93,127.78,841.95,131.56z"
        />
        <path
          :class="colorClass"
          d="M807.33,24.78c0,1.7,0,3.29,0,4.97c-18.28,0-36.51,0-54.81,0c0-1.64,0-3.26,0-4.97C770.77,24.78,789,24.78,807.33,24.78z"
        />
        <path
          :class="colorClass"
          d="M648.09,119.7c1.72,0,3.3,0,4.96,0c0.02,0.39,0.06,0.74,0.06,1.08c0,4.48-0.02,8.95,0.01,13.43
		c0.02,2.48-0.94,4.49-2.79,6.11c-4.69,4.1-11.7,3.93-16.1-0.4c-1.37-1.34-2.29-2.93-2.31-4.85c-0.06-5.07-0.02-10.14-0.02-15.32
		c1.63,0,3.24,0,4.97,0c0,0.55,0,1.06,0,1.57c0,3.68-0.02,7.35,0.01,11.03c0.02,2.4,1.04,4.27,3.22,5.34c2,0.98,3.97,0.76,5.75-0.58
		c1.5-1.13,2.24-2.67,2.24-4.55c0-3.8,0-7.59,0-11.39C648.09,120.72,648.09,120.29,648.09,119.7z"
        />
        <path
          :class="colorClass"
          d="M882.74,87.36c7.62-14.65,15.15-29.13,22.81-43.85c1.05,2.05,2.02,3.89,2.93,5.77c0.1,0.21-0.08,0.6-0.22,0.87
		c-6.33,12.19-12.66,24.37-19.01,36.55c-0.14,0.27-0.47,0.63-0.72,0.64C886.67,87.39,884.81,87.36,882.74,87.36z"
        />
        <path
          :class="colorClass"
          d="M284.88,133.21c-4.66,0-9.21,0-13.84,0c0,1.69,0,3.3,0,5.03c5.33,0,10.59,0,15.93,0c0,1.69,0,3.27,0,4.94
		c-6.95,0-13.92,0-20.95,0c0-4.99,0-9.97,0-14.99c6.31,0,12.54,0,18.86,0C284.88,129.83,284.88,131.45,284.88,133.21z"
        />
        <path
          :class="colorClass"
          d="M386.7,143.22c0-5.06,0-10.01,0-15c6.32,0,12.58,0,18.9,0c0,1.65,0,3.23,0,4.93c-4.62,0-9.19,0-13.86,0
		c0,1.73,0,3.34,0,5.07c5.3,0,10.56,0,15.91,0c0,1.72,0,3.32,0,4.99C400.68,143.22,393.74,143.22,386.7,143.22z"
        />
        <path
          :class="colorClass"
          d="M694.33,87.33c-1.89,0-3.59,0-5.38,0c0-13.61,0-27.17,0-41.02c1.12,1.11,2.03,2.21,3.12,3.08
		c1.82,1.43,2.35,3.15,2.32,5.47c-0.14,10.31-0.06,20.62-0.06,30.93C694.33,86.26,694.33,86.73,694.33,87.33z"
        />
        <path
          :class="colorClass"
          d="M810.35,39.33c1.69,0,3.31,0,5.07,0c0.02,0.45,0.06,0.87,0.06,1.29c0,11.76-0.01,23.51,0.02,35.27
		c0,0.9-0.29,1.36-1.09,1.71c-1.34,0.58-2.63,1.28-4.07,1.99C810.35,66.06,810.35,52.73,810.35,39.33z"
        />
        <path
          :class="colorClass"
          d="M569.26,119.56c-0.38,0.55-0.6,0.91-0.85,1.25c-2.61,3.58-5.25,7.14-7.83,10.75c-0.32,0.45-0.53,1.08-0.53,1.63
		c-0.05,3.31-0.02,6.62-0.02,10.02c-1.68,0-3.28,0-5.03,0c0-2.72-0.12-5.43,0.04-8.13c0.12-2-0.47-3.56-1.69-5.13
		c-2.57-3.3-4.98-6.74-7.63-10.37c2.15,0,4.07-0.03,5.99,0.04c0.29,0.01,0.61,0.46,0.84,0.77c1.64,2.21,3.25,4.44,4.97,6.79
		c1.66-2.26,3.29-4.42,4.84-6.62c0.51-0.73,1.05-1.06,1.96-1.01C565.85,119.62,567.41,119.56,569.26,119.56z"
        />
        <path
          :class="colorClass"
          d="M923.88,119.56c2.07,0,3.86-0.04,5.65,0.03c0.38,0.02,0.84,0.4,1.09,0.74c1.68,2.23,3.31,4.49,5.03,6.84
		c1.7-2.32,3.34-4.53,4.93-6.77c0.44-0.61,0.9-0.9,1.68-0.87c1.63,0.06,3.26,0.02,5.14,0.02c-0.35,0.52-0.57,0.87-0.81,1.2
		c-2.59,3.55-5.2,7.08-7.75,10.66c-0.38,0.54-0.64,1.29-0.65,1.94c-0.07,3.27-0.03,6.53-0.03,9.86c-1.68,0-3.27,0-5.03,0
		c0-0.37,0-0.75,0-1.13c0-2.96,0.03-5.91-0.03-8.87c-0.01-0.59-0.23-1.26-0.57-1.73c-2.56-3.57-5.17-7.1-7.76-10.65
		C924.52,120.49,924.28,120.14,923.88,119.56z"
        />
        <path
          :class="colorClass"
          d="M429.89,119.54c1.65,0,3.2,0,4.9,0c0,6.19,0,12.37,0,18.68c4.68,0,9.21,0,13.8,0c0,1.73,0,3.31,0,4.97
		c-6.22,0-12.43,0-18.7,0C429.89,135.33,429.89,127.5,429.89,119.54z"
        />
        <path
          :class="colorClass"
          d="M470.92,143.22c0-7.94,0-15.77,0-23.66c1.64,0,3.22,0,4.94,0c0,6.22,0,12.39,0,18.67c4.65,0,9.15,0,13.74,0
		c0,1.71,0,3.32,0,4.99C483.38,143.22,477.2,143.22,470.92,143.22z"
        />
        <path
          :class="colorClass"
          d="M364.39,124.6c-7.03,0-14.03,0-21.11,0c0-1.65,0-3.23,0-4.87c7.05,0,14.03,0,21.11,0
		C364.39,121.34,364.39,122.94,364.39,124.6z"
        />
        <path
          :class="colorClass"
          d="M796.03,124.6c0-1.66,0-3.21,0-4.84c7.06,0,14.07,0,21.14,0c0,1.6,0,3.18,0,4.84C810.16,124.6,803.16,124.6,796.03,124.6z"
        />
        <path
          :class="colorClass"
          d="M266.06,119.73c7.05,0,13.94,0,20.92,0c0,1.63,0,3.21,0,4.87c-6.96,0-13.88,0-20.92,0
		C266.06,123,266.06,121.4,266.06,119.73z"
        />
        <path
          :class="colorClass"
          d="M407.64,124.61c-6.95,0-13.88,0-20.9,0c0-1.6,0-3.17,0-4.85c6.94,0,13.86,0,20.9,0
		C407.64,121.33,407.64,122.93,407.64,124.61z"
        />
        <path
          :class="colorClass"
          d="M356.12,143.21c-1.66,0-3.25,0-4.88,0c0-5.01,0-9.95,0-14.96c1.62,0,3.2,0,4.88,0
		C356.12,133.19,356.12,138.16,356.12,143.21z"
        />
        <path
          :class="colorClass"
          d="M804.04,128.2c1.67,0,3.22,0,4.86,0c0,5,0,9.97,0,15.01c-1.61,0-3.18,0-4.86,0C804.04,138.24,804.04,133.28,804.04,128.2z"
        />
        <path
          :class="colorClass"
          d="M97.37,95.18c-2.18-2.42-3.78-5.07-4.93-8c-0.13-0.33-0.07-0.79,0.04-1.15c1.54-5.24,1.55-10.47-0.02-15.71
		c-0.1-0.33-0.12-0.72-0.04-1.05c0.54-2.05,3.26-6.38,4.95-7.94C103.04,70.32,103.47,85.1,97.37,95.18z"
        />
      </svg>
    </svg>
  </div>
</template>

<script>
export default {
  name: "textIcon",
  props: {
    width: Number,
    height: Number,
    colorClass: String,
    link: String,
  },
  methods: {
    handleLink: function () {
      if (this.link) {
        this.$router.push(this.link);
      }
    },
  },
  computed: {
    svgWidth: function () {
      if (this.width && this.width > 0) {
        return this.width;
      }
      return "100%";
    },
    svgHeight: function () {
      if (this.height && this.height > 0) {
        return this.height;
      }
      return "100%";
    },
  },
};
</script>

<style type="text/css" scoped>
.st1 {
  fill: red;
}
.st0 {
  /* fill: black; */
}
.primary {
  fill: var(--v-primary-base);
}
.secondary {
  fill: var(--v-secondary-base);
}
.text {
  fill: var(--v-text-base);
}
.background {
  fill: var(--v-background-base);
}
.white {
  fill: #ffffff;
}
.black {
  fill: #000000;
}
.cls-1 {
  fill: transparent;
}
.cls-2 {
  fill: #162183;
}
.cls-3 {
  fill: #3fad3d;
}
.cls-4 {
  fill: transparent;
}
.cls-5 {
  fill: transparent;
}
.cls-6 {
  fill: transparent;
}
.cls-7 {
  fill: transparent;
}
.cls-8 {
  fill: transparent;
}
.cls-9 {
  fill: transparent;
}
</style>
