<template>
  <div class="prevent-full-width mx-auto mt-4">
    <div class="glow">
      <v-card color="deepBlack">
        <v-container>
          <v-row>
            <v-col class="d-flex justify-center">
              <span class="text-h4 font-weight-bold">Ticket Overview</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card elevation="0" color="deepBlack">
                <v-card-title>
                  <v-row>
                    <v-col class="d-fley align-center">
                      <v-text-field
                        label="Search Subject"
                        v-model="subjectSearch"
                        prepend-icon="mdi-magnify"
                        color="secondary"
                      ></v-text-field>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-select
                        class="pr-2"
                        label="Filter"
                        :items="filterItems"
                        v-model="filter"
                        item-text="text"
                        item-value="value"
                        color="secondary"
                      ></v-select>
                      <v-btn icon @click="getTickets" class="mr-4">
                        <v-icon :class="rotateClass" color="secondary"
                          >mdi-refresh</v-icon
                        >
                      </v-btn>
                      <v-dialog v-model="newTicketDialog" persistent>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="secondary" v-bind="attrs" v-on="on">
                            <v-icon color="text"> mdi-plus </v-icon>
                            <span>Add</span>
                          </v-btn>
                        </template>
                        <createTicketForm
                          title="Create a new Ticket"
                          cancelButton
                          v-on:createdTicket="getTickets"
                          v-on:cancelNewTicket="newTicketDialog = false"
                        />
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-data-table
                  class="deepBlack"
                  disable-pagination
                  multi-sort
                  hide-default-footer
                  :search="subjectSearch"
                  :loading="tickets.loading"
                  @click:row="handleTicketView"
                  :headers="tickets.headers"
                  :items="tickets.items"
                >
                  <template v-slot:[`item.done`]="{ item }">
                    <span class="ml-n2">
                      <v-icon v-if="item.done" color="success"
                        >mdi-check-all</v-icon
                      >
                      <v-icon v-else color="error">mdi-alert-circle</v-icon>
                    </span>
                  </template>
                  <template v-slot:[`item.created`]="{ item }">
                    <span class="ml-n2">
                      {{ buildDate(item.created) }}
                    </span>
                  </template>
                  <template v-slot:[`item.updated`]="{ item }">
                    <span class="ml-n2">
                      {{ buildDate(item.updated) }}
                    </span>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </div>
</template>
<script>
import { supabase } from "../plugins/supabase/supabase.js";
import { createDate } from "@/plugins/vuetify/helpers.js";

import createTicketForm from "../components/createTicketForm.vue";

export default {
  name: "TicketOverview",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Ticket Overview",
      meta: [
        {
          name: "description",
          content: "Overview of Tickets sent to " + this.$applicationName,
        },
      ],
    };
  },
  components: {
    createTicketForm,
  },
  data() {
    return {
      filter: "all",
      filterItems: [
        { text: "All", value: "all" },
        { text: "Open", value: "open" },
        { text: "Done", value: "done" },
      ],
      newTicketDialog: false,
      subjectSearch: "",
      ticketArray: [],
      ticketsArray: [],
      tickets: {
        items: [],
        loading: false,
        headers: [
          {
            text: "Status",
            align: "center",
            sortable: true,
            value: "done",
          },
          {
            text: "First Name",
            align: "center",
            sortable: true,
            value: "first_name",
          },
          {
            text: "Last Name",
            align: "center",
            sortable: true,
            value: "last_name",
          },
          {
            text: "Subject",
            align: "center",
            sortable: true,
            value: "subject",
          },
          {
            text: "Updated",
            align: "center",
            sortable: true,
            value: "updated",
          },
          {
            text: "Created",
            align: "center",
            sortable: true,
            value: "created",
          },
        ],
      },
    };
  },
  watch: {
    filter: function () {
      this.getTickets();
      this.$router.push({
        name: "TicketOverview",
        query: { filter: this.filter },
      });
    },
  },
  mounted() {
    if (this.$route.query.message) {
      let notify = {
        duration: 4000,
        type: "info",
        headline: this.$route.query.message,
        message: "",
      };
      this.$globalState.addNotification(notify);
    }
    if (this.$route.query.filter) {
      this.filter = this.$route.query.filter;
    }
    this.getTickets();
  },
  methods: {
    getTickets: async function () {
      this.tickets.loading = true;
      this.newTicketDialog = false;
      let response = null;
      if (this.filter == "all") {
        response = await supabase.from("ticket").select();
      } else {
        response = await supabase
          .from("ticket")
          .select()
          .match({
            done: this.filter === "open" ? false : true,
          });
      }

      if (response.error === null) {
        response.data.sort(function (a, b) {
          if (a.created > b.created) return -1;
          if (a.created < b.created) return 1;
          return 0;
        });
        this.tickets.items = response.data;
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Fetching Tickets",
          message: "Can't fetch Tickets from Database. Try again later!",
        };
        this.$globalState.addNotification(notify);
      }
      this.tickets.loading = false;
    },
    handleTicketView: function (row) {
      this.$router.push({ name: "Ticket", params: { id: row.id } });
    },
    buildDate: function (epoch) {
      return createDate(epoch);
    },
  },
  computed: {
    cardStyle: function () {
      if (this.$vuetify.breakpoint.width > 600) {
        return "margin-top: -25vh; width: 1200px; margin-bottom: 5vh;";
      } else if (
        this.$vuetify.breakpoint.width <= 600 &&
        this.$vuetify.breakpoint.width > 350
      ) {
        return "margin-top: -35vh; width: 800px; margin-bottom: 5vh;";
      }
      return "margin-top: -25vh; width: 500px; margin-bottom: 5vh;";
    },
    rotateClass: function () {
      if (this.tickets.loading) {
        return "rotate";
      }
      return "";
    },
  },
};
</script>
<style scoped>
.glow {
  -webkit-box-shadow: 0px 0px 15px 10px var(--v-secondary-base);
  box-shadow: 0px 0px 15px 10px var(--v-secondary-base);
  border-radius: 5px;
  margin-bottom: 20px;
}
.center {
  display: grid;
  place-items: center;
}

.rotate {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
