<template>
  <div class="prevent-full-width mx-auto mt-4">
    <div v-if="ticket" class="glow">
      <v-card color="deepBlack">
        <v-container>
          <v-row>
            <v-col cols="2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" to="/ticketoverview">
                    <v-icon dark> mdi-list-box-outline </v-icon>
                  </v-btn>
                </template>
                <span>Ticket-Overview</span>
              </v-tooltip>
            </v-col>
            <v-col cols="8" class="d-flex justify-center pb-0">
              <span class="text-h4 font-weight-bold text-truncate">
                {{ ticket.subject }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-tabs v-model="tab" centered background-color="deepBlack">
              <v-tabs-slider color="secondary"></v-tabs-slider>

              <v-tab
                v-for="(item, index) in tabItems"
                :key="item"
                class="text--text deepBlack"
                @click="pushTabUrl(index)"
              >
                {{ item }}
              </v-tab>
              <!-- ENTRIES -->
              <v-tab-item>
                <v-container>
                  <v-row class="d-flex justify-center">
                    <v-col>
                      <v-card color="orange">
                        <v-container>
                          <v-row no-gutters>
                            <v-col>
                              <span
                                class="
                                  d-flex
                                  align-center
                                  justify-start
                                  text-caption
                                  background--text
                                  pb-1
                                "
                              >
                                {{ ticket.first_name }}
                                {{ ticket.last_name }} on
                                {{ buildDate(ticket.created) }}</span
                              >
                            </v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col v-linkified>
                              {{ ticket.message }}
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-form ref="newEntryForm" v-model="validNewEntry">
                        <v-textarea
                          color="secondary"
                          v-model="newEntry"
                          :rules="[notEmpty]"
                        ></v-textarea>
                      </v-form>
                    </v-col>
                    <v-col cols="3" class="d-flex justify-center">
                      <v-container class="grow d-flex flex-column flex-nowrap">
                        <v-row class="shrink">
                          <v-col class="py-0 d-flex justify-center">
                            <v-avatar>
                              <v-icon large> mdi-account </v-icon>
                            </v-avatar>
                          </v-col>
                        </v-row>
                        <v-row class="shrink">
                          <v-col
                            class="py-0 d-flex justify-center text-truncate"
                          >
                            {{ $globalState.profile.first_name }}
                          </v-col>
                        </v-row>
                        <v-row class="d-flex grow align-end">
                          <v-col class="d-flex justify-end">
                            <v-btn
                              fab
                              color="secondary"
                              @click="addEntry"
                              :disabled="!validNewEntry"
                            >
                              <v-icon> mdi-send </v-icon>
                            </v-btn>
                          </v-col>
                          <v-col class="d-flex justify-start">
                            <v-dialog
                              v-model="uploadAttachmentDialog"
                              persistent
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn fab v-on="on" v-bind="attrs">
                                  <v-icon> mdi-paperclip </v-icon>
                                </v-btn>
                              </template>
                              <uploadAttachmentForm
                                v-on:cancelUpload="
                                  uploadAttachmentDialog = false
                                "
                                v-on:uploaded="uploadAttachmentDialog = false"
                                v-on:pullEntries="getEntries"
                                :ticketId="ticket.id"
                              />
                            </v-dialog>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-skeleton-loader
                    class="pt-4"
                    v-if="loadingEntries"
                    type="card"
                  ></v-skeleton-loader>
                  <v-row v-else v-for="entry in entries" :key="entry.id">
                    <v-col class="pt-0">
                      <entryCard
                        :entry="entry"
                        :entries="entries"
                        v-on:getEntries="getEntries"
                        v-on:quoteEntry="addQuote"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <!-- ATTACHMENTS -->
              <v-tab-item>
                <v-container>
                  <attachmentTable :ticketId="ticket.id" />
                </v-container>
              </v-tab-item>
              <!-- CLIENT DETAILS -->
              <v-tab-item>
                <v-container>
                  <v-card elevation="0">
                    <v-container>
                      <v-row>
                        <v-col cols="6" class="font-weight-bold">
                          First Name
                        </v-col>
                        <v-col>
                          {{ ticket.first_name }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" class="font-weight-bold">
                          Last Name
                        </v-col>
                        <v-col>
                          {{ ticket.last_name }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" class="font-weight-bold"> Email </v-col>
                        <v-col>
                          <a
                            class="text--text"
                            :href="'mailto:' + ticket.email"
                            >{{ ticket.email }}</a
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" class="font-weight-bold"> Phone </v-col>
                        <v-col v-if="ticket.phone != '-'">
                          <a class="text--text" :href="'tel:' + ticket.phone">{{
                            ticket.phone
                          }}</a>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-container>
              </v-tab-item>
              <!-- CONTACT CLIENT -->
              <v-tab-item>
                <v-container>
                  <contactClientForm
                    v-on:sentMail="getTicket"
                    v-on:pushTab="pushTab"
                    :ticketId="this.ticket.id"
                    :title="
                      'Contact ' +
                      this.ticket.first_name +
                      ' ' +
                      this.ticket.last_name
                    "
                  />
                </v-container>
              </v-tab-item>
              <!-- TO DOs -->
              <v-tab-item>
                <v-container>
                  <todoTable v-on:getEntries="getEntries" :ticketView="true" />
                </v-container>
              </v-tab-item>
              <!-- SETTINGS -->
              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col class="d-flex justify-end">
                      <v-btn
                        v-if="!ticket.done"
                        small
                        color="success"
                        @click="markState('done')"
                        >Mark Done</v-btn
                      >
                      <v-btn
                        v-else
                        color="error"
                        small
                        @click="markState('open')"
                        >Mark Open</v-btn
                      >
                    </v-col>

                    <v-col>
                      <v-dialog v-model="deleteConfirmDialog">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small color="error" v-on="on" v-bind="attrs">
                            Delete Ticket
                          </v-btn>
                        </template>
                        <v-card color="background" outlined class="overlay">
                          <v-container>
                            <v-row>
                              <v-col>
                                <span
                                  class="
                                    text-h6
                                    font-weight-bold
                                    secondary--text
                                  "
                                  >Delete Ticket</span
                                >
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <span
                                  >Are you sure you want to delete this Ticket
                                  and all of its Entries?<br />This action can
                                  not be reverted!
                                </span>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col class="d-flex justify-end">
                                <v-btn
                                  @click="
                                    deleteConfirmDialog = !deleteConfirmDialog
                                  "
                                  class="mr-3 cancel text--text"
                                  text
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  @click="deleteTicket"
                                  color="error"
                                  text
                                  class="delete"
                                  >Yes</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <v-skeleton-loader
      v-else
      :style="cardStyle"
      class="pt-4"
      type="card"
    ></v-skeleton-loader>
  </div>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
import { createDate } from "@/plugins/vuetify/helpers.js";
import { DeleteObjectCommand } from "@aws-sdk/client-s3";
import { createClient, bucketName } from "../plugins/s3/s3client";

import contactClientForm from "../components/contactClientForm.vue";
import entryCard from "../components/entryCard.vue";
import uploadAttachmentForm from "../components/uploadAttachmentForm.vue";
import attachmentTable from "../components/attachmentTable.vue";
import todoTable from "../components/todoTable.vue";
export default {
  name: "Ticket",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: this.ticketSubject,
      meta: [
        {
          name: "description",
          content: "Home of " + this.$applicationName,
        },
      ],
    };
  },
  data() {
    return {
      test: "test",
      ticket: null,
      ticketSubject: "",
      entries: [],
      newEntry: "",
      validNewEntry: false,
      loadingEntries: true,
      contactClientDialog: false,
      editEntry: false,
      editEntryMessage: "",
      deleteConfirmDialog: false,
      uploadAttachmentDialog: false,
      tabItems: [
        "Entries",
        "Attachments",
        "Client Details",
        "Contact Client",
        "ToDos",
        "Settings",
      ],
      tab: this.$route.query.tab ? Number(this.$route.query.tab) : 0,
    };
  },
  components: {
    contactClientForm,
    entryCard,
    uploadAttachmentForm,
    attachmentTable,
    todoTable,
  },
  async mounted() {
    await this.getTicket();
  },
  computed: {
    cardStyle: function () {
      if (this.$vuetify.breakpoint.width > 600) {
        return "margin-top: -25vh; width: 1100px; margin-bottom: 5vh;";
      } else if (
        this.$vuetify.breakpoint.width <= 600 &&
        this.$vuetify.breakpoint.width > 350
      ) {
        return "margin-top: -35vh; width: 800px; margin-bottom: 5vh;";
      }
      return "margin-top: -25vh; width: 500px; margin-bottom: 5vh;";
    },
  },
  methods: {
    pushTab: function (tab) {
      this.tab = tab;
      this.pushTabUrl(tab);
    },
    pushTabUrl: function (tabIndex) {
      this.$router.push({
        name: "Ticket",
        params: {
          ticketId: this.ticket.id,
        },
        query: {
          tab: tabIndex,
        },
      });
    },
    addQuote: function (entryId) {
      this.newEntry = this.newEntry + " [Q-" + entryId + "-Q/] ";
    },
    getTicket: async function () {
      this.contactClientDialog = false;
      let response = await supabase
        .from("ticket")
        .select()
        .match({ id: this.$route.params.id });

      if (response.error === null) {
        this.ticket = response.data[0];
        this.ticketSubject = this.ticket.subject;
        await this.getEntries();
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Ticket",
          message: "Couldn't fetch Ticket! Try again later!",
        };
        this.$globalState.addNotification(notify);
      }
    },
    buildDate: function (epoch) {
      return createDate(epoch);
    },
    addEntry: async function () {
      //check if neEntry contains "[Q-" and "-/Q]"
      let mark = null;
      if (this.newEntry.includes("[Q-") && this.newEntry.includes("-Q/]")) {
        mark = "quote";
      }

      let response = await supabase.from("entry").insert([
        {
          user_id: supabase.auth.user().id,
          ticket_id: this.ticket.id,
          message: this.newEntry,
          mark: mark,
          created: Date.now(),
          updated: Date.now(),
        },
      ]);

      if (response.error === null) {
        //update updated date on ticket
        response = await supabase
          .from("ticket")
          .update({ updated: Date.now() })
          .match({ id: this.ticket.id });

        if (response.error === null) {
          let notify = {
            duration: 4000,
            type: "success",
            headline: "Successfully added Entry",
            message: "",
          };
          this.$globalState.addNotification(notify);
          this.$refs.newEntryForm.reset();
          await this.getEntries();
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "Ticket",
            message: "Couldn't update Ticket! Try again later!",
          };
          this.$globalState.addNotification(notify);
        }
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Failed Adding Entry",
          message: "",
        };
        this.$globalState.addNotification(notify);
      }
    },
    getEntries: async function () {
      this.loadingEntries = true;
      let payload = {
        token: supabase.auth.session().access_token,
        ticketId: this.ticket.id,
      };
      let response = await fetch(this.$apiUrl + "/ticket/entries", {
        method: "POST",
        body: JSON.stringify(payload),
      });
      let responsePayload = await response.json();
      this.entries = responsePayload.entries;
      this.loadingEntries = false;
    },
    markState: async function (state) {
      let doneState = false;
      if (state === "done") {
        doneState = true;
      }
      let response = await supabase
        .from("ticket")
        .update({ done: doneState, updated: Date.now() })
        .match({ id: this.ticket.id });

      if (response.error === null) {
        response = await supabase.from("entry").insert([
          {
            user_id: supabase.auth.user().id,
            ticket_id: this.ticket.id,
            message:
              "[U-" +
              supabase.auth.user().id +
              "] marked this Ticket as " +
              state,
            created: Date.now(),
            updated: Date.now(),
            mark: state,
          },
        ]);

        if (response.error === null) {
          let notify = {
            duration: 4000,
            type: "success",
            headline:
              "Successfully marked '" +
              this.ticket.subject +
              "' as " +
              state +
              "!",
            message: "",
          };
          this.$globalState.addNotification(notify);
          await this.getTicket();
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline:
              "Failed marking '" + this.ticket.subject + "' as " + state + "!",
            message: response.error,
          };
          this.$globalState.addNotification(notify);
        }
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline:
            "Failed marking '" + this.ticket.subject + "' as " + state + "!",
          message: response.error,
        };
        this.$globalState.addNotification(notify);
      }
    },
    deleteTicket: async function () {
      await this.deleteS3Folder();

      let response = await supabase
        .from("ticket")
        .delete()
        .match({ id: this.ticket.id });

      if (response.error === null) {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Successfully deleted Ticket",
          message: "",
        };
        this.$globalState.addNotification(notify);
        this.deleteConfirmDialog = false;
        this.$router.push({
          name: "TicketOverview",
        });
        //query: { func: "getTickets" },
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Failed Deleting Ticket",
          message: "",
        };
        this.$globalState.addNotification(notify);
      }
    },
    deleteS3Folder: async function () {
      //SUPABASE
      //get all attachments of this ticket
      let response = await supabase
        .from("attachment")
        .select()
        .match({ ticket_id: this.ticket.id });

      let files = [];
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Failed getting attachments of this Ticket",
          message: "",
        };
        this.$globalState.addNotification(notify);
      } else {
        files = response.data;
      }

      if (files.length > 0) {
        //CDN
        let folderPath = "private/ticket/" + this.ticket.id;
        let s3Client = await createClient();
        for (let i = 0; i < files.length; i++) {
          const bucketParams = {
            Bucket: bucketName,
            Key: folderPath + "/attachments/" + files[i].id,
          };
          try {
            await s3Client.send(new DeleteObjectCommand(bucketParams));
          } catch (err) {
            console.log("Error", err);
          }
        }
      }
    },

    //rules
    notEmpty: function (input) {
      if (input) {
        return true;
      }
      return "This field is required";
    },
  },
};
</script>
<style scoped>
.glow {
  -webkit-box-shadow: 0px 0px 15px 10px var(--v-secondary-base);
  box-shadow: 0px 0px 15px 10px var(--v-secondary-base);
  border-radius: 5px;
  margin-bottom: 20px;
}
.cancel {
  outline: solid;
  outline-width: 2px;
  outline-color: var(--v-primary-base);
}
.delete {
  outline: solid;
  outline-width: 2px;
  outline-color: var(--v-error-base);
}
</style>
