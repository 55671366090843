<template>
  <div>
    <masonry-wall :items="images" :column-width="columnWidth" :gap="20">
      <template v-slot:default="{ item }">
        <div class="Item">
          <img
            class="detailimg grow"
            :src="
              $cdnUrl + item.key.replace('BREAKPOINT', $vuetify.breakpoint.name)
            "
            @click="openFullSize(item)"
            style="cursor: pointer"
            loading="lazy"
          />
        </div>
      </template>
    </masonry-wall>
    <v-overlay
      v-model="overlay"
      :opacity="$vuetify.breakpoint.mdAndDown ? 0.9 : 0.8"
      @click="overlay = false"
      style="cursor: pointer"
      v-if="selectedItem"
    >
      <v-row>
        <v-col class="d-flex justify-center">
          <img
            class="imageBorder"
            :src="
              $cdnUrl +
              selectedItem.key.replace('BREAKPOINT', getNextBiggerBreakpoint())
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center">
          <v-btn
            color="primary"
            :to="
              '/portfolio/' +
              getGenreNameById(selectedItem.genre_id) +
              '#p-' +
              selectedItem.project_id
            "
            >Go to Project
          </v-btn>
        </v-col>
      </v-row>
    </v-overlay>
  </div>
</template>
<script>
import { supabase } from "../plugins/supabase/supabase";

export default {
  name: "highlightGrid",
  data() {
    return {
      images: [],
      items: [
        { title: "Item 0", content: "Content" },
        { title: "Item 1", content: "Content" },
      ],
      masonryGap: 20,
      selectedItem: null,
      overlay: false,
    };
  },

  async mounted() {
    await this.getHighlights();
  },
  methods: {
    getGenreNameById: function (id) {
      let validItems = this.$globalState.genres.filter((genre) => {
        return genre.id === id;
      });
      return validItems[0].name.toLowerCase();
    },
    getNextBiggerBreakpoint: function () {
      let breakpoints = ["og", "xl", "lg", "md", "sm", "xs"];
      let currentBreakpoint = this.$vuetify.breakpoint.name;
      let nextBiggerBreakpoint =
        breakpoints[breakpoints.indexOf(currentBreakpoint) - 1];
      return nextBiggerBreakpoint;
    },

    openFullSize: function (item) {
      this.selectedItem = item;
      this.overlay = true;
      let notify = {
        duration: 2000,
        type: "info",
        headline: "",
        message: "Click anywhere to close this view",
      };
      this.$globalState.addNotification(notify);
    },
    append() {
      for (let i = 0; i < 20; i++) {
        this.items.push({
          title: `Item ${this.items.length}`,
          content: "Content",
        });
      }
    },
    getHighlights: async function () {
      let response = await supabase
        .from("image")
        .select()
        .match({ highlight: true });

      if (!response.error && response.data.length > 0) {
        this.imageAmount = response.data.length;
        this.images = this.shuffleArray(response.data);
      }
    },
    shuffleArray: function (array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      //add index as property to every item in array
      array.forEach((item, index) => {
        item.index = index;
      });

      return array;
    },
  },
  computed: {
    gridContainerStyle: function () {
      if (this.$vuetify.breakpoint.xsOnly) {
        return "display: grid; grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); grid-gap: 10px; grid-auto-rows: minmax(150px, 1fr);";
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return "display: grid; grid-template-columns: repeat(auto-fit, minmax(170px, 1fr)); grid-gap: 10px; grid-auto-rows: minmax(170px, 1fr);";
      } else {
        return "display: grid; grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); grid-gap: 10px; grid-auto-rows: minmax(300px, 1fr);";
      }
    },
    columnWidth: function () {
      let colAmount = 4;
      let centralColumnMulitplier = 8 / 12;
      if (this.$vuetify.breakpoint.smAndDown) {
        colAmount = 3;
        centralColumnMulitplier = 1;
      }
      if (this.$vuetify.breakpoint.xsOnly) {
        colAmount = 2;
        centralColumnMulitplier = 1;
      }
      let width = this.$vuetify.breakpoint.width;
      let centralColumnWidth = width * centralColumnMulitplier;
      centralColumnWidth = Math.floor(centralColumnWidth);
      let columnWidth = centralColumnWidth / colAmount;
      return columnWidth;
    },
  },
};
</script>
<style scoped>
img {
  border-radius: 3px;
}
.Item {
  overflow: hidden;
  width: 100%;
}
.Content {
  padding: 20px;
}
.detailimg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  line-height: 0;
  display: block;
}
.imageBorder {
  display: block;
  max-width: 80vw;
  max-height: 80vh;
  width: auto;
  height: auto;
}
.grow {
  transition: all 0.2s ease-in-out;
  /* cursor: pointer; */
}
.grow:hover {
  transform: scale(1.1);
  z-index: 2;
}
</style>
