var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:('width:' + _vm.width + 'px; cursor:pointer; max-width: ' + _vm.maxWidth),on:{"click":_vm.handleClick}},[_c('v-img',{staticClass:"grow",attrs:{"contain":"","width":_vm.width,"max-width":_vm.maxWidth,"src":_vm.$cdnUrl + _vm.src.replace('BREAKPOINT', _vm.getSmallerBreakpoint(3)),"lazy-src":_vm.$cdnUrl + _vm.src.replace('BREAKPOINT', 'sm')},on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(_vm.hover && _vm.hoverText)?_c('div',{staticClass:"center text-center darkenblur pa-6",style:('width:' +
        _vm.width +
        'px; height:' +
        _vm.width +
        'px; max-width: ' +
        _vm.maxWidth +
        'max-height: ' +
        _vm.maxWidth)},[_vm._v(" "+_vm._s(_vm.hoverText)+" ")]):_vm._e()]),_c('v-overlay',{attrs:{"opacity":_vm.$vuetify.breakpoint.mdAndDown ? 0.9 : 0.8},model:{value:(_vm.overlay),callback:function ($$v) {_vm.overlay=$$v},expression:"overlay"}},[_c('v-row',[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"d-flex align-center"},[(_vm.array)?_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handlePreviousImg.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1):_vm._e()],1):_vm._e(),_c('v-col',[_c('v-img',{staticClass:"pa-8",attrs:{"src":_vm.$cdnUrl + _vm.dataSrc.replace('BREAKPOINT', 'xl'),"lazy-src":_vm.$cdnUrl + _vm.dataSrc.replace('BREAKPOINT', 'md'),"width":_vm.imageSize,"contain":""}})],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"d-flex align-center"},[(_vm.array)?_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleNextImg.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1):_vm._e()],1):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center"},[(_vm.array)?_c('v-btn',{staticClass:"hidden-md-and-up",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handlePreviousImg.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1):_vm._e(),(
            _vm.$globalState.isLoggedIn &&
            _vm.indexInArray === _vm.dataIndexInArray &&
            _vm.highlight
          )?_c('v-btn',{attrs:{"icon":""},on:{"mouseenter":function($event){_vm.hoverDislike = true},"mouseleave":function($event){_vm.hoverDislike = false},"click":function($event){$event.stopPropagation();return _vm.changeHighlightState(false)}}},[(_vm.hoverDislike)?_c('v-icon',[_vm._v("mdi-heart-off")]):_c('v-icon',[_vm._v("mdi-heart")])],1):(
            _vm.$globalState.isLoggedIn &&
            _vm.indexInArray === _vm.dataIndexInArray &&
            !_vm.highlight
          )?_c('v-btn',{attrs:{"icon":""},on:{"mouseenter":function($event){_vm.hoverLike = true},"mouseleave":function($event){_vm.hoverLike = false},"click":function($event){$event.stopPropagation();return _vm.changeHighlightState(true)}}},[(_vm.hoverLike)?_c('v-icon',[_vm._v("mdi-heart")]):_c('v-icon',[_vm._v("mdi-heart-outline")])],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(
                _vm.$globalState.isLoggedIn &&
                _vm.indexInArray === _vm.dataIndexInArray &&
                _vm.titleImage
              )?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-swap-horizontal ")])],1):_vm._e()]}}]),model:{value:(_vm.titleChangeOverlay),callback:function ($$v) {_vm.titleChangeOverlay=$$v},expression:"titleChangeOverlay"}},[_c('titleImageChange',{attrs:{"projectId":_vm.parseProjectId(_vm.dataSrc),"titleImageSrc":_vm.dataSrc},on:{"closeDialog":function($event){_vm.titleChangeOverlay = false}}})],1),(_vm.array)?_c('v-btn',{staticClass:"hidden-md-and-up",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleNextImg.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }