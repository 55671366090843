<template>
  <div>
    <svg
      viewBox="0 0 2560 400"
      :width="svgWidth"
      :height="svgHeight"
      @click="handleLink()"
    >
      <svg
        id="Ebene_1"
        data-name="Ebene 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 600 100"
      >
        <path
          class="st0"
          d="M0,0c204.8,0,409.6,0,614.4,0c0,32,0,64,0,96C409.6,96,204.8,96,0,96C0,64,0,32,0,0z M159.33,26.28
		c0-0.08,0-0.16,0-0.23c-2.08,0-4.16-0.03-6.24,0.01c-4.82,0.08-8.47,2.24-10.7,6.47c-2.21,4.18-1.92,8.38,0.66,12.36
		c0.42,0.65,0.39,1.03-0.01,1.65c-2.59,4.03-2.85,8.26-0.58,12.46c2.25,4.16,5.9,6.28,10.65,6.35c4.04,0.06,8.07,0.06,12.11,0
		c4.75-0.06,8.41-2.16,10.68-6.32c2.29-4.2,2.04-8.43-0.53-12.47c-0.44-0.69-0.43-1.09,0.02-1.76c4.75-7.2,0.8-16.89-7.69-18.45
		C164.99,25.86,162.12,26.28,159.33,26.28z M249.33,26.27c-10.82,0.02-19.61,8.81-19.58,19.58c0.03,10.77,8.84,19.51,19.67,19.5
		c10.78-0.01,19.62-8.85,19.58-19.6C268.96,34.97,260.16,26.25,249.33,26.27z M355.73,26.27c-10.82-0.02-19.61,8.69-19.66,19.5
		c-0.05,10.69,8.76,19.54,19.47,19.58c10.89,0.04,19.76-8.71,19.78-19.52C375.33,35.06,366.56,26.3,355.73,26.27z M76.97,33.23
		c-1.43-3.88-3.25-7.4-6.07-10.22c-1.55-1.55-3.53-2.69-5.33-4c-0.62-0.45-1.17-0.35-1.82,0.13c-2.99,2.2-6.03,4.34-9.05,6.5
		c-2.89,2.06-5.77,4.13-8.66,6.19c-5.43-3.88-10.79-7.72-16.15-11.55c-2.69-1.93-2.72-1.95-5.46-0.08c-4.65,3.18-7.23,7.85-9.31,13
		c1.54,0.88,3.04,1.73,4.38,2.49c1.06-2.31,1.85-4.56,3.08-6.52c0.95-1.5,2.43-2.68,3.76-3.92c0.65-0.61,1.37-0.47,2.15,0.09
		c3.19,2.34,6.43,4.6,9.65,6.9c0.23,0.16,0.43,0.36,0.78,0.64c-8.04-0.85-12.88,4.2-14.37,8.65c-1.97,5.86,0.27,12.15,5.42,15.37
		c4.86,3.04,11.56,2.5,15.5-1.28c-0.63-0.75-1.25-1.5-1.88-2.25c-5.36,4.27-11.89,2.49-14.87-1.75c-3.18-4.52-2.17-10.69,2.31-13.92
		c4.1-2.95,10.33-2.35,13.01,1.26c-3.18,5.38-2.22,12.01,1.9,16.1c4.06,4.04,10.35,4.99,15.39,2.31c5.1-2.71,7.83-8.33,6.74-13.99
		c-0.66-3.44-2.45-6.2-5.33-8.22c-2.83-1.99-6.02-2.68-9.76-2.23c3.86-2.75,7.42-5.31,11.02-7.8c0.33-0.23,1-0.22,1.39-0.05
		c2.27,1.01,3.68,2.91,4.74,5.04c0.89,1.8,1.59,3.69,2.41,5.62C73.99,34.93,75.44,34.11,76.97,33.23z M113.98,41.72
		c-0.17-0.03-0.35-0.07-0.52-0.1c-0.26-0.36-0.47-0.76-0.77-1.07c-4.6-4.61-9.24-9.18-13.79-13.83c-0.94-0.96-1.87-0.97-3.07-0.77
		c0,2.86,0.03,5.64-0.02,8.43c-0.01,0.82,0.23,1.41,0.82,1.99c3.25,3.19,6.46,6.42,9.68,9.63c2.43,2.42,4.86,4.85,7.39,7.37
		c3.02-3.02,5.99-6,9.22-9.24c0,7.29,0,14.23,0,21.14c2.87,0,5.57,0,8.28,0c0-13.18,0-26.28,0-39.34c-1.2-0.32-2,0.1-2.81,0.92
		c-4.54,4.61-9.15,9.15-13.71,13.74C114.38,40.9,114.21,41.34,113.98,41.72z M222.19,25.95c-1.2-0.36-2,0.06-2.81,0.88
		c-4.29,4.35-8.64,8.64-12.96,12.97c-0.56,0.56-1.03,1.21-1.59,1.87c-0.54-0.51-0.85-0.8-1.16-1.1c-4.58-4.57-9.19-9.11-13.71-13.74
		c-0.94-0.96-1.88-1.16-3.18-0.86c0,2.85-0.05,5.67,0.04,8.49c0.02,0.63,0.33,1.4,0.78,1.85c5.39,5.45,10.83,10.86,16.27,16.27
		c0.24,0.24,0.51,0.46,1.01,0.91c3.01-3.01,5.98-5.98,9.03-9.03c0,6.98,0,13.9,0,20.84c2.82,0,5.52,0,8.27,0
		C222.19,52.16,222.19,39.09,222.19,25.95z M408.58,47.14c-0.59-0.55-0.93-0.86-1.26-1.19c-6.42-6.4-12.86-12.78-19.23-19.23
		c-0.94-0.95-1.86-0.98-3.06-0.77c0,2.82,0.03,5.57-0.02,8.31c-0.02,0.9,0.24,1.55,0.89,2.2c9.38,9.31,18.75,18.63,28.07,28
		c0.9,0.9,1.77,1.03,2.85,0.79c0-13.08,0-26.09,0-39.11c-2.78,0-5.48,0-8.26,0C408.58,33.08,408.58,39.93,408.58,47.14z
		M318.41,26.1c0,13.16,0,26.17,0,39.18c2.8,0,5.5,0,8.18,0c0-13.1,0-26.12,0-39.18C323.83,26.1,321.16,26.1,318.41,26.1z
		M308.79,34.43c0-2.83,0-5.55,0-8.27c-11.8,0-23.5,0-35.27,0c0,2.78,0,5.5,0,8.27C285.3,34.43,297.01,34.43,308.79,34.43z
		M502.36,62.25c-9.76,0-19.45,0-29.11,0c0-5.14,0-10.11,0-15.21c8.47,0,16.84,0,25.26,0c0-1.07,0-2.01,0-3.06
		c-8.47,0-16.85,0-25.36,0c0-2.34,0-4.56,0-6.81c-1.06,0-2,0-2.93,0c0,9.43,0,18.77,0,28.12c10.76,0,21.42,0,32.14,0
		C502.36,64.3,502.36,63.36,502.36,62.25z M510.28,31.61c4.79,0,9.46-0.01,14.13,0c10.02,0.01,17.26,8.78,15.35,18.58
		c-1.35,6.93-7.62,12.11-14.65,12.11c-4.52,0-9.03,0-13.55,0c-0.42,0-0.85,0-1.27,0c0,1.11,0,2.05,0,3.01
		c0.23,0.02,0.39,0.05,0.54,0.05c4.96,0,9.91,0.04,14.87-0.02c1.26-0.01,2.56-0.17,3.78-0.49c8.63-2.24,14.37-10.52,13.6-19.51
		c-0.74-8.67-7.78-15.94-16.48-16.59c-4.81-0.36-9.67-0.16-14.5-0.21c-0.59-0.01-1.18,0-1.83,0
		C510.28,29.59,510.28,30.53,510.28,31.61z M426.69,28.72c0,1.41-0.03,2.61,0.02,3.8c0.01,0.32,0.14,0.73,0.36,0.95
		c5.57,5.6,11.17,11.19,16.92,16.94c4.53-4.57,9.01-9.08,13.7-13.81c0,9.75,0,19.21,0,28.7c1.11,0,2.09,0,3.1,0
		c0-12.07,0-24.08,0-36.25c-5.67,5.66-11.22,11.2-16.89,16.85C438.14,40.14,432.5,34.51,426.69,28.72z M286.82,40.32
		c0,8.37,0,16.66,0,24.96c2.78,0,5.47,0,8.15,0c0-8.38,0-16.67,0-24.96C292.19,40.32,289.53,40.32,286.82,40.32z M95.9,65.28
		c2.81,0,5.51,0,8.3,0c0-4.3,0.02-8.52-0.03-12.74c0-0.37-0.31-0.8-0.6-1.08c-1.82-1.85-3.68-3.66-5.5-5.51
		c-0.66-0.67-1.26-1.39-1.88-2.1c-0.1,0.08-0.19,0.15-0.29,0.23C95.9,51.12,95.9,58.16,95.9,65.28z M186.88,43.74
		c0,7.42,0,14.48,0,21.53c2.79,0,5.5,0,8.27,0c0-4.3,0.01-8.52-0.02-12.75c0-0.28-0.16-0.62-0.36-0.83
		C192.24,49.12,189.67,46.55,186.88,43.74z M385.37,43.88c-0.09,0.08-0.18,0.15-0.27,0.23c0,7.03,0,14.06,0,21.16
		c2.77,0,5.46,0,8.16,0c0.05-0.17,0.12-0.28,0.12-0.39c0.01-4.07,0.03-8.14-0.01-12.21c0-0.4-0.31-0.88-0.61-1.2
		c-1.93-1.97-3.9-3.9-5.84-5.86C386.38,45.07,385.89,44.46,385.37,43.88z M602.81,65.35c-6.39-12.25-12.68-24.31-19.06-36.54
		c-0.48,0.91-0.84,1.7-1.31,2.42c-0.48,0.73-0.42,1.32-0.01,2.08c1.41,2.59,2.74,5.22,4.11,7.83c4.05,7.77,8.1,15.54,12.17,23.3
		c0.19,0.37,0.59,0.83,0.94,0.87C600.62,65.43,601.61,65.35,602.81,65.35z M552.54,28.53c0,12.33,0,24.55,0,36.78c1,0,1.9,0,2.87,0
		c0-12.28,0-24.5,0-36.78C554.44,28.53,553.53,28.53,552.54,28.53z M502.36,28.61c-10.76,0-21.45,0-32.16,0c0,1.02,0,1.95,0,2.92
		c10.75,0,21.44,0,32.16,0C502.36,30.52,502.36,29.58,502.36,28.61z M71.91,62.63c-0.62,1.33-1.15,2.6-1.79,3.82
		c-1.04,2-2.37,3.78-4.41,4.9c-0.69,0.38-1.22,0.45-1.86-0.24c-1.08-1.16-2.32-2.19-3.49-3.27c-0.59-0.55-1.19-1.1-1.97-1.82
		c0,1.86-0.03,3.41,0.02,4.95c0.01,0.34,0.25,0.75,0.51,1c1.68,1.59,3.37,3.17,5.11,4.69c0.27,0.24,0.93,0.36,1.21,0.19
		c1.53-0.91,3.12-1.79,4.47-2.94c2.82-2.41,4.74-5.5,6.24-8.96C74.57,64.14,73.26,63.39,71.91,62.63z M16.28,65.31
		c0.38,0.74,0.67,1.35,0.99,1.94c2.23,4.07,5.11,7.51,9.41,9.56c0.56,0.27,0.97,0.32,1.48-0.18c1.64-1.58,3.34-3.09,5-4.65
		c0.24-0.23,0.51-0.58,0.52-0.88c0.05-1.61,0.02-3.22,0.02-5.08c-1.95,1.81-3.75,3.38-5.43,5.07c-0.65,0.66-1.16,0.63-1.86,0.25
		c-2.1-1.14-3.41-2.97-4.49-5c-0.57-1.08-1.04-2.21-1.57-3.37C18.98,63.77,17.67,64.52,16.28,65.31z M578.09,39.6
		c-4.52,8.66-8.93,17.13-13.43,25.75c1.1,0,2.02,0.07,2.91-0.03c0.38-0.04,0.89-0.34,1.06-0.67c3.66-6.94,7.29-13.9,10.9-20.87
		c0.16-0.31,0.24-0.79,0.11-1.08C579.24,41.72,578.7,40.78,578.09,39.6z M510.29,37.16c0,7.88,0,15.66,0,23.57
		c0.54-0.25,0.94-0.5,1.39-0.64c1.26-0.38,1.62-1.14,1.6-2.47c-0.08-6.34-0.04-12.67-0.04-19.01c0-0.47,0-0.94,0-1.46
		C512.2,37.16,511.3,37.16,510.29,37.16z M426.77,41.25c0,8.21,0,16.12,0,24.03c1.06,0,2.03,0,2.98,0c0.04-0.15,0.08-0.22,0.08-0.3
		c0.01-6.66,0.01-13.32,0.01-19.98c0-0.26-0.04-0.6-0.2-0.77C428.77,43.28,427.85,42.36,426.77,41.25z M12.85,50.1
		c0.13,4.2,0.84,8.22,2.27,12.18c0.69-0.39,1.24-0.71,1.8-1.02c2.48-1.4,2.44-1.39,2.02-4.15c-0.35-2.31-0.59-4.63-0.89-7
		C16.36,50.1,14.66,50.1,12.85,50.1z M72.89,59.49c1.34,0.76,2.75,1.56,4.27,2.42c1.28-3.9,2.03-7.75,2.11-11.77
		c-1.85,0-3.55,0-5.16,0C73.7,53.26,73.3,56.29,72.89,59.49z M18.03,46.82c0.29-2.48,0.58-4.81,0.83-7.14
		c0.03-0.26-0.09-0.67-0.28-0.79c-1.42-0.85-2.87-1.64-4.6-2.62c-0.48,3.64-0.93,7.04-1.4,10.55C14.6,46.82,16.33,46.82,18.03,46.82
		z M78.15,36.27c-1.73,0.97-3.15,1.76-4.55,2.59c-0.19,0.11-0.38,0.44-0.36,0.65c0.26,2.44,0.57,4.87,0.87,7.31
		c1.72,0,3.45,0,5.44,0C79.07,43.28,78.62,39.88,78.15,36.27z"
        />
        <path
          :class="colorClass"
          d="M159.33,26.28c2.79,0,5.66-0.42,8.36,0.08c8.49,1.56,12.44,11.26,7.69,18.45c-0.44,0.67-0.46,1.07-0.02,1.76
		c2.58,4.03,2.82,8.27,0.53,12.47c-2.27,4.15-5.93,6.25-10.68,6.32c-4.04,0.05-8.07,0.06-12.11,0c-4.75-0.07-8.4-2.2-10.65-6.35
		c-2.27-4.2-2.01-8.43,0.58-12.46c0.4-0.62,0.43-1.01,0.01-1.65c-2.58-3.98-2.87-8.18-0.66-12.36c2.23-4.22,5.89-6.38,10.7-6.47
		c2.08-0.04,4.16-0.01,6.24-0.01C159.33,26.12,159.33,26.2,159.33,26.28z M159.31,35.17c-2.12,0-4.24-0.01-6.36,0
		c-1.67,0.01-2.94,1.3-2.94,2.96c0,1.65,1.3,3,2.93,3.01c4.16,0.01,8.32,0.01,12.48,0c1.64,0,2.92-1.33,2.93-3
		c0.01-1.68-1.23-2.96-2.91-2.97C163.39,35.15,161.35,35.17,159.31,35.17z M159.19,56.24c2.04,0,4.08,0.01,6.12,0
		c1.76-0.01,3.04-1.27,3.04-2.99c0-1.73-1.26-2.98-3.04-2.98c-4.08-0.01-8.16-0.01-12.24,0c-1.76,0-3.06,1.28-3.06,2.98
		c0,1.69,1.31,2.99,3.05,3C155.11,56.25,157.15,56.24,159.19,56.24z"
        />
        <path
          :class="colorClass"
          d="M249.33,26.27c10.83-0.02,19.63,8.69,19.67,19.49c0.04,10.75-8.79,19.59-19.58,19.6c-10.82,0.01-19.63-8.73-19.67-19.5
		C229.72,35.08,238.51,26.3,249.33,26.27z M237.98,45.84c0.02,6.25,5.12,11.34,11.37,11.36c6.28,0.02,11.47-5.16,11.45-11.42
		c-0.02-6.28-5.18-11.36-11.51-11.33C243.02,34.48,237.96,39.58,237.98,45.84z"
        />
        <path
          :class="colorClass"
          d="M355.73,26.27c10.83,0.02,19.6,8.78,19.59,19.57c-0.01,10.8-8.88,19.56-19.78,19.52c-10.71-0.04-19.51-8.9-19.47-19.58
		C336.12,34.97,344.91,26.25,355.73,26.27z M344.3,45.8c-0.01,6.24,5.08,11.36,11.33,11.39c6.28,0.03,11.48-5.12,11.49-11.39
		c0.01-6.22-5.11-11.34-11.36-11.36C349.41,34.42,344.3,39.49,344.3,45.8z"
        />
        <path
          :class="colorClass"
          d="M76.97,33.23c-1.54,0.87-2.98,1.7-4.42,2.52c-0.82-1.93-1.52-3.83-2.41-5.62c-1.06-2.13-2.46-4.03-4.74-5.04
		c-0.39-0.17-1.06-0.18-1.39,0.05c-3.6,2.5-7.16,5.06-11.02,7.8c3.74-0.45,6.92,0.24,9.76,2.23c2.88,2.02,4.66,4.78,5.33,8.22
		c1.09,5.66-1.64,11.28-6.74,13.99c-5.04,2.68-11.33,1.73-15.39-2.31c-4.12-4.09-5.09-10.72-1.9-16.1
		c-2.68-3.6-8.91-4.21-13.01-1.26c-4.48,3.22-5.49,9.39-2.31,13.92c2.98,4.24,9.51,6.02,14.87,1.75c0.63,0.75,1.26,1.5,1.88,2.25
		c-3.94,3.77-10.65,4.31-15.5,1.28c-5.15-3.22-7.38-9.5-5.42-15.37c1.49-4.45,6.33-9.49,14.37-8.65c-0.35-0.29-0.55-0.48-0.78-0.64
		c-3.22-2.29-6.46-4.56-9.65-6.9c-0.77-0.57-1.5-0.7-2.15-0.09c-1.33,1.24-2.81,2.42-3.76,3.92c-1.24,1.96-2.02,4.21-3.08,6.52
		c-1.34-0.76-2.83-1.62-4.38-2.49c2.08-5.16,4.65-9.83,9.31-13c2.74-1.87,2.76-1.85,5.46,0.08c5.36,3.83,10.72,7.66,16.15,11.55
		c2.89-2.06,5.77-4.13,8.66-6.19c3.02-2.16,6.06-4.29,9.05-6.5c0.65-0.48,1.21-0.58,1.82-0.13c1.8,1.3,3.77,2.44,5.33,4
		C73.72,25.84,75.54,29.36,76.97,33.23z M55.17,55.82c5.57,0.01,10.09-4.47,10.08-10c-0.01-5.48-4.48-9.95-9.98-10
		c-5.55-0.05-10.11,4.44-10.13,9.96C45.12,51.33,49.6,55.81,55.17,55.82z"
        />
        <path
          :class="colorClass"
          d="M113.98,41.72c0.23-0.38,0.39-0.83,0.7-1.13c4.57-4.58,9.17-9.13,13.71-13.74c0.81-0.82,1.61-1.24,2.81-0.92
		c0,13.06,0,26.16,0,39.34c-2.7,0-5.4,0-8.28,0c0-6.92,0-13.86,0-21.14c-3.23,3.24-6.21,6.22-9.22,9.24
		c-2.53-2.52-4.96-4.95-7.39-7.37c-3.22-3.22-6.43-6.44-9.68-9.63c-0.59-0.58-0.83-1.17-0.82-1.99c0.04-2.79,0.02-5.57,0.02-8.43
		c1.2-0.19,2.13-0.19,3.07,0.77c4.55,4.66,9.19,9.22,13.79,13.83c0.31,0.31,0.52,0.71,0.77,1.07
		C113.63,41.66,113.81,41.69,113.98,41.72z"
        />
        <path
          :class="colorClass"
          d="M222.19,25.95c0,13.14,0,26.21,0,39.34c-2.75,0-5.45,0-8.27,0c0-6.93,0-13.85,0-20.84c-3.06,3.06-6.02,6.02-9.03,9.03
		c-0.5-0.45-0.77-0.67-1.01-0.91c-5.43-5.41-10.87-10.82-16.27-16.27c-0.44-0.45-0.76-1.22-0.78-1.85
		c-0.08-2.82-0.04-5.65-0.04-8.49c1.3-0.3,2.23-0.1,3.18,0.86c4.52,4.63,9.13,9.17,13.71,13.74c0.3,0.3,0.62,0.59,1.16,1.1
		c0.56-0.66,1.03-1.31,1.59-1.87c4.31-4.33,8.67-8.62,12.96-12.97C220.19,26.01,220.98,25.59,222.19,25.95z"
        />
        <path
          :class="colorClass"
          d="M408.58,47.14c0-7.21,0-14.06,0-20.99c2.78,0,5.48,0,8.26,0c0,13.02,0,26.03,0,39.11c-1.09,0.23-1.96,0.11-2.85-0.79
		c-9.32-9.37-18.69-18.69-28.07-28c-0.66-0.65-0.91-1.3-0.89-2.2c0.05-2.75,0.02-5.49,0.02-8.31c1.2-0.21,2.11-0.19,3.06,0.77
		c6.37,6.45,12.81,12.83,19.23,19.23C407.64,46.28,407.99,46.59,408.58,47.14z"
        />
        <path
          :class="colorClass"
          d="M318.41,26.1c2.75,0,5.42,0,8.18,0c0,13.05,0,26.07,0,39.18c-2.69,0-5.39,0-8.18,0C318.41,52.27,318.41,39.26,318.41,26.1z
		"
        />
        <path
          :class="colorClass"
          d="M308.79,34.43c-11.78,0-23.48,0-35.27,0c0-2.76,0-5.49,0-8.27c11.77,0,23.47,0,35.27,0
		C308.79,28.88,308.79,31.61,308.79,34.43z"
        />
        <path
          :class="colorClass"
          d="M502.36,62.25c0,1.11,0,2.05,0,3.04c-10.71,0-21.38,0-32.14,0c0-9.35,0-18.69,0-28.12c0.93,0,1.86,0,2.93,0
		c0,2.24,0,4.47,0,6.81c8.51,0,16.89,0,25.36,0c0,1.05,0,1.98,0,3.06c-8.41,0-16.79,0-25.26,0c0,5.11,0,10.07,0,15.21
		C482.92,62.25,492.6,62.25,502.36,62.25z"
        />
        <path
          :class="colorClass"
          d="M510.28,31.61c0-1.08,0-2.02,0-3.08c0.66,0,1.25-0.01,1.83,0c4.83,0.05,9.69-0.15,14.5,0.21
		c8.69,0.65,15.74,7.92,16.48,16.59c0.77,8.99-4.97,17.27-13.6,19.51c-1.22,0.32-2.52,0.47-3.78,0.49
		c-4.96,0.05-9.91,0.02-14.87,0.02c-0.15,0-0.31-0.03-0.54-0.05c0-0.96,0-1.89,0-3.01c0.42,0,0.84,0,1.27,0c4.52,0,9.03,0,13.55,0
		c7.03,0,13.3-5.18,14.65-12.11c1.91-9.8-5.33-18.56-15.35-18.58C519.74,31.6,515.07,31.61,510.28,31.61z"
        />
        <path
          :class="colorClass"
          d="M426.69,28.72c5.81,5.8,11.45,11.42,17.21,17.18c5.66-5.65,11.22-11.19,16.89-16.85c0,12.17,0,24.17,0,36.25
		c-1.01,0-1.99,0-3.1,0c0-9.49,0-18.95,0-28.7c-4.69,4.73-9.17,9.24-13.7,13.81c-5.75-5.75-11.35-11.33-16.92-16.94
		c-0.22-0.22-0.35-0.62-0.36-0.95C426.67,31.32,426.69,30.13,426.69,28.72z"
        />
        <path
          :class="colorClass"
          d="M286.82,40.32c2.71,0,5.37,0,8.15,0c0,8.29,0,16.58,0,24.96c-2.67,0-5.37,0-8.15,0C286.82,56.98,286.82,48.68,286.82,40.32
		z"
        />
        <path
          :class="colorClass"
          d="M95.9,65.28c0-7.13,0-14.16,0-21.19c0.1-0.08,0.19-0.15,0.29-0.23c0.62,0.7,1.22,1.43,1.88,2.1
		c1.82,1.85,3.69,3.66,5.5,5.51c0.28,0.29,0.59,0.72,0.6,1.08c0.04,4.22,0.03,8.44,0.03,12.74C101.41,65.28,98.71,65.28,95.9,65.28z
		"
        />
        <path
          :class="colorClass"
          d="M186.88,43.74c2.79,2.81,5.35,5.37,7.89,7.96c0.2,0.2,0.36,0.55,0.36,0.83c0.02,4.22,0.02,8.45,0.02,12.75
		c-2.78,0-5.48,0-8.27,0C186.88,58.23,186.88,51.16,186.88,43.74z"
        />
        <path
          :class="colorClass"
          d="M385.37,43.88c0.52,0.58,1,1.19,1.55,1.74c1.94,1.96,3.91,3.88,5.84,5.86c0.3,0.31,0.61,0.79,0.61,1.2
		c0.04,4.07,0.02,8.14,0.01,12.21c0,0.11-0.06,0.22-0.12,0.39c-2.7,0-5.39,0-8.16,0c0-7.1,0-14.13,0-21.16
		C385.19,44.03,385.28,43.95,385.37,43.88z"
        />
        <path
          :class="colorClass"
          d="M602.81,65.35c-1.21,0-2.2,0.08-3.16-0.04c-0.35-0.04-0.74-0.5-0.94-0.87c-4.07-7.76-8.12-15.53-12.17-23.3
		c-1.36-2.61-2.7-5.24-4.11-7.83c-0.41-0.75-0.47-1.35,0.01-2.08c0.47-0.72,0.83-1.51,1.31-2.42
		C590.14,41.04,596.42,53.1,602.81,65.35z"
        />
        <path
          :class="colorClass"
          d="M552.54,28.53c0.99,0,1.89,0,2.87,0c0,12.27,0,24.5,0,36.78c-0.97,0-1.87,0-2.87,0C552.54,53.07,552.54,40.86,552.54,28.53
		z"
        />
        <path
          :class="colorClass"
          d="M502.36,28.61c0,0.98,0,1.91,0,2.92c-10.72,0-21.41,0-32.16,0c0-0.97,0-1.91,0-2.92C480.92,28.61,491.6,28.61,502.36,28.61
		z"
        />
        <path
          :class="colorClass"
          d="M71.91,62.63c1.35,0.77,2.65,1.51,4.05,2.31c-1.49,3.46-3.41,6.55-6.24,8.96c-1.35,1.15-2.94,2.03-4.47,2.94
		c-0.28,0.16-0.94,0.04-1.21-0.19c-1.74-1.52-3.43-3.1-5.11-4.69c-0.26-0.25-0.5-0.65-0.51-1c-0.05-1.54-0.02-3.09-0.02-4.95
		c0.78,0.72,1.37,1.27,1.97,1.82c1.17,1.08,2.41,2.11,3.49,3.27c0.64,0.69,1.16,0.62,1.86,0.24c2.05-1.12,3.37-2.89,4.41-4.9
		C70.76,65.23,71.3,63.95,71.91,62.63z"
        />
        <path
          :class="colorClass"
          d="M16.28,65.31c1.39-0.79,2.7-1.54,4.08-2.33c0.54,1.16,1,2.29,1.57,3.37c1.08,2.03,2.39,3.87,4.49,5
		c0.7,0.38,1.21,0.41,1.86-0.25c1.68-1.69,3.47-3.26,5.43-5.07c0,1.86,0.03,3.47-0.02,5.08c-0.01,0.3-0.28,0.65-0.52,0.88
		c-1.66,1.56-3.37,3.07-5,4.65c-0.51,0.49-0.92,0.44-1.48,0.18c-4.31-2.04-7.18-5.49-9.41-9.56C16.95,66.67,16.66,66.05,16.28,65.31
		z"
        />
        <path
          :class="colorClass"
          d="M578.09,39.6c0.61,1.18,1.14,2.11,1.56,3.09c0.13,0.3,0.05,0.78-0.11,1.08c-3.61,6.97-7.24,13.93-10.9,20.87
		c-0.17,0.33-0.68,0.63-1.06,0.67c-0.9,0.1-1.81,0.03-2.91,0.03C569.16,56.73,573.58,48.27,578.09,39.6z"
        />
        <path
          :class="colorClass"
          d="M510.29,37.16c1.01,0,1.92,0,2.96,0c0,0.52,0,0.99,0,1.46c0,6.34-0.05,12.67,0.04,19.01c0.02,1.33-0.35,2.09-1.6,2.47
		c-0.44,0.13-0.85,0.39-1.39,0.64C510.29,52.82,510.29,45.04,510.29,37.16z"
        />
        <path
          :class="colorClass"
          d="M426.77,41.25c1.08,1.11,2,2.02,2.88,2.98c0.16,0.17,0.2,0.51,0.2,0.77c0.01,6.66,0,13.32-0.01,19.98
		c0,0.07-0.04,0.15-0.08,0.3c-0.95,0-1.93,0-2.98,0C426.77,57.37,426.77,49.47,426.77,41.25z"
        />
        <path
          :class="colorClass"
          d="M12.85,50.1c1.81,0,3.51,0,5.19,0c0.3,2.37,0.54,4.7,0.89,7c0.42,2.76,0.46,2.75-2.02,4.15c-0.55,0.31-1.11,0.63-1.8,1.02
		C13.7,58.31,12.98,54.3,12.85,50.1z"
        />
        <path
          :class="colorClass"
          d="M72.89,59.49c0.42-3.2,0.81-6.23,1.22-9.35c1.61,0,3.31,0,5.16,0c-0.08,4.02-0.82,7.88-2.11,11.77
		C75.64,61.05,74.23,60.25,72.89,59.49z"
        />
        <path
          :class="colorClass"
          d="M18.03,46.82c-1.7,0-3.44,0-5.45,0c0.47-3.51,0.92-6.91,1.4-10.55c1.74,0.98,3.19,1.77,4.6,2.62
		c0.19,0.11,0.3,0.53,0.28,0.79C18.62,42.01,18.33,44.34,18.03,46.82z"
        />
        <path
          :class="colorClass"
          d="M78.15,36.27c0.48,3.61,0.92,7.01,1.39,10.55c-1.98,0-3.71,0-5.44,0c-0.3-2.44-0.6-4.87-0.87-7.31
		c-0.02-0.21,0.17-0.54,0.36-0.65C75,38.03,76.42,37.24,78.15,36.27z"
        />
        <path
          class="st0"
          d="M159.31,35.17c2.04,0,4.08-0.01,6.12,0c1.68,0.01,2.92,1.29,2.91,2.97c-0.01,1.67-1.28,2.99-2.93,3
		c-4.16,0.01-8.32,0.01-12.48,0c-1.63,0-2.93-1.36-2.93-3.01c0-1.66,1.27-2.95,2.94-2.96C155.08,35.16,157.19,35.17,159.31,35.17z"
        />
        <path
          class="st0"
          d="M159.19,56.24c-2.04,0-4.08,0.01-6.12,0c-1.74-0.01-3.05-1.3-3.05-3c0-1.7,1.3-2.97,3.06-2.98
		c4.08-0.01,8.16-0.01,12.24,0c1.78,0,3.04,1.25,3.04,2.98c0,1.72-1.28,2.98-3.04,2.99C163.27,56.25,161.23,56.24,159.19,56.24z"
        />
        <path
          class="st0"
          d="M237.98,45.84c-0.02-6.26,5.05-11.36,11.31-11.39c6.33-0.03,11.49,5.05,11.51,11.33
		c0.02,6.26-5.17,11.44-11.45,11.42C243.1,57.18,237.99,52.09,237.98,45.84z"
        />
        <path
          class="st0"
          d="M344.3,45.8c0.01-6.32,5.12-11.38,11.46-11.36c6.25,0.02,11.37,5.14,11.36,11.36
		c-0.01,6.27-5.2,11.42-11.49,11.39C349.38,57.17,344.29,52.05,344.3,45.8z"
        />
        <path
          class="st0"
          d="M55.17,55.82c-5.57-0.01-10.05-4.49-10.03-10.04c0.02-5.52,4.58-10.01,10.13-9.96c5.5,0.05,9.97,4.52,9.98,10
		C65.26,51.35,60.74,55.83,55.17,55.82z M48.35,39.43c-1.05,1.48-2.19,2.62-1.64,4.6c0.32,1.14,0.31,2.51-0.01,3.65
		c-0.55,1.98,0.63,3.09,1.65,4.58C50.52,47.89,50.53,43.76,48.35,39.43z"
        />
        <path
          :class="colorClass"
          d="M48.35,39.43c2.18,4.32,2.17,8.45,0.01,12.83c-1.03-1.49-2.2-2.6-1.65-4.58c0.32-1.14,0.32-2.51,0.01-3.65
		C46.16,42.06,47.31,40.91,48.35,39.43z"
        />
      </svg>
    </svg>
  </div>
</template>

<script>
export default {
  name: "textIcon",
  props: {
    width: Number,
    height: Number,
    colorClass: String,
    link: String,
  },
  methods: {
    handleLink: function () {
      if (this.link) {
        this.$router.push(this.link);
      }
    },
  },
  computed: {
    svgWidth: function () {
      if (this.width && this.width > 0) {
        return this.width;
      }
      return "100%";
    },
    svgHeight: function () {
      if (this.height && this.height > 0) {
        return this.height;
      }
      return "100%";
    },
  },
};
</script>

<style type="text/css">
.st1 {
  fill: red;
}
.st0 {
  /* fill: #ffffff; */
}
.primary {
  fill: var(--v-primary-base);
}
.secondary {
  fill: var(--v-secondary-base);
}
.text {
  fill: var(--v-text-base);
}
.background {
  fill: var(--v-background-base);
}
.white {
  fill: #ffffff;
}
.black {
  fill: #000000;
}
.cls-1 {
  fill: transparent;
}
.cls-2 {
  fill: #162183;
}
.cls-3 {
  fill: #3fad3d;
}
.cls-4 {
  fill: transparent;
}
.cls-5 {
  fill: transparent;
}
.cls-6 {
  fill: transparent;
}
.cls-7 {
  fill: transparent;
}
.cls-8 {
  fill: transparent;
}
.cls-9 {
  fill: transparent;
}
</style>
