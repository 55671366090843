<template>
  <div class="prevent-full-width mx-auto my-8">
    <div
      class="project-wrapper mx-3"
      v-for="(project, i) in projects"
      :key="project.id"
    >
      <div class="project-slider">
        <ProjectShowcase
          :project="project"
          :id="'p-' + project.id"
          v-on:overlay="handleOverlay"
          v-on:showVideo="handleVideoOverlay"
          :index="i"
        />
      </div>
      <v-divider v-if="i != projects.length - 1" class="text my-8"></v-divider>
    </div>
    <v-overlay
      v-model="overlay"
      :opacity="$vuetify.breakpoint.mdAndDown ? 0.9 : 0.8"
      @click="overlay = false"
      style="cursor: pointer"
      v-if="selectedItem"
    >
      <div class="d-flex">
        <!-- display: grid; place-items: center -->
        <div
          style="
            position: fixed;
            left: 2%;
            top: 50%;
            transform: translateY(-50%);
          "
        >
          <div>
            <v-btn @click.stop="prevImage(selectedItem)" icon>
              <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>
          </div>
        </div>
        <div>
          <img
            class="image-border"
            :src="
              $cdnUrl +
              selectedItem.key.replace('BREAKPOINT', getNextBiggerBreakpoint())
            "
          />
        </div>
        <div
          style="
            position: fixed;
            right: 2%;
            top: 50%;
            transform: translateY(-50%);
          "
        >
          <div>
            <v-btn @click.stop="nextImage(selectedItem)" icon>
              <v-icon large>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div v-if="$globalState.isLoggedIn" class="image-admin-tools">
          <div>
            <v-btn icon small @click.stop="changeHighlightState">
              <v-icon v-if="selectedItem.highlight">mdi-heart</v-icon>
              <v-icon v-else>mdi-heart-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-overlay>
    <v-dialog
      v-model="videoDialog"
      :opacity="$vuetify.breakpoint.mdAndDown ? 0.9 : 0.8"
      v-if="video.url"
      persistent
      @click:outside="stopVideo"
      @keydown="handleKeydown"
    >
      <!-- <div class="video-dialog"> -->
      <LazyYoutube
        ref="lazyVideo"
        class="video-embed"
        :src="video.url"
        :showTitle="false"
        :max-width="$vuetify.breakpoint.width.toString()"
        :customThumbnail="video.thumbnail"
        thumbnailQuality="maxres"
      />
      <!-- <v-btn class="stop-btn">X</v-btn>
      </div> -->
    </v-dialog>
  </div>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
import { getGenreProjects } from "../plugins/vuetify/helpers";
import ProjectShowcase from "../components/projectShowcase.vue";
export default {
  name: "PortofolioItem",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: this.genreName,
      meta: [
        {
          name: "description",
          content: "Photography view of " + this.$applicationName,
        },
      ],
    };
  },
  components: {
    ProjectShowcase,
  },
  data() {
    return {
      projects: [],
      overlay: false,
      videoDialog: false,
      video: {
        url: null,
        thumbnail: null,
      },
      selectedItem: null,
      genreName: "",
    };
  },
  async mounted() {
    if (
      !this.$route.params ||
      !this.validatePortfolioItem(this.$route.params.item)
    ) {
      this.$router.push({ name: "Portfolio" });
    } else if (this.$route.query && this.$route.query.p) {
      this.$router.push({
        name: "PortfolioItem",
        params: { item: this.$route.params.item },
        hash: "#p-" + this.$route.query.p,
      });
    }
    await this.getGenreProjects();
  },
  watch: {
    "$route.params.item": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.validatePortfolioItem(newVal)) {
          this.projects = [];
          this.getGenreProjects();
        } else {
          this.$router.push({ name: "Portfolio" });
        }
      }
    },
  },

  methods: {
    nextImage: function (item) {
      let images = item.images;
      let index = item.index;
      if (index === images.length - 1) {
        let newImage = images[0];
        let overlayItem = newImage;
        overlayItem.images = images;
        overlayItem.index = 0;
        this.selectedItem = overlayItem;
      } else {
        let newImage = images[index + 1];
        let overlayItem = newImage;
        overlayItem.images = images;
        overlayItem.index = index + 1;
        this.selectedItem = overlayItem;
      }
    },
    prevImage: function (item) {
      let images = item.images;
      let index = item.index;
      if (index === 0) {
        let newImage = images[images.length - 1];
        let overlayItem = newImage;
        overlayItem.images = images;
        overlayItem.index = images.length - 1;
        this.selectedItem = overlayItem;
      } else {
        let newImage = images[index - 1];
        let overlayItem = newImage;
        overlayItem.images = images;
        overlayItem.index = index - 1;
        this.selectedItem = overlayItem;
      }
    },
    handleOverlay: function (item) {
      let selectedItem = item.selected;
      let images = item.images;
      //get index of selected image in images array
      let index = images.findIndex((image) => image.id === selectedItem.id);
      let overlayItem = selectedItem;
      overlayItem.images = images;
      overlayItem.index = index;

      let notify = {
        duration: 2000,
        type: "info",
        headline: "",
        message: "Click anywhere to close this view",
      };
      this.$globalState.addNotification(notify);

      this.selectedItem = overlayItem;

      this.overlay = true;
    },
    changeHighlightState: async function () {
      if (this.selectedItem) {
        const { error } = await supabase
          .from("image")
          .update({ highlight: !this.selectedItem.highlight })
          .eq("id", this.selectedItem.id);
        if (error) {
          console.log(error);
        } else {
          this.selectedItem.highlight = !this.selectedItem.highlight;

          let notify = {
            duration: 2000,
            type: "info",
            headline: "Image Highlight",
            message: "",
          };
          if (this.selectedItem.highlight) {
            notify.message = "Image is now highlighted";
          } else {
            notify.message = "Image is no longer highlighted";
          }

          this.$globalState.addNotification(notify);
        }
      }
    },
    handleKeydown: function (e) {
      if (e.key === "Escape") {
        this.stopVideo();
      }
    },
    stopVideo: function () {
      this.$refs.lazyVideo.stopVideo();
      this.videoDialog = false;
      this.video.url = null;
      this.video.thumbnail = null;
    },
    handleVideoOverlay: function (item) {
      this.video = item;
      this.videoDialog = true;
      setTimeout(() => {
        this.$refs.lazyVideo.playVideo();
      }, 1000);
    },
    validatePortfolioItem: function (item) {
      let validItems = this.$globalState.genres.filter((genre) => {
        return genre.name.toLowerCase() === item;
      });
      if (validItems.length > 0) {
        this.genreName = validItems[0].name;
        return true;
      }
      return false;
    },
    getGenreProjects: async function () {
      //get genre id of genre photography
      const { data } = await supabase
        .from("genre")
        .select("id")
        .eq("name", this.genreName);

      let genreId = data[0].id;
      //get all projects with provided genre id
      let projects = await getGenreProjects(genreId);

      for (let i = 0; i < projects.length; i++) {
        let project = projects[i];
        if (project.video_url) {
          this.projects.push(project);
        } else {
          //check amount of images with id of project
          const { data } = await supabase
            .from("image")
            .select("id")
            .eq("project_id", project.id);

          if (data.length > 0) {
            this.projects.push(project);
          }
        }
      }
    },
    getNextBiggerBreakpoint: function () {
      let breakpoints = ["og", "xl", "lg", "md", "sm", "xs"];
      let currentBreakpoint = this.$vuetify.breakpoint.name;
      let nextBiggerBreakpoint =
        breakpoints[breakpoints.indexOf(currentBreakpoint) - 1];
      return nextBiggerBreakpoint;
    },
  },
};
</script>
<style scoped>
.image-admin-tools {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.video-embed {
  width: 70vw;
}
.image-border {
  display: block;
  max-width: 80vw;
  max-height: 80vh;
  width: auto;
  height: auto;
}
</style>
