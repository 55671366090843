<template>
  <div>
    <v-app-bar app color="deepBlack" class="hidden-sm-and-down">
      <div class="navbar-items-wrapper">
        <div :class="getNavBarItemClass('/')" @click="routeTo('/')">
          <span>Home</span>
        </div>

        <div class="dropdown">
          <div
            :class="'dropbtn ' + getNavBarItemClass('/portfolio')"
            @click="routeTo('/portfolio')"
          >
            <span>Portfolio</span>
          </div>
          <div class="dropdown-content">
            <span
              v-for="portfolioItem in portfolioItems"
              :key="portfolioItem.route"
              :class="getDropDownItemClass(portfolioItem.route)"
              @click="routeTo(portfolioItem.route)"
              >{{ portfolioItem.title }}</span
            >
          </div>
        </div>
        <Icon colorClass="text" :width="40" style="cursor: pointer" link="/" />
        <div class="dropdown">
          <div
            :class="'dropbtn ' + getDropDownAboutItemClass()"
            @click="routeTo('/team')"
          >
            <span>Über</span>
          </div>
          <div class="dropdown-content">
            <span
              v-for="aboutItem in aboutItems"
              :key="aboutItem.route"
              :class="getDropDownItemClass(aboutItem.route)"
              @click="routeTo(aboutItem.route)"
              >{{ aboutItem.title }}</span
            >
          </div>
        </div>
        <div
          :class="getNavBarItemClass('/contact')"
          @click="routeTo('/contact')"
        >
          <span>Kontakt</span>
        </div>
      </div>
      <AdminMenu v-if="$globalState.isLoggedIn" class="admin-menu" />
    </v-app-bar>
    <v-app-bar app color="background" class="hidden-md-and-up">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <textIcon
        class="pt-2 absolute-center"
        colorClass="text"
        :height="40"
        style="cursor: pointer"
        link="/"
      />
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      disable-resize-watcher
      color="background"
    >
      <div style="border-bottom: 1px solid var(--v-text-base)">
        <v-app-bar
          color="background"
          class="d-flex justify-center align-center"
        >
          <Icon
            colorClass="text"
            :width="40"
            style="cursor: pointer"
            link="/"
          />
        </v-app-bar>
      </div>
      <v-list nav>
        <div class="ma-0 pa-0" v-for="view in drawerViews" :key="view.name">
          <v-list-item
            v-if="!view.group"
            link
            :to="view.link"
            active-class="secondary--text"
          >
            <v-list-item-icon>
              <v-icon>{{ view.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ view.name }}
            </v-list-item-title>
          </v-list-item>
          <v-list-group
            v-else
            :prepend-icon="view.icon"
            active-class="secondary--text"
            v-model="view.active"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ view.name }}</v-list-item-title>
            </template>
            <div v-for="item in view.items" :key="item.name">
              <v-list-item link :to="item.link" active-class="secondary--text">
                <v-list-item-icon class="ml-4">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="ml-n4">
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list-group>
        </div>
      </v-list>
      <v-footer absolute color="background">
        <v-list>
          <v-list-item v-if="$globalState.isLoggedIn" @click="handleSignOut">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ml-n4"> Abmelden </v-list-item-title>
          </v-list-item>
          <v-list-item v-else to="/login">
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ml-n4"> Anmelden </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-footer>
    </v-navigation-drawer>
  </div>
</template>
<script>
import Icon from "@/components/icon.vue";
import textIcon from "@/components/textIcon.vue";
import AdminMenu from "@/components/adminMenu.vue";

import { supabase } from "../plugins/supabase/supabase";
export default {
  name: "navigation",
  components: {
    Icon,
    textIcon,
    AdminMenu,
  },
  data() {
    return {
      drawer: false,
      aboutItems: [
        {
          title: "Team",
          route: "/team",
          icon: "mdi-account-group",
        },
        {
          title: "Ausrüstung",
          route: "/gear",
          icon: "mdi-camera",
        },
        // {
        //   title: "Services",
        //   route: "/services",
        // },
        {
          title: "Socials",
          route: "/socials",
          icon: "mdi-monitor-cellphone",
        },
      ],
      portfolioItems: [
        {
          title: "Highlights",
          route: "/portfolio",
          icon: "mdi-spotlight",
        },
        {
          title: "Photography",
          route: "/portfolio/photography",
          icon: "mdi-camera",
        },
        {
          title: "Videography",
          route: "/portfolio/videography",
          icon: "mdi-video",
        },
        {
          title: "Mehr",
          route: "/more",
          icon: "mdi-dots-horizontal",
        },
      ],
    };
  },
  computed: {
    drawerViews: function () {
      let baseViews = [
        {
          name: "Home",
          link: "/",
          icon: "mdi-home",
          group: false,
          active: false,
        },
      ];
      let portfolioGroupItem = {
        name: "Portfolio",
        icon: "mdi-folder-multiple-image",
        group: true,
        active: false,
        items: [],
      };
      for (let i = 0; i < this.portfolioItems.length; i++) {
        portfolioGroupItem.items.push({
          name: this.portfolioItems[i].title,
          link: this.portfolioItems[i].route,
          icon: this.portfolioItems[i].icon,
          active: false,
        });
      }
      baseViews.push(portfolioGroupItem);
      let aboutGroupItem = {
        name: "About",
        icon: "mdi-account-group",
        group: true,
        active: false,
        items: [],
      };
      for (let i = 0; i < this.aboutItems.length; i++) {
        aboutGroupItem.items.push({
          name: this.aboutItems[i].title,
          link: this.aboutItems[i].route,
          icon: this.aboutItems[i].icon,
          active: false,
        });
      }
      baseViews.push(aboutGroupItem);
      baseViews.push({
        name: "Contact",
        link: "/contact",
        icon: "mdi-email",
        active: false,
      });

      if (this.$globalState.isLoggedIn) {
        let adminGroupItem = {
          name: "Admin",
          icon: "mdi-account-cog",
          group: true,
          active: false,
          items: [],
        };
        for (let i = 0; i < this.$globalState.adminDrawerItems.length; i++) {
          adminGroupItem.items.push({
            name: this.$globalState.adminDrawerItems[i].name,
            link: this.$globalState.adminDrawerItems[i].link,
            icon: this.$globalState.adminDrawerItems[i].icon,
            active: false,
          });
        }
        baseViews.push(adminGroupItem);
      }
      return baseViews;
    },
  },
  methods: {
    handleSignOut: async function () {
      await supabase.auth.signOut();
      this.$globalState.isLoggedIn = false;
      this.$router.push({ name: "Home" });
    },
    getNavBarItemClass(route) {
      if (this.$route.path.includes(route) && route !== "/") {
        return "navbar-item active";
      } else {
        return "navbar-item";
      }
    },
    getDropDownAboutItemClass: function () {
      let currentRoute = this.$route.path;
      let aboutItem = this.aboutItems.find(
        (item) => item.route === currentRoute
      );
      if (aboutItem) {
        return "navbar-item active";
      } else {
        return "navbar-item";
      }
    },
    getDropDownItemClass(route) {
      return this.$route.path === route
        ? "dropdown-item active-dropdown-item"
        : "dropdown-item";
    },
    routeTo: function (route) {
      this.$router.push({ path: route });
    },
  },
};
</script>
<style scoped>
.dropdown-item {
  cursor: pointer;
}
.admin-menu {
  position: absolute;
  right: 0;
}
.active-dropdown-item {
  outline: 2px solid #f5f5f5;
}
.dropdown-content {
  display: none;
  position: absolute;
  top: 50px;
  background-color: var(--v-background-base);
  min-width: 80px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
}

.dropdown-content span {
  background-color: var(--v-background-base);
  color: var(--v-text-base);
  padding: 10px 16px;
  text-decoration: none;
  display: block;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
}

.dropdown-content span:hover {
  background-color: #f1f1f1;
  color: var(--v-background-base);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: var(--v-text-base);
  color: var(--v-background-base);
}
.navbar-items-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.active {
  outline: 2px solid var(--v-text-base);
  border-radius: 5px;
}
.navbar-item {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
}
.navbar-item:hover {
  background-color: var(--v-text-base);
  color: var(--v-background-base);
  border-radius: 5px;
  outline: 2px solid var(--v-text-base);
}
.navbar-item span {
  padding: 10px;
  text-transform: uppercase;
  font-size: 1.1rem;
}
.absolute-center {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
</style>
