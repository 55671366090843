import Vue from "vue";
import { supabase } from "@/plugins/supabase/supabase.js";

async function getProfile() {
  let responsePayload = {
    state: false,
    firstLogin: false,
    msg: "",
    profile: null,
  };
  let response = await supabase.from("profile").select();

  if (response.error === null) {
    if (response.data.length > 0) {
      let data = response.data[0];
      data.email = supabase.auth.user().email;
      Vue.prototype.$globalState.profile = data;
      if (data.first_name != null) {
        responsePayload.state = true;
        responsePayload.msg = "Fetched profile!";
        responsePayload.profile = data;
      } else {
        responsePayload.msg = "First login!";
        responsePayload.firstLogin = true;
        responsePayload.profile = data;
      }
    } else {
      responsePayload.msg = "No data found!";
    }
  } else {
    responsePayload.msg = response.error;
  }
  return responsePayload;
}

async function getGenreProjects(genreId) {
  //get all projects for genre
  let response = await supabase
    .from("project")
    .select()
    .match({ genre_id: genreId });
  if (response.error) {
    let notify = {
      duration: 4000,
      type: "error",
      headline: "Loading Projects",
      message: "Failed loading Projects. Please try again later",
    };
    this.$globalState.addNotification(notify);
  } else if (response.data && response.data.length > 0) {
    let projects = response.data;
    //get images for projects
    for (let i = 0; i < projects.length; i++) {
      response = await supabase
        .from("image")
        .select()
        .match({ project_id: projects[i].id });
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Loading Images",
          message:
            "Failed loading Images for Project '" +
            projects[i].title +
            "'. Please try again later",
        };
        Vue.prototype.$globalState.addNotification(notify);
      } else if (response.data && response.data.length > 0) {
        projects[i].images = response.data;
      }
    }
    return projects;
  }
  return null;
}

export { getProfile, getGenreProjects };
