<template>
  <div>
    <v-card elevation="0">
      <v-container>
        <v-row v-if="title">
          <v-col>
            <span class="text-h6 font-weight-bold">{{ title }} </span>
          </v-col>
        </v-row>
        <v-form v-model="validMail" ref="mailForm">
          <v-row>
            <v-col class="py-0">
              <v-textarea
                color="secondary"
                label="Message"
                :rules="[notEmpty]"
                v-model="mail.message"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col>
            <v-btn
              color="secondary"
              :disabled="!validMail"
              @click="sendMail"
              :loading="loading"
            >
              Send
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { supabase } from "../plugins/supabase/supabase";
export default {
  name: "contactClientForm",
  props: {
    title: String,
    ticketId: String,
  },
  data() {
    return {
      validMail: false,
      mail: {
        message: "",
      },
      loading: false,
    };
  },
  methods: {
    //Methods
    sendMail: async function () {
      this.loading = true;
      let payload = {
        token: supabase.auth.session().access_token,
        message: this.mail.message,
        ticketId: this.ticketId,
      };
      let response = await fetch(this.$apiUrl + "/ticket/contact", {
        method: "POST",
        body: JSON.stringify(payload),
      });

      let responseData = await response.json();

      if (responseData.state) {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Mail",
          message: responseData.msg,
        };
        this.$globalState.addNotification(notify);
        this.$emit("sentMail");
        this.$refs.mailForm.reset();
        setTimeout(() => {
          this.$emit("pushTab", 0);
        }, 1500);
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Mail",
          message: responseData.msg,
        };
        this.$globalState.addNotification(notify);
      }
      this.loading = false;
    },

    //RULES
    notEmpty: function (input) {
      if (input && input.length > 0) {
        return true;
      }
      return "This field is required!";
    },
  },
};
</script>
<style scoped></style>
