<template>
  <div>
    <v-card elevation="0" color="deepBlack">
      <v-card-title>Upload to CDN</v-card-title>
      <v-stepper v-model="uploadStep" elevation="0" class="deepBlack">
        <v-stepper-header class="elevation-0">
          <v-stepper-step :complete="uploadStep > 1" step="1">
            Project Select
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="uploadStep > 2" step="2">
            Select & Crop Images
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="uploadStep > 3" step="3">
            Select Title Image
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step step="4"> Select Highlights </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1" class="pt-0">
            <v-container>
              <v-form ref="projectSelect">
                <v-row>
                  <v-col>
                    <v-autocomplete
                      label="Project"
                      :rules="[notEmpty]"
                      :items="projects"
                      item-text="title"
                      item-value="id"
                      v-model="stepper.uploadProject"
                      @change="prepareImageSelect"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-btn outlined color="error" @click="resetStepper" class="mr-4"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              @click="uploadStep = 2"
              :disabled="!stepper.uploadProject"
            >
              Continue
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2" class="pt-0">
            <v-container>
              <!-- TODO Remove crop panels? -->
              <v-row>
                <v-col>
                  <cropPanels
                    ref="cropPanels"
                    v-on:cancel="resetStepper"
                    v-on:back="uploadStep = 1"
                    v-on:continue="
                      (images) => {
                        uploadStep = 3;
                        stepper.images = images;
                      }
                    "
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-container v-if="!stepper.existingTitleImage">
              <v-row>
                <v-col>
                  <div v-if="stepper.images && stepper.images.length > 0">
                    <div class="scrollable">
                      <v-item-group v-model="stepper.titleImageIndex">
                        <v-row>
                          <v-col>
                            <div :style="gridContainerStyle">
                              <div
                                v-for="(image, index) in stepper.images"
                                :key="index"
                              >
                                <!-- TODO Use masonry-wall to display images -->
                                <v-item v-slot="{ active, toggle }">
                                  <v-img
                                    :src="image.src"
                                    contain
                                    @click="toggle"
                                  >
                                    <v-container v-if="active">
                                      <v-row>
                                        <v-col>
                                          <v-btn icon dark>
                                            <v-icon color="success" large>
                                              mdi-check-decagram
                                            </v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-img>
                                </v-item>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-item-group>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-else>
              <v-row>
                <v-col class="d-flex justify-end align-center">
                  <imageDetail
                    v-if="stepper.existingTitleImage"
                    :src="stepper.existingTitleImage.key"
                    :width="maxWidthImages"
                  />
                </v-col>
                <v-col class="d-flex justify-start align-center">
                  <span class="font-weight-bold"
                    >Title-Image already selected</span
                  >
                </v-col>
              </v-row>
            </v-container>
            <v-btn outlined color="error" @click="resetStepper" class="mr-4"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              outlined
              @click="uploadStep = uploadStep - 1"
              class="mr-4"
              >Back</v-btn
            >
            <v-btn
              color="primary"
              :disabled="
                stepper.titleImageIndex === null && !stepper.existingTitleImage
              "
              @click="uploadStep = 4"
            >
              Continue
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-container>
              <v-row>
                <v-col>
                  <div v-if="stepper.images && stepper.images.length > 0">
                    <div class="scrollable">
                      <v-item-group v-model="stepper.highlightIndexes" multiple>
                        <v-row>
                          <v-col>
                            <div :style="gridContainerStyle">
                              <div
                                v-for="(image, index) in stepper.images"
                                :key="index"
                              >
                                <v-item v-slot="{ active, toggle }">
                                  <v-img
                                    :src="image.src"
                                    contain
                                    @click="toggle"
                                  >
                                    <!-- TODO Use masonry-wall to display images -->
                                    <v-container v-if="active">
                                      <v-row>
                                        <v-col>
                                          <v-btn icon dark>
                                            <v-icon color="success" large>
                                              mdi-check-decagram
                                            </v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-img>
                                </v-item>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-item-group>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-row>
              <v-col cols="4" class="d-flex align-center">
                <v-btn outlined color="error" @click="resetStepper" class="mr-4"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  @click="uploadStep = uploadStep - 1"
                  class="mr-4"
                >
                  Back
                </v-btn>
                <v-btn
                  v-if="uploadPercentage === 100 && uploadLoading === false"
                  color="primary"
                  @click="resetStepper"
                >
                  Finish
                </v-btn>
                <v-btn
                  v-else
                  color="success"
                  @click="upload"
                  :loading="uploadLoading"
                >
                  Upload
                </v-btn>
              </v-col>
              <v-col class="d-flex align-center">
                <v-container>
                  <v-row no-gutters>
                    <v-col class="font-weight-bold">
                      {{ currentAction }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-progress-linear
                        :indeterminate="uploadPercentage === 0"
                        :value="uploadPercentage"
                        color="primary"
                      ></v-progress-linear>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </div>
</template>
<script>
import { PutObjectCommand } from "@aws-sdk/client-s3";
import { createClient, bucketName } from "../plugins/s3/s3client";
import { supabase } from "../plugins/supabase/supabase.js";

import cropPanels from "../components/cropPanels.vue";
import imageDetail from "../components/imageDetail.vue";

const stepperDefault = Object.freeze({
  uploadProject: null,
  images: [],
  existingTitleImage: null,
  titleImageIndex: null,
  highlightIndexes: [],
});
export default {
  name: "cdnUpload",
  data() {
    return {
      stepper: Object.assign({}, stepperDefault),
      uploadStep: 1,
      projects: [],
      previewWidth: 200,
      uploadLoading: false,
      currentAction: "Waiting for Upload",
      uploadPercentage: 0,
    };
  },
  components: {
    cropPanels,
    imageDetail,
  },
  async mounted() {
    this.getProjects();
  },
  computed: {
    gridContainerStyle: function () {
      return (
        "display: grid; grid-template-columns: repeat(auto-fill, minmax(" +
        this.maxWidthImages +
        "px, 1fr)); grid-gap: 10px; grid-auto-rows: minmax(" +
        this.maxWidthImages +
        "px, 1fr);"
      );
    },
    maxWidthImages: function () {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 150;
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return 170;
      } else {
        return 300;
      }
    },
  },
  methods: {
    upload: async function () {
      this.uploadLoading = true;
      let images = this.stepper.images;
      let genreId = null;
      let projectId = this.stepper.uploadProject;
      let imgRatio = 1;
      let type = "image/webp";

      this.currentAction = "Initializing upload";

      //get genreId
      let response = await supabase
        .from("project")
        .select()
        .match({ id: projectId });
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Upload",
          message: "Failed getting genreId",
        };
        this.$globalState.addNotification(notify);
      } else if (response.data && response.data.length > 0) {
        genreId = response.data[0].genre_id;
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Upload",
          message: "Failed getting genreId",
        };
        this.$globalState.addNotification(notify);
      }

      for (let i = 0; i < images.length; i++) {
        let image = images[i];

        this.uploadPercentage = ((i + 1) / images.length) * 100 - 10;

        //check if image is title image
        let isTitleImage = false;
        if (this.stepper.titleImageIndex === i) {
          isTitleImage = true;
        }

        //check if image is highlight image
        let isHighlightImage = false;
        if (this.stepper.highlightIndexes.includes(i)) {
          isHighlightImage = true;
        }

        //create image to get image id
        response = await supabase.from("image").insert([
          {
            genre_id: genreId,
            project_id: projectId,
            name: image.name,
            key: "REPLACE_" + Date.now(),
            title: isTitleImage,
            highlight: isHighlightImage,
            created: Date.now(),
            updated: Date.now(),
          },
        ]);

        let imageData = response.data[0];
        let path =
          "public/dynamic/image/" +
          imageData.genre_id +
          "/" +
          imageData.project_id +
          "/" +
          imageData.id +
          "/BREAKPOINT.webp";

        response = await supabase
          .from("image")
          .update({ key: path, updated: Date.now() })
          .match({ id: imageData.id });

        this.currentAction =
          "Resizing & Uploading image '" +
          image.name +
          "' - " +
          (i + 1) +
          " of " +
          images.length;
        for (let k = 0; k < this.$globalState.imageVariants.length; k++) {
          let variant = this.$globalState.imageVariants[k];

          let base64 = await this.blobToBase64(image.blob);

          if (variant.width != -1) {
            //RESIZE

            base64 = await this.resizeImage(
              base64,
              variant.width,
              variant.width * imgRatio
            );
          }

          //create uploadable buffer
          let base64Buffer = new Buffer.from(
            base64.replace(/^data:image\/\w+;base64,/, ""),
            "base64"
          );

          //UPLOAD
          await this.uploadS3(
            path.replace("BREAKPOINT", variant.breakpoint),
            base64Buffer,
            "public-read",
            type
          );
        }
      }
      this.uploadPercentage = 100;
      this.currentAction = "Done";
      this.$emit("fetchImages");
      this.uploadLoading = false;
    },
    uploadS3: async function (key, body, permission, mimeType) {
      const params = {
        Bucket: bucketName, // The path to the directory you want to upload the object to, starting with your Space name.
        Key: key, // Object key, referenced whenever you want to access this file later.
        Body: body, // The object's contents. This variable is an object, not a string.
        ACL: permission, // Defines ACL permissions, such as private or public.
        ContentType: mimeType,
      };
      let s3Client = await createClient();
      try {
        await s3Client.send(new PutObjectCommand(params));
        // this.listBucketItems();
      } catch (err) {
        console.log("Error", err);
      }
    },
    resizeImage: function (base64Str, maxWidth, maxHeight) {
      return new Promise((resolve) => {
        let img = new Image();
        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let MAX_WIDTH = maxWidth;
          const MAX_HEIGHT = maxHeight;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL("image/webp"));
        };
      });
    },
    blobToBase64: function (blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    prepareImageSelect: async function () {
      this.stepper.existingTitleImage = await this.getProjectTitleImage(
        this.stepper.uploadProject
      );
    },
    getProjectTitleImage: async function (projectId) {
      let response = await supabase.from("image").select().match({
        project_id: projectId,
        title: true,
      });

      if (response.error) {
        return null;
      } else if (response.data.length > 0) {
        return response.data[0];
      } else {
        return null;
      }
    },
    resetStepper: function () {
      this.$emit("closeUpload");
      this.stepper = Object.assign({}, stepperDefault);
      this.$refs.projectSelect.reset();
      this.$refs.cropPanels.resetPanel();
      this.uploadStep = 1;
      this.uploadPercentage = 0;
      this.currentAction = "Waiting for Upload";
    },
    getProjects: async function () {
      let response = await supabase.from("project").select();

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Loading Projects",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        this.projects = response.data;
      }
    },

    //rules
    notEmpty: function (input) {
      if (input) {
        return true;
      }
      return "This field is required";
    },
  },
};
</script>
<style scoped>
.scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 60vh;
}
</style>
