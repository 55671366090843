<template>
  <v-card elevation="0">
    <v-container>
      <v-row>
        <v-col>
          <v-data-table
            :headers="attachmentTable.header"
            :items="attachmentTable.items"
            @click:row="handleAttachmentView"
            disable-pagination
            multi-sort
            hide-default-footer
          >
            <template v-slot:[`item.created`]="{ item }">
              <span class="ml-n2">
                {{ createDate(item.created) }}
              </span>
            </template>
            <template v-slot:[`item.updated`]="{ item }">
              <span class="ml-n2">
                {{ createDate(item.updated) }}
              </span>
            </template>
            <template v-slot:[`item.size`]="{ item }">
              <span class="ml-n2"> {{ byteToMegabyte(item.size) }} Mb </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { supabase } from "../plugins/supabase/supabase";
import { createDate } from "@/plugins/vuetify/helpers.js";
import { s3SignedLink } from "../plugins/s3/s3";

export default {
  name: "attachmentTable",
  props: {
    ticketId: String,
  },
  async mounted() {
    await this.getAttachments();
  },
  data() {
    return {
      attachmentTable: {
        header: [
          {
            text: "Name",
            align: "center",
            sortable: true,
            value: "file_name",
          },
          {
            text: "Type",
            align: "center",
            sortable: true,
            value: "type",
          },
          {
            text: "Size",
            align: "center",
            sortable: true,
            value: "size",
          },
          {
            text: "Created",
            align: "center",
            sortable: true,
            value: "created",
          },
          {
            text: "Updated",
            align: "center",
            sortable: true,
            value: "updated",
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    handleAttachmentView: async function (row) {
      let path = "private/ticket/" + this.ticketId + "/attachments/" + row.id;
      let link = await s3SignedLink(path);
      window.open(link);
    },
    byteToMegabyte: function (byte) {
      return (byte / 1024 / 1024).toFixed(2);
    },
    createDate: function (date) {
      return createDate(date);
    },
    getAttachments: async function () {
      let response = await supabase.from("attachment").select().match({
        ticket_id: this.ticketId,
      });

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Failed to get attachments",
          message: "",
        };
        this.$globalState.addNotification(notify);
      } else {
        this.attachmentTable.items = response.data;
      }
    },
  },
};
</script>
<style scoped></style>
