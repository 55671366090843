<template>
  <div>
    <v-container fluid class="pt-16">
      <div class="center">
        <v-card class="login-card" color="background">
          <v-container>
            <v-row>
              <v-col class="d-flex justify-center">
                <span class="heading4 font-weight-bold">Login</span>
              </v-col>
            </v-row>
            <v-form v-model="validLogin">
              <v-row>
                <v-col>
                  <v-text-field
                    label="E-Mail"
                    color="primary"
                    type="email"
                    v-model="login.user.email"
                    :rules="[notEmpty, validEmail]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Password"
                    color="primary"
                    type="password"
                    v-model="login.user.password"
                    :rules="[notEmpty]"
                    @keyup.enter="handleLogin"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col class="pt-0">
                <span
                  class="text-caption primary--text font-weight-bold"
                  style="cursor: pointer"
                  @click="handlePasswordReset"
                  >Forgot Password?</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  color="primary"
                  :disabled="!validLogin"
                  @click="handleLogin"
                  :loading="login.loading"
                  >Login</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
    </v-container>
  </div>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
export default {
  name: "Login",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Login",
      meta: [
        {
          name: "description",
          content: "Login to " + this.$applicationName,
        },
      ],
    };
  },
  data() {
    return {
      validLogin: false,
      login: {
        loading: false,
        user: {
          email: "",
          password: "",
        },
      },
    };
  },
  mounted() {
    if (this.$route.query.message) {
      let notify = {
        duration: 4000,
        type: "info",
        headline: this.$route.query.message,
        message: "",
      };
      this.$globalState.addNotification(notify);
    }
  },
  methods: {
    //METHODS
    handleLogin: async function () {
      this.login.loading = true;
      const { user, error } = await supabase.auth.signIn({
        email: this.login.user.email,
        password: this.login.user.password,
      });
      if (user && user.id) {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Log-In",
          message: "Successfully Logged In!",
        };
        this.$globalState.addNotification(notify);
        if (this.$route.query.redirect) {
          this.$router.push({ name: this.$route.query.redirect });
        } else {
          this.$router.push({ name: "Dashboard" });
        }
      } else if (error.status === 400) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Log-In",
          message: error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Log-In",
          message: "Failed logging in!",
        };
        this.$globalState.addNotification(notify);
      }
      this.login.loading = false;
    },
    handlePasswordReset: async function () {
      if (
        this.login.user.email &&
        this.validEmail(this.login.user.email) === true
      ) {
        let response = await supabase.auth.api.resetPasswordForEmail(
          this.login.user.email
        );
        if (response.error) {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "Invalid E-Mail",
            message: response.error.message,
          };
          this.$globalState.addNotification(notify);
        } else {
          let notify = {
            duration: 4000,
            type: "success",
            headline: "Password Reset",
            message: "Reset Link sent to " + this.login.user.email,
          };
          this.$globalState.addNotification(notify);
        }
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Invalid E-Mail",
          message: "",
        };
        this.$globalState.addNotification(notify);
      }
    },

    //RULES
    notEmpty: function (input) {
      if (input && input.length > 0) {
        return true;
      }
      return "This field is required!";
    },
    validEmail: function (input) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(input).toLowerCase())) {
        return true;
      }
      return "Invalid E-Mail";
    },
  },
};
</script>
<style scoped>
.login-card {
  max-width: 400px;
  width: 90%;
  outline: 1px solid var(--v-text-base);
}
.center {
  height: 80vh;
  display: grid;
  place-items: center;
}
.heading4 {
  font-size: 34px;
  font-weight: bold;
}
</style>
