<template>
  <div class="prevent-full-width mx-auto mt-4">
    <div class="glow">
      <v-card color="deepBlack">
        <v-container>
          <v-row>
            <v-col class="d-flex justify-center">
              <span class="text-h4 font-weight-bold">My ToDos</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <todoTable :ticketView="false" />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </div>
</template>
<script>
import todoTable from "../components/todoTable.vue";
export default {
  name: "Todos",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "My ToDos",
      meta: [
        {
          name: "description",
          content: "Overview of the ToDos you are responsible for.",
        },
      ],
    };
  },
  components: {
    todoTable,
  },
};
</script>
<style scoped>
.glow {
  -webkit-box-shadow: 0px 0px 15px 10px var(--v-secondary-base);
  box-shadow: 0px 0px 15px 10px var(--v-secondary-base);
  border-radius: 5px;
  margin-bottom: 20px;
}
</style>
