<template>
  <div class="prevent-full-width mx-auto mt-4 d-flex justify-center">
    <div class="glow" style="max-width: 600px; width: 90%">
      <v-card color="deepBlack">
        <v-container>
          <v-row>
            <v-col class="d-flex justify-center">
              <span :style="headlineTextStyle">Profile</span>
            </v-col>
          </v-row>
          <v-form v-model="validProfile">
            <v-row>
              <v-col class="d-flex align-center font-weight-bold">
                First Name
              </v-col>
              <v-col v-if="!edit">
                {{ $globalState.profile.first_name }}
              </v-col>
              <v-col v-else>
                <v-text-field
                  label="First Name"
                  :placeholder="$globalState.profile.first_name"
                  color="primary"
                  v-model="editProfile.firstName"
                  :rules="[notEmpty]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center font-weight-bold">
                Last Name
              </v-col>
              <v-col v-if="!edit">
                {{ $globalState.profile.last_name }}
              </v-col>
              <v-col v-else>
                <v-text-field
                  label="Last Name"
                  :placeholder="$globalState.profile.last_name"
                  color="primary"
                  v-model="editProfile.lastName"
                  :rules="[notEmpty]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center font-weight-bold">
                E-Mail
              </v-col>
              <v-col v-if="!edit"> {{ $globalState.profile.email }} </v-col>
              <v-col v-else>
                <v-text-field
                  label="E-Mail"
                  :placeholder="$globalState.profile.email"
                  color="primary"
                  v-model="editProfile.email"
                  :rules="[notEmpty, validEmail]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center font-weight-bold">
                Password
              </v-col>
              <v-col>
                <v-dialog v-model="changePassword.dialog">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small color="primary" v-bind="attrs" v-on="on">
                      Change Password
                    </v-btn>
                  </template>
                  <v-card color="background" width="400">
                    <v-container>
                      <v-row>
                        <v-col class="d-flex justify-center">
                          <span class="heading6 font-weight-bold"
                            >Password Change</span
                          >
                        </v-col>
                      </v-row>
                      <v-form v-model="changePassword.valid">
                        <v-row>
                          <v-col class="d-flex justify-center">
                            <v-text-field
                              color="primary"
                              label="Password"
                              v-model="changePassword.user.password"
                              :rules="[notEmpty, minPasswordRequirements]"
                              type="password"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="d-flex justify-center">
                            <v-text-field
                              color="primary"
                              label="Password"
                              v-model="changePassword.user.password2"
                              :rules="[
                                notEmpty,
                                minPasswordRequirements,
                                samePassword,
                              ]"
                              type="password"
                              @keyup.enter="updatePassword"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-row>
                        <v-col class="d-flex justify-center">
                          <v-btn
                            @click="updatePassword"
                            color="primary"
                            :loading="changePassword.loading"
                            :disabled="!changePassword.valid"
                            >Change</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-form>
          <v-row v-if="edit">
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn v-if="!edit" icon @click="edit = true">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <div v-else>
                <v-btn class="mr-4" @click="edit = false" outlined color="error"
                  >Cancel</v-btn
                >
                <v-btn
                  @click="updateUserProfile"
                  :disabled="!newChanges || !validProfile"
                  color="success"
                  outlined
                  >Save</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </div>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
export default {
  name: "Profile",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Profile",
      meta: [
        {
          name: "description",
          content: "Your Profile on " + this.$applicationName,
        },
      ],
    };
  },
  data() {
    return {
      inviteDialog: false,
      validInvite: false,
      inviteLoading: false,
      invite: {
        firstName: "",
        email: "",
      },
      validProfile: false,
      edit: false,
      editProfile: {
        loading: false,
        firstName: "",
        lastName: "",
        email: "",
      },
      changePassword: {
        valid: false,
        dialog: false,
        loading: false,
        user: {
          password: "",
          password2: "",
        },
      },
    };
  },
  mounted() {
    if (this.$route.query.message) {
      let notify = {
        duration: 4000,
        type: "info",
        headline: this.$route.query.message,
        message: "",
      };
      this.$globalState.addNotification(notify);
    }
    this.resetEditForm();
  },
  methods: {
    //METHODS
    resetEditForm: function () {
      this.editProfile.firstName = this.$globalState.profile.first_name;
      this.editProfile.lastName = this.$globalState.profile.last_name;
      this.editProfile.email = this.$globalState.profile.email;
    },
    updateUserProfile: async function () {
      this.editProfile.loading = true;
      let response = await supabase
        .from("profile")
        .update({
          first_name: this.editProfile.firstName,
          last_name: this.editProfile.lastName,
          display_name:
            this.editProfile.firstName + " " + this.editProfile.lastName,
          updated: Date.now(),
        })
        .match({ id: supabase.auth.user().id });

      if (response.data.length > 0) {
        let profile = response.data[0];
        profile.email = supabase.auth.user().email;
        this.$globalState.profile = profile;

        if (this.editProfile.email != this.$globalState.profile.email) {
          response = await supabase.auth.update({
            email: this.editProfile.email,
          });

          if (response.user.new_email === this.editProfile.email) {
            let notify = {
              duration: 8000,
              type: "success",
              headline: "E-Mail-Address Change",
              message:
                "We sent you an E-Mail to confirm the change of your E-Mail-Address to '" +
                this.$globalState.profile.email +
                "' - Please check your Inbox!",
            };
            this.$globalState.addNotification(notify);
          } else {
            let notify = {
              duration: 4000,
              type: "error",
              headline: "E-Mail-Address Change",
              message:
                "Failed changing your E-Mail-Address. Please try again later!",
            };
            this.$globalState.addNotification(notify);
          }
        } else {
          let notify = {
            duration: 4000,
            type: "success",
            headline: "Updating Profile",
            message: "Successfully updated your Profile!",
          };
          this.$globalState.addNotification(notify);
        }
        this.resetEditForm();
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Updating Profile",
          message: "Failed updating your Profile. Please try again later!",
        };
        this.$globalState.addNotification(notify);
      }

      //   await this.getUserProfile();
      this.edit = false;
      this.editProfile.loading = false;
    },
    updatePassword: async function () {
      this.changePassword.loading = true;
      let response = await supabase.auth.update({
        password: this.changePassword.user.password,
      });
      if (response.data.role === "authenticated") {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Password Change",
          message: "Successfully changed Password",
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Password Change",
          message: "Failed changing Password! Please try again later!",
        };
        this.$globalState.addNotification(notify);
      }
      this.changePassword.dialog = false;
      this.changePassword.loading = false;
    },

    //RULES
    notEmpty: function (input) {
      if (input && input.length > 0) {
        return true;
      }
      return "This field is required!";
    },
    validEmail: function (input) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(input).toLowerCase())) {
        return true;
      }
      return "Invalid E-Mail";
    },
    minPasswordRequirements: function (input) {
      let reUpperCase = /^(?=.*[A-Z])/;
      let reLowerCase = /^(?=.*[a-z])/;
      let reNumber = /^(?=.*\d)/;
      let reSpecial = /^(?=.*[-+_!@#$%^&*.,?])/;
      let reLength = /^.{8,}$/;
      let upperCase = reUpperCase.test(input);
      let lowerCase = reLowerCase.test(input);
      let number = reNumber.test(input);
      let special = reSpecial.test(input);
      let length = reLength.test(input);

      if (upperCase && lowerCase && number && special && length) {
        return true;
      } else {
        let errorString = "Minimum Requirements: ";

        if (!upperCase) {
          errorString = errorString + "1 Uppercase, ";
        }
        if (!lowerCase) {
          errorString = errorString + "1 Lowercase, ";
        }
        if (!number) {
          errorString = errorString + "1 Number, ";
        }
        if (!special) {
          errorString = errorString + "1 Special Character (-+_!@#$%^&*.,?), ";
        }
        if (!length) {
          errorString = errorString + "8 Characters in Total";
        }
        if (errorString.slice(-2) === ", ") {
          errorString = errorString.slice(0, -2);
        }
        return errorString;
      }
    },
    samePassword: function (input) {
      if (input === this.changePassword.user.password) {
        return true;
      }
      return "Passwords don't match!";
    },
  },
  computed: {
    newChanges: function () {
      if (
        this.$globalState.profile.first_name != this.editProfile.firstName ||
        this.$globalState.profile.last_name != this.editProfile.lastName ||
        this.$globalState.profile.email != this.editProfile.email
      ) {
        if (
          this.editProfile.firstName &&
          this.editProfile.lastName &&
          this.editProfile.email
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    headlineTextStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "letter-spacing: 0.3em; font-size: 1.5em; font-weight: bold;";
        case "sm":
          return "letter-spacing: 0.3em; font-size: 1.7em; font-weight: bold;";
        case "md":
          return "letter-spacing: 0.3em; font-size: 2.3em; font-weight: bold;";
        case "lg":
          return "letter-spacing: 0.3em; font-size: 2.5em; font-weight: bold;";
        case "xl":
          return "letter-spacing: 0.3em; font-size: 2.5em; font-weight: bold;";
      }
      return "letter-spacing: 0.3em; font-size: 2.5em; font-weight: bold;";
    },
  },
};
</script>
<style scoped>
.glow {
  -webkit-box-shadow: 0px 0px 15px 10px var(--v-secondary-base);
  box-shadow: 0px 0px 15px 10px var(--v-secondary-base);
  border-radius: 5px;
  margin-bottom: 20px;
}
.center-full-size {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.heading6 {
  font-size: 20px;
  font-weight: bold;
}
</style>
