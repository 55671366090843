import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Meta from "vue-meta";
import Clipboard from "v-clipboard";
import VueScrollmagic from "vue-scrollmagic";
import linkify from "vue-linkify";
import MasonryWall from "@yeger/vue2-masonry-wall";
import Carousel3d from "vue-carousel-3d";
import LazyTube from "vue-lazytube";

Vue.use(LazyTube);

Vue.use(Carousel3d);

Vue.use(MasonryWall);

Vue.directive("linkified", linkify);

Vue.use(Clipboard);

//Scrollmagic
Vue.use(VueScrollmagic, {
  vertical: true,
  loglevel: 2,
  refreshInterval: 100,
});

//Meta
Vue.use(Meta);

Vue.prototype.$applicationName = "M8Motion";
Vue.prototype.$cdnUrl = "https://m8motion-cdn.fra1.cdn.digitaloceanspaces.com/";
Vue.prototype.$appUrl = "https://m8motion.com";
Vue.prototype.$apiUrl = "https://api.m8motion.com";
Vue.prototype.$ticketSystemUser = {
  id: "f551b7d8-5b65-4547-bb5f-cb850d2ad702",
};

Vue.config.productionTip = false;

Vue.prototype.$dev = Vue.config.devtools;

if (Vue.prototype.$dev) {
  Vue.prototype.$appUrl = "http://localhost:8080";
  Vue.prototype.$apiUrl = "http://localhost:8787";
}

const store = Vue.observable({
  genres: [],
  genreProjects: [],
  imageVariants: [
    {
      type: "image/webp",
      breakpoint: "xs",
      width: 600,
    },
    {
      type: "image/webp",
      breakpoint: "sm",
      width: 900,
    },
    {
      type: "image/webp",
      breakpoint: "md",
      width: 1260,
    },
    {
      type: "image/webp",
      breakpoint: "lg",
      width: 1900,
    },
    {
      type: "image/webp",
      breakpoint: "xl",
      width: 2200,
    },
    {
      type: null,
      breakpoint: "og",
      width: -1,
    },
  ],
  drawer: true,
  drawerWidth: 180,
  notifications: [],
  addNotification(notification) {
    store.notifications.push(notification);
  },
  getAndRemoveNotification() {
    return store.notifications.shift();
  },
  isLoggedIn: false,
  profile: null,
  adminDrawerItems: [
    {
      name: "Dashboard",
      icon: "mdi-monitor-dashboard",
      link: "/dashboard",
    },
    {
      name: "CDN-Manager",
      icon: "mdi-cloud-circle",
      link: "/cdnmanager",
    },
    {
      name: "Project-Manager",
      icon: "mdi-folder-image",
      link: "/projectmanager",
    },
    {
      name: "Genre-Manager",
      icon: "mdi-palette-swatch",
      link: "/genremanager",
    },
    {
      name: "Tickets",
      icon: "mdi-ticket",
      link: "/ticketoverview",
    },
    {
      name: "My Todos",
      icon: "mdi-playlist-check",
      link: "/todos",
    },
    {
      name: "Gear Manager",
      icon: "mdi-camera",
      link: "/gearmanager",
    },
    {
      name: "Profile",
      icon: "mdi-badge-account-outline",
      link: "/profile",
    },
  ],
});

Vue.prototype.$globalState = store;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
