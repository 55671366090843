<template>
  <v-card color="grey" v-if="entry && entry != 'not found'" elevation="0">
    <v-container>
      <v-row no-gutters class="pb-2">
        <v-col
          v-if="entry.mail"
          class="text-caption text--text d-flex align-center"
        >
          <span v-if="entry.userId != $globalState.profile.id">
            {{ entry.firstName }} {{ entry.lastName }} contacted the Client on
            {{
              createDate(
                entry.created >= entry.updated ? entry.created : entry.updated
              )
            }}</span
          >
          <span v-else
            >You contacted the Client on
            {{
              createDate(
                entry.created >= entry.updated ? entry.created : entry.updated
              )
            }}</span
          >
        </v-col>
        <v-col v-else class="text-caption text--text d-flex align-center">
          <span v-if="entry.userId != $globalState.profile.id">
            {{ entry.firstName }} {{ entry.lastName }} on
            {{
              createDate(
                entry.created >= entry.updated ? entry.created : entry.updated
              )
            }}</span
          >
          <span v-else
            >You on
            {{
              createDate(
                entry.created >= entry.updated ? entry.created : entry.updated
              )
            }}</span
          >
        </v-col>
        <v-col cols="2" class="d-flex justify-end">
          <v-btn icon @click="scrollToEntry">
            <v-icon small color="primary">mdi-debug-step-over</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col v-if="entry.mark === 'quote'">
          <entryCard :entry="entry" :entries="entries" :recursive="true" />
        </v-col>
        <v-col v-else v-linkified>
          {{ entry.message }}
        </v-col>
      </v-row>
    </v-container>
  </v-card>
  <v-card v-else-if="entry && entry === 'not found'" color="grey" elevation="0">
    <v-container>
      <v-row>
        <v-col>
          Couldn't find the given Quote with ID {{ entryId }} - The quoted entry
          might have been deleted.
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { createDate } from "@/plugins/vuetify/helpers.js";
export default {
  name: "quotePanel",
  props: {
    tag: String,
    entries: Array,
  },
  components: {
    entryCard: () => import("./entryCard.vue"),
  },
  data() {
    return {
      entryId: "",
      entry: null,
    };
  },
  mounted() {
    this.parseTag(this.tag);
  },
  methods: {
    scrollToEntry: function () {
      this.$router.push({
        path: this.$route.path,
        hash: "#e-" + this.entry.id,
      });
    },
    createDate: function (epoch) {
      return createDate(epoch);
    },

    parseTag: function (tag) {
      let entryId = tag
        .substring(tag.lastIndexOf("[Q-") + 1, tag.indexOf("-Q/]"))
        .replace("Q-", "")
        .replace("-Q/", "");

      this.entryId = entryId;
      this.getEntry();
    },
    getEntry: function () {
      let entryId = this.entryId;
      //find entry in entries by entryId
      let entry = this.entries.find((entry) => entry.id == entryId);
      if (!entry) {
        entry = "not found";
      }
      this.entry = entry;
    },
  },
};
</script>
<style scoped></style>
