<template>
  <div>
    <v-row>
      <v-col>
        <span class="text-h6 font-weight-bold pl-4">Gear</span>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-dialog v-model="newGearDialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" v-on="on" v-bind="attrs">
              <v-icon>mdi-plus</v-icon><span class="pl-2">Add</span>
            </v-btn>
          </template>
          <v-card style="min-width: 25vw" color="deepBlack">
            <v-container>
              <v-row>
                <v-col>
                  <span>Add new Gear</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-form ref="newGearForm" v-model="validGear">
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Brand"
                      color="secondary"
                      :rules="[notEmpty]"
                      v-model="newGear.brand"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Model"
                      color="secondary"
                      :rules="[notEmpty]"
                      v-model="newGear.model"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Shop Link"
                      color="secondary"
                      :rules="[notEmpty, isLink]"
                      v-model="newGear.shopLink"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      label="Category"
                      color="secondary"
                      :items="categories"
                      :rules="[notEmpty]"
                      v-model="newGear.categoryId"
                      item-text="title"
                      item-value="id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      label="Image"
                      color="secondary"
                      :items="images"
                      :rules="[notEmpty]"
                      v-model="newGear.imageKey"
                      item-text="name"
                      item-value="key"
                    >
                      <template v-slot:item="{ item }">
                        <v-img
                          :src="item.link.replace('BREAKPOINT', 'xs')"
                          max-width="50"
                          aspect-ratio="1"
                        ></v-img>
                        <span
                          class="ml-4"
                          style="max-width: 290px; max-height: 50px"
                          >{{ item.name }}</span
                        >
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
              <v-row>
                <v-col>
                  <v-checkbox
                    label="Highlight"
                    color="secondary"
                    v-model="newGear.highlight"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-end">
                  <v-btn color="error" @click="cancelGearDialog" class="mr-2">
                    Cancel</v-btn
                  >
                  <v-btn color="success" :disabled="!validGear" @click="addGear"
                    >Save</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="deepBlack"
          :items="gearTable.items"
          :headers="gearTable.headers"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:[`item.gearcategory_id`]="{ item }">
            {{ getCategoryName(item.gearcategory_id) }}
          </template>
          <template v-slot:[`item.highlight`]="{ item }">
            <v-icon v-if="item.highlight">mdi-check</v-icon>
            <span v-else>-</span>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            {{ createDate(item.created) }}
          </template>
          <template v-slot:[`item.updated`]="{ item }">
            {{ createDate(item.updated) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon :href="item.shop_link" target="_blank">
              <v-icon small>mdi-cart</v-icon>
            </v-btn>
            <v-btn
              icon
              :href="
                $cdnUrl +
                item.img_key.replace('BREAKPOINT', $vuetify.breakpoint.name)
              "
              target="_blank"
            >
              <v-icon small>mdi-image-filter-center-focus</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="
                (showEditDialog = true) &&
                  (selectedGear = item) &&
                  fillEditGear(item)
              "
            >
              <v-icon color="info" small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="(showDeleteDialog = true) && (selectedGear = item)"
            >
              <v-icon color="error" small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="showDeleteDialog">
      <v-card outlined class="overlay" color="deepBlack">
        <v-container>
          <v-row>
            <v-col>
              <span class="text-h6 font-weight-bold secondary--text"
                >Delete Gear</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span v-if="selectedGear">
                Are you sure you want to delete this Gear?<br />
                '<span class="font-weight-bold"
                  >{{ selectedGear.brand + " " + selectedGear.model }}
                </span>
                ' <br />This action can not be reverted!
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn
                @click="showDeleteDialog = !showDeleteDialog"
                class="mr-3 cancel text--text"
                text
                outlined
                >Cancel</v-btn
              >
              <v-btn
                @click="deleteGear(selectedGear)"
                color="error"
                text
                outlined
                >Yes</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEditDialog">
      <v-card outlined class="overlay" color="deepBlack">
        <v-container style="min-width: 25vw">
          <v-row>
            <v-col>
              <span class="text-h6 font-weight-bold secondary--text"
                >Edit Gear</span
              >
            </v-col>
          </v-row>
          <div v-if="editGear">
            <v-form ref="newGearForm" v-model="validEditGear">
              <v-row>
                <v-col>
                  <v-text-field
                    label="Brand"
                    color="secondary"
                    :rules="[notEmpty]"
                    v-model="editGear.brand"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Model"
                    color="secondary"
                    :rules="[notEmpty]"
                    v-model="editGear.model"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Shop Link"
                    color="secondary"
                    :rules="[notEmpty, isLink]"
                    v-model="editGear.shopLink"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete
                    label="Category"
                    color="secondary"
                    :items="categories"
                    :rules="[notEmpty]"
                    v-model="editGear.categoryId"
                    item-text="title"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete
                    label="Image"
                    color="secondary"
                    :items="images"
                    :rules="[notEmpty]"
                    v-model="editGear.imageKey"
                    item-text="name"
                    item-value="key"
                  >
                    <template v-slot:item="{ item }">
                      <v-img
                        :src="item.link.replace('BREAKPOINT', 'xs')"
                        max-width="50"
                        aspect-ratio="1"
                      ></v-img>
                      <span
                        class="ml-4"
                        style="max-width: 290px; max-height: 50px"
                        >{{ item.name }}</span
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col>
                <v-checkbox
                  label="Highlight"
                  color="secondary"
                  v-model="editGear.highlight"
                ></v-checkbox>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn
                @click="showEditDialog = !showEditDialog"
                class="mr-3 cancel text--text"
                text
                outlined
                >Cancel</v-btn
              >
              <v-btn
                @click="updateGear(selectedGear)"
                color="success"
                text
                outlined
                :disabled="!validEditGear"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
import { createDate } from "../plugins/vuetify/helpers";
export default {
  name: "gearTable",
  data() {
    return {
      images: [],
      categories: [],
      editGear: {
        brand: "",
        model: "",
        shopLink: "",
        imageKey: "",
        categoryId: "",
        highlight: false,
      },
      showEditDialog: false,
      validEditGear: false,
      selectedGear: null,
      showDeleteDialog: false,
      newGearDialog: false,
      newGear: {
        brand: "",
        model: "",
        shopLink: "",
        imageKey: "",
        categoryId: "",
        highlight: false,
      },
      validGear: false,
      gearTable: {
        items: [],
        headers: [
          {
            text: "Brand",
            align: "center",
            sortable: true,
            value: "brand",
          },
          {
            text: "Model",
            align: "center",
            sortable: true,
            value: "model",
          },
          {
            text: "Category",
            align: "center",
            sortable: true,
            value: "gearcategory_id",
          },
          {
            text: "Highlight",
            align: "center",
            sortable: true,
            value: "highlight",
          },
          {
            text: "Created",
            align: "center",
            sortable: true,
            value: "created",
          },
          {
            text: "Updated",
            align: "center",
            sortable: true,
            value: "updated",
          },
          {
            text: "Actions",
            align: "center",
            sortable: false,
            value: "actions",
          },
        ],
      },
    };
  },
  mounted() {
    this.getGear();
    this.getCategories();
    this.getImages();
  },
  methods: {
    getCategoryName(id) {
      if (this.categories.length > 0) {
        let cat = this.categories.find((category) => category.id == id);
        if (cat) {
          return cat.title;
        }
      }
    },
    getImages: async function () {
      //get genre id for genre with name gear
      let gearGenre = await supabase
        .from("genre")
        .select("id")
        .match({ name: "Gear" });
      if (gearGenre.error) {
        let notify = {
          type: "error",
          headline: "Error getting gear genre id",
          message: "",
          duration: 4000,
        };
        this.$globalState.addNotification(notify);
      } else {
        let gearId = gearGenre.data[0].id;
        let images = await supabase.from("image").select().match({
          genre_id: gearId,
        });
        if (images.error) {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "",
            message: "Failed to load images from Supabase",
          };
          this.$globalState.addNotification(notify);
        } else if (images.data) {
          let imageArray = images.data;
          this.images = [];
          for (let i = 0; i < imageArray.length; i++) {
            let image = imageArray[i];
            image.link = this.$cdnUrl + image.key;
            this.images.push(image);
          }
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "",
            message: "No images found in Supabase",
          };
          this.$globalState.addNotification(notify);
        }
      }
    },
    getCategories: async function () {
      let response = await supabase.from("gearcategory").select();
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Failed loading gear categories",
          message: "Please try again later.",
        };
        this.$globalState.addNotification(notify);
      } else {
        this.categories = response.data;
      }
    },
    updateGear: async function (gear) {
      let response = await supabase
        .from("gear")
        .update({
          brand: this.editGear.brand,
          model: this.editGear.model,
          shop_link: this.editGear.shopLink,
          img_key: this.editGear.imageKey,
          gearcategory_id: this.editGear.categoryId,
          highlight: this.editGear.highlight,
          updated: Date.now(),
        })
        .match({ id: gear.id });

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Updating Gear",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Updating Gear",
          message: "Successfully updated Gear",
        };
        this.$globalState.addNotification(notify);
        this.getGear();
      }
      this.showEditDialog = false;
    },
    fillEditGear: function (gear) {
      this.editGear.model = gear.model;
      this.editGear.brand = gear.brand;
      this.editGear.shopLink = gear.shop_link;
      this.editGear.imageKey = gear.img_key;
      this.editGear.categoryId = gear.gearcategory_id;
      this.editGear.highlight = gear.highlight;
    },
    deleteGear: async function (gear) {
      let response = await supabase
        .from("gear")
        .delete()
        .match({ id: gear.id });
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Gear",
          message: "Failed deleting Gear",
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "ToDo",
          message: "Todo deleted",
        };
        this.$globalState.addNotification(notify);
        this.showDeleteDialog = false;
        this.getGear();
      }
    },

    createDate: function (epoch) {
      return createDate(epoch);
    },
    cancelGearDialog: function () {
      this.newGearDialog = false;
      this.$refs.newGearForm.reset();
    },
    addGear: async function () {
      let gear = {
        brand: this.newGear.brand,
        model: this.newGear.model,
        shop_link: this.newGear.shopLink,
        img_key: this.newGear.imageKey,
        gearcategory_id: this.newGear.categoryId,
        highlight: this.newGear.highlight,
        created: Date.now(),
        updated: Date.now(),
      };
      let response = await supabase.from("gear").insert(gear);

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Failed to add Gear",
          message: "Please try again later.",
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Gear added",
          message: "",
        };
        this.$globalState.addNotification(notify);
        this.getGear();
        this.$refs.newGearForm.reset();
        this.newGearDialog = false;
      }
    },
    getGear: async function () {
      let response = await supabase.from("gear").select();
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Failed loading gear",
          message: "Please try again later.",
        };
        this.$globalState.addNotification(notify);
      } else {
        this.gearTable.items = response.data;
      }
    },

    //RULES
    notEmpty: function (value) {
      return !!value || "This field is Required";
    },
    isLink: function (value) {
      //check if value is a link
      if (value && value.includes("http")) {
        return true;
      } else {
        return "Please enter a valid link";
      }
    },
  },
};
</script>
<style scoped></style>
