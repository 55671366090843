<template>
  <div>
    <v-container fluid class="pt-16">
      <div class="center">
        <v-card>
          <v-container class="pa-5">
            <v-row>
              <v-col class="d-flex justify-center">
                <span class="heading4 font-weight-bold"
                  >Complete your Profile</span
                >
              </v-col>
            </v-row>
            <v-form v-model="validProfile">
              <v-row>
                <v-col>
                  <v-text-field
                    label="First Name"
                    color="primary"
                    v-model="profile.firstName"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Last Name"
                    color="primary"
                    v-model="profile.lastName"
                    @keyup.enter="saveProfile"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  color="primary"
                  :disabled="!validProfile"
                  :loading="profile.loading"
                  @click="saveProfile"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
    </v-container>
  </div>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
export default {
  name: "FirstLogin",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "First Login",
      meta: [
        {
          name: "description",
          content:
            "Complete your Profile on your First Login on " +
            this.$applicationName,
        },
      ],
    };
  },
  data() {
    return {
      validProfile: false,
      profile: {
        loading: false,
        firstName: "",
        lastName: "",
      },
    };
  },
  mounted() {
    if (this.$route.query.message) {
      let notify = {
        duration: 4000,
        type: "info",
        headline: this.$route.query.message,
        message: "",
      };
      this.$globalState.addNotification(notify);
    }
  },
  methods: {
    //METHODS
    saveProfile: async function () {
      const user = supabase.auth.user();

      const { data } = await supabase
        .from("profile")
        .update({
          first_name: this.profile.firstName,
          last_name: this.profile.lastName,
          display_name: this.profile.firstName + " " + this.profile.lastName,
          provider: user.app_metadata.provider,
          updated: Date.now(),
          created: Date.now(),
        })
        .match({ id: user.id });

      if (data.length > 0) {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Sign-Up",
          message: "Successfully saved your Profile! You're all set now!",
        };
        this.$globalState.addNotification(notify);
        this.$router.push({ name: "Home" });
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Sign-Up",
          message: "Failed saving your Profile! Try again later!",
        };
        this.$globalState.addNotification(notify);
      }
    },
    //RULES
    notEmpty: function (input) {
      if (input && input.length > 0) {
        return true;
      }
      return "This field is required!";
    },
  },
};
</script>
<style scoped>
.center {
  height: 80vh;
  display: grid;
  place-items: center;
}
.heading4 {
  font-size: 34px;
  font-weight: bold;
}
</style>
