import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/lib/services/goto";

import { supabase } from "@/plugins/supabase/supabase.js";
import { getParameterByName, handleInvite } from "@/plugins/supabase/helpers";
import {
  // getGenreProjects,
  getProfile,
} from "../plugins/router/helper";

import { getGenres } from "../plugins/vuetify/helpers";

import Home from "../views/Home.vue";
import Portfolio from "../views/Portfolio.vue";
import PortfolioItem from "../views/PortfolioItem.vue";
import Login from "../views/Login.vue";
import ResetPassword from "../views/ResetPassword.vue";
import FirstLogin from "../views/FirstLogin.vue";
import Profile from "../views/Profile.vue";
import CdnManager from "../views/CdnManager.vue";
import ProjectManager from "../views/ProjectManager.vue";
import GenreManager from "../views/GenreManager.vue";
import Ticket from "../views/Ticket.vue";
import TicketOverview from "../views/TicketOverview.vue";
import Todos from "../views/Todos.vue";
import Contact from "../views/Contact.vue";
import Team from "../views/Team.vue";
import Socials from "../views/Socials.vue";
import Services from "../views/Services.vue";
import Gear from "../views/Gear.vue";
import Imprint from "../views/Imprint.vue";
import Privacy from "../views/Privacy.vue";
import Videography from "../views/Videography.vue";
import Photography from "../views/Photography.vue";
import GearManager from "../views/GearManager.vue";
import AdminDashboard from "../views/AdminDashboard.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      drawer: false,
      title: "Home",
      portfolioItem: false,
      authRequired: false,
    },
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: Portfolio,
    meta: {
      drawer: true,
      title: "Portfolio",
      portfolioItem: false,
      authRequired: false,
    },
  },
  {
    path: "/portfolio/:item",
    name: "PortfolioItem",
    component: PortfolioItem,
    meta: {
      drawer: true,
      title: "Portfolio Item",
      portfolioItem: false,
      authRequired: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      drawer: true,
      title: "Login",
      portfolioItem: false,
      authRequired: false,
    },
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      drawer: true,
      title: "Reset Password",
      portfolioItem: false,
      authRequired: false,
    },
  },
  {
    path: "/firstLogin",
    name: "FirstLogin",
    component: FirstLogin,
    meta: {
      drawer: true,
      title: "First Login",
      portfolioItem: false,
      authRequired: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      drawer: true,
      title: "Profile",
      portfolioItem: false,
      authRequired: true,
    },
  },
  {
    path: "/cdnmanager",
    name: "CdnManager",
    component: CdnManager,
    meta: {
      drawer: true,
      title: "CDN-Manager",
      portfolioItem: false,
      authRequired: true,
    },
  },
  {
    path: "/projectmanager",
    name: "ProjectManager",
    component: ProjectManager,
    meta: {
      drawer: true,
      title: "Project-Manager",
      portfolioItem: false,
      authRequired: true,
    },
  },
  {
    path: "/genremanager",
    name: "GenreManager",
    component: GenreManager,
    meta: {
      drawer: true,
      title: "Genre-Manager",
      portfolioItem: false,
      authRequired: true,
    },
  },
  {
    path: "/ticketOverview",
    name: "TicketOverview",
    component: TicketOverview,
    meta: {
      drawer: true,
      title: "Ticket-Overview",
      portfolioItem: false,
      authRequired: true,
    },
  },
  {
    path: "/ticket/:id",
    name: "Ticket",
    component: Ticket,
    meta: {
      drawer: true,
      title: "Ticket",
      portfolioItem: false,
      authRequired: true,
    },
  },
  {
    path: "/todos",
    name: "Todos",
    component: Todos,
    meta: {
      drawer: true,
      title: "Todos",
      portfolioItem: false,
      authRequired: true,
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      drawer: true,
      title: "Contact",
      portfolioItem: false,
      authRequired: false,
    },
  },
  {
    path: "/videography",
    name: "Videography",
    component: Videography,
    meta: {
      drawer: true,
      title: "Videography",
      portfolioItem: true,
      authRequired: false,
    },
  },
  {
    path: "/photography",
    name: "Photography",
    component: Photography,
    meta: {
      drawer: true,
      title: "Photography",
      portfolioItem: true,
      authRequired: false,
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    meta: {
      drawer: true,
      title: "Privacy",
      portfolioItem: false,
      authRequired: false,
    },
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: Imprint,
    meta: {
      drawer: true,
      title: "Imprint",
      portfolioItem: false,
      authRequired: false,
    },
  },
  {
    path: "/gear",
    name: "Gear",
    component: Gear,
    meta: {
      drawer: true,
      title: "Gear",
      portfolioItem: false,
      authRequired: false,
    },
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
    meta: {
      drawer: true,
      title: "Team",
      portfolioItem: false,
      authRequired: false,
    },
  },
  {
    path: "/socials",
    name: "Socials",
    component: Socials,
    meta: {
      drawer: true,
      title: "Socials",
      portfolioItem: false,
      authRequired: false,
    },
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
    meta: {
      drawer: true,
      title: "Services",
      portfolioItem: false,
      authRequired: false,
    },
  },
  {
    path: "/gearmanager",
    name: "GearManager",
    component: GearManager,
    meta: {
      drawer: true,
      title: "GearManager",
      portfolioItem: false,
      authRequired: false,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: AdminDashboard,
    meta: {
      drawer: true,
      title: "Dashboard",
      portfolioItem: false,
      authRequired: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let delay = 1500;
    if (to.name === "Home") {
      delay = 0;
    }
    setTimeout(() => {
      let scrollTo = null;
      let options = {
        // offset: -120,
      };

      if (to.name === "Ticket") {
        options.offset = 90;
      }
      if (to.name === "Portfolio") {
        options.offset = 90;
      }

      if (to.hash) {
        scrollTo = to.hash;
      } else if (savedPosition) {
        scrollTo = savedPosition.y;
      }
      if (scrollTo) {
        return goTo(scrollTo, options);
      }
    }, delay);
  },
});

router.beforeEach(async (to, from, next) => {
  let toUrl = location.origin + to.fullPath;
  const requestType = getParameterByName("type", toUrl);

  if (supabase.auth.session()) {
    Vue.prototype.$globalState.isLoggedIn = true;
  } else {
    Vue.prototype.$globalState.isLoggedIn = false;
  }

  if (Vue.prototype.$globalState.isLoggedIn) {
    await getProfile();
  }

  // Get genres if not already stored in global State
  if (Vue.prototype.$globalState.genres.length === 0) {
    Vue.prototype.$globalState.genres = await getGenres();
  }

  if (requestType === "email_change") {
    const accessToken = getParameterByName("access_token", toUrl);
    supabase.auth.setAuth(accessToken);
    if (supabase.auth.session()) {
      next({
        name: "Home",
        query: { message: "E-Mail changed successfully!" },
      });
    } else {
      next({ name: "Home", query: { message: "Failed changing E-Mail" } });
    }
  } else if (requestType === "invite") {
    const accessToken = getParameterByName("access_token", toUrl);
    await supabase.auth.setAuth(accessToken);
    if (supabase.auth.session()) {
      let inviteSuccess = await handleInvite();
      if (inviteSuccess) {
        next({
          name: "Home",
          query: { message: "Successfully activated Invite! Check your Inbox" },
        });
      } else {
        next({
          name: "Home",
          query: { message: "Failed activating Invite!" },
        });
      }
    } else {
      next({ name: "Home", query: { message: "Invite Link broken!" } });
    }
  } else if (requestType === "signup") {
    const accessToken = getParameterByName("access_token", toUrl);
    supabase.auth.setAuth(accessToken);
    if (supabase.auth.session()) {
      let profilePayload = await getProfile();
      if (profilePayload.firstLogin) {
        next({
          name: "FirstLogin",
          query: {
            message: "Finish setting up your profile!",
          },
        });
      } else {
        next({
          name: "Home",
          query: { message: "Successfully confirmed Sign-Up!" },
        });
      }
    } else {
      next({ name: "Login", query: { message: "Failed confirming Sign-Up" } });
    }
  } else if (requestType === "recovery") {
    const accessToken = getParameterByName("access_token", toUrl);
    next({ name: "ResetPassword", query: { token: accessToken } });
  } else {
    // if (to.name === "PortfolioItem") {
    //   console.log(to);
    //   Vue.prototype.$globalState.genreProjects = await getGenreProjects(
    //     to.params.item
    //   );
    //   console.log(Vue.prototype.$globalState.genreProjects);
    // }
    if (to.path === "/more") {
      window.location.href = "https://j4peg.m8motion.com/";
    } else if (to.meta.authRequired) {
      if (supabase.auth.session()) {
        if (to.name === "FirstLogin" && !to.query) {
          next({
            name: "Home",
            query: { message: "You already finished your Sign-Up Process!" },
          });
        } else {
          let profile = await getProfile();
          if (to.name === "FirstLogin" && to.query.message) {
            next();
          } else if (profile.firstLogin) {
            next({
              name: "FirstLogin",
              query: {
                message: "Finish setting up your profile!",
              },
            });
          } else {
            if (to.name === "FirstLogin") {
              next({
                name: "Home",
                query: {
                  message: "You already finished your Sign-Up Process!",
                },
              });
            } else {
              next();
            }
          }
        }
        next();
      } else {
        next({ name: "Login", query: { redirect: to.name } });
      }
    } else {
      if (to.name === "Login") {
        if (supabase.auth.session()) {
          next({ name: "Dashboard" });
        } else {
          next();
        }
      } else if (to.name === "ResetPassword") {
        if (to.query && to.query.token) {
          next();
        } else if (supabase.auth.session()) {
          next({
            name: "Home",
            query: {
              message:
                "You cannot access this site without a Password-Reset-Token!",
            },
          });
        } else {
          next({
            name: "Login",
            query: {
              message:
                "You cannot access this site without a Password-Reset-Token!",
            },
          });
        }
      } else {
        next();
      }
    }
  }
});

export default router;
