<template>
  <div>
    <div v-if="!project.video_url">
      <v-row>
        <v-col>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-on="on"
                v-bind="attrs"
                @click="copyProjectUrl"
                :class="
                  'font-weight-bold ' +
                  ($vuetify.breakpoint.mdAndUp ? 'heading4' : 'heading5')
                "
              >
                {{ project.title }}
              </span>
            </template>
            <span>Click to copy the link to this project</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <!-- PHOTOGRAPHY -->
        <v-col>
          <carousel-3d
            v-if="$vuetify.breakpoint.smAndUp"
            ref="carousel"
            :on-main-slide-click="onMainSlideClick"
            :key="images.length"
            :border="carouselSettings.border"
            :autoplay="carouselSettings.autoplay"
            :autoplay-timeout="carouselSettings.autoplayTimeout"
            :height="carouselSettings.height"
            :width="carouselSettings.width"
          >
            <slide
              v-for="(image, i) in images"
              :index="i"
              :key="i"
              class="slide"
            >
              <img
                class="imageBorder"
                :style="
                  'max-height: ' +
                  carouselSettings.height +
                  'px; max-width: ' +
                  carouselSettings.width +
                  'px;'
                "
                :src="
                  $cdnUrl +
                  image.key.replace('BREAKPOINT', $vuetify.breakpoint.name)
                "
                loading="lazy"
              />
            </slide>
          </carousel-3d>
          <masonry-wall
            v-else-if="wallImages.length > 0"
            :items="wallImages"
            :columnWidth="$vuetify.breakpoint.width / colsWallImages - 40"
            :gap="6"
          >
            <template v-slot:default="{ item }">
              <div
                :style="'aspect-ratio: ' + item.meta.ratio"
                class="Item"
                @click="handleMobileImageClick(item)"
              >
                <img
                  class="detailimg"
                  :src="
                    $cdnUrl +
                    item.key.replace('BREAKPOINT', $vuetify.breakpoint.name)
                  "
                  style="cursor: pointer"
                  loading="lazy"
                />
              </div>
            </template>
          </masonry-wall>
          <!-- <v-carousel
            v-else
            cycle
            height="auto"
            :show-arrows="false"
            hide-delimiters
            continuous
            style="height: 350px"
          >
            <v-carousel-item v-for="(image, index) in images" :key="index">
              <v-img
                @click="onMainSlideClick(image)"
                height="350px"
                :src="
                  $cdnUrl +
                  image.key.replace('BREAKPOINT', $vuetify.breakpoint.name)
                "
                contain
                :lazy-src="$cdnUrl + image.key.replace('BREAKPOINT', 'xs')"
              ></v-img>
            </v-carousel-item>
          </v-carousel> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mx-2" style="text-align: justify">
          {{ project.description }}
        </v-col>
      </v-row>
      <v-row v-if="project.linkproject_id">
        <v-col
          :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''"
        >
          <v-btn
            color="primary"
            outlined
            :href="
              this.$appUrl +
              '/portfolio/videography#p-' +
              project.linkproject_id
            "
          >
            {{
              $vuetify.breakpoint.mdAndUp
                ? "Watch the video to this shoot"
                : "Project-Video"
            }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row v-else-if="project.video_url && $vuetify.breakpoint.mdAndUp">
      <!-- VIDEOGRAPHY -->
      <div class="video-row" v-if="index % 2 == 1">
        <div class="video-frame">
          <img
            :src="customThumbnail"
            class="video-thumbnail right-gradient"
            @click="showVideo"
          />
          <div class="top-left-text">
            <v-tooltip right color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  fab
                  color="primary"
                  @click="showVideo"
                >
                  <v-icon>mdi-play</v-icon>
                </v-btn>
              </template>
              <span>Click to play the video </span>
            </v-tooltip>
          </div>
          <!-- <LazyYoutube
            class="video-embed"
            :src="project.video_url"
            :max-width="$vuetify.breakpoint.width.toString()"
            :showTitle="false"
            thumbnailQuality="maxres"
            :customThumbnail="customThumbnail"
          /> -->
        </div>
        <div class="video-description">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-on="on"
                  v-bind="attrs"
                  @click="copyProjectUrl"
                  class="font-weight-bold"
                  :class="$vuetify.breakpoint.lgAndUp ? 'heading4' : 'heading5'"
                >
                  {{ project.title }}
                </span>
              </template>
              <span>Click to copy the link to this project</span>
            </v-tooltip>
          </div>
          <div style="hyphens: auto">
            {{ project.description }}
          </div>
          <div v-if="project.linkproject_id" class="my-4">
            <v-btn
              color="primary"
              outlined
              :href="
                this.$appUrl +
                '/portfolio/photography#p-' +
                project.linkproject_id
              "
            >
              {{
                $vuetify.breakpoint.lgAndUp
                  ? "See the photos to this shoot"
                  : "Project-Photos"
              }}
            </v-btn>
          </div>
        </div>
      </div>
      <div class="video-row" v-else>
        <div class="video-description">
          <div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-on="on"
                  v-bind="attrs"
                  @click="copyProjectUrl"
                  :class="$vuetify.breakpoint.lgAndUp ? 'heading4' : 'heading5'"
                  class="font-weight-bold"
                >
                  {{ project.title }}
                </span>
              </template>
              <span>Click to copy the link to this project</span>
            </v-tooltip>
          </div>
          <div style="hyphens: auto">
            {{ project.description }}
          </div>
          <div v-if="project.linkproject_id" class="my-4">
            <v-btn
              color="primary"
              outlined
              :href="
                this.$appUrl +
                '/portfolio/photography#p-' +
                project.linkproject_id
              "
            >
              {{
                $vuetify.breakpoint.lgAndUp
                  ? "See the photos to this shoot"
                  : "Project-Photos"
              }}
            </v-btn>
          </div>
        </div>
        <div class="video-frame">
          <img
            :src="customThumbnail"
            class="video-thumbnail left-gradient"
            @click="showVideo"
          />
          <div class="top-right-text">
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  fab
                  color="primary"
                  @click="showVideo"
                >
                  <v-icon>mdi-play</v-icon>
                </v-btn>
              </template>
              <span>Click to play the video</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </v-row>
    <div v-else-if="project.video_url && $vuetify.breakpoint.smAndDown">
      <div class="video-col-mobile">
        <div class="video-frame-mobile">
          <img
            :src="customThumbnail"
            class="video-thumbnail bottom-gradient"
            @click="showVideo"
          />
          <div class="top-right-text">
            <v-tooltip right color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  fab
                  color="primary"
                  @click="showVideo"
                >
                  <v-icon>mdi-play</v-icon>
                </v-btn>
              </template>
              <span>Click to play the video </span>
            </v-tooltip>
          </div>
          <!-- <LazyYoutube
            class="video-embed"
            :src="project.video_url"
            :max-width="$vuetify.breakpoint.width.toString()"
            :showTitle="false"
            thumbnailQuality="maxres"
            :customThumbnail="customThumbnail"
          /> -->
        </div>
        <div class="video-description-mobile">
          <div class="video-description-col-mobile px-2">
            <div>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-on="on"
                    v-bind="attrs"
                    @click="copyProjectUrl"
                    class="heading5 font-weight-bold"
                  >
                    {{ project.title }}
                  </span>
                </template>
                <span>Click to copy the link to this project</span>
              </v-tooltip>
            </div>
            <div style="hyphens: auto">
              {{ project.description }}
            </div>

            <div v-if="project.linkproject_id" class="my-4">
              <v-btn
                color="primary"
                outlined
                :href="
                  this.$appUrl +
                  '/portfolio/photography#p-' +
                  project.linkproject_id
                "
              >
                {{
                  $vuetify.breakpoint.lgAndUp
                    ? "See the photos to this shoot"
                    : "Project-Photos"
                }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "../plugins/supabase/supabase";
export default {
  name: "projectShowcase",
  data() {
    return {
      slides: 7,
      images: [],
      amountWallImages: 4,
      colsWallImages: 2,
      wallImages: [],
      carouselSettings: {
        border: 0,
        autoplay: true,
        autoplayTimeout: this.$vuetify.breakpoint.mobile ? 6000 : 4000,
        height: 400,
        width: 600,
      },
      customThumbnail: "",
    };
  },
  props: {
    project: Object,
    index: Number,
  },
  async mounted() {
    await this.getProjectImages();
    if (this.project.video_url) {
      this.customThumbnail = await this.getTitleImageUrl();
    }
    if (this.$vuetify.breakpoint.smAndDown) {
      await this.getWallImages(this.amountWallImages);
    }
  },
  watch: {
    "this.$vuetify.breakpoint.name"() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.getWallImages(this.amountWallImages);
      }
    },
  },
  computed: {
    columnWidth: function () {
      let colAmount = 4;
      let centralColumnMulitplier = 8 / 12;
      if (this.$vuetify.breakpoint.smAndDown) {
        colAmount = 3;
        centralColumnMulitplier = 1;
      }
      if (this.$vuetify.breakpoint.xsOnly) {
        colAmount = 3;
        centralColumnMulitplier = 1;
      }
      let width = this.$vuetify.breakpoint.width;
      let centralColumnWidth = width * centralColumnMulitplier;
      centralColumnWidth = Math.floor(centralColumnWidth);
      let columnWidth = centralColumnWidth / colAmount;
      return columnWidth;
    },
  },
  methods: {
    handleMobileImageClick: function (image) {
      let emitObject = {
        selected: image,
        images: this.images,
      };
      this.$emit("overlay", emitObject);
    },
    getWallImages: async function (amount) {
      //shuffle images array
      this.images = this.shuffleArray(this.images);
      let wallImages = [];
      let metaImages = [];
      for (let i = 0; i < this.images.length; i++) {
        let image = this.images[i];
        image.meta = await this.getMeta(
          this.$cdnUrl +
            image.key.replace("BREAKPOINT", this.$vuetify.breakpoint.name)
        );
        metaImages.push(image);
      }
      let horizontalImages = metaImages.filter((image) => image.meta.ratio > 1);
      let verticalImages = metaImages.filter((image) => image.meta.ratio < 1);

      //if amount is even do nothing, if not round down
      if (amount % 2 != 0) {
        amount = amount - 1;
      }

      let maxLength = Math.min(horizontalImages.length, verticalImages.length);
      if (maxLength >= 2) {
        maxLength = maxLength * 2;
      }
      let length = maxLength > amount ? amount : maxLength;

      if (length % 4 == 0 && length > 0) {
        for (let i = 0; i < length / 2; i++) {
          wallImages.push(verticalImages[i]);
          wallImages.push(horizontalImages[i]);
        }
      } else {
        //how often can colsWallImages fit into length
        let possibleRows = Math.floor(length / this.colsWallImages);

        if (possibleRows === 0) {
          let moreHorizontalImages = verticalImages < horizontalImages;
          if (moreHorizontalImages) {
            length =
              amount > horizontalImages.length
                ? horizontalImages.length
                : amount;
            if (length % 2 != 0) {
              length = length - 1;
            }
            for (let i = 0; i < length; i++) {
              wallImages.push(horizontalImages[i]);
            }
          } else {
            length =
              amount > verticalImages.length ? verticalImages.length : amount;
            if (length % 2 != 0) {
              length = length - 1;
            }
            for (let i = 0; i < length; i++) {
              wallImages.push(verticalImages[i]);
            }
          }
        } else {
          if (possibleRows % 2 == 0) {
            for (let i = 0; i < possibleRows / 2; i++) {
              wallImages.push(verticalImages[i]);
            }
            for (let i = 0; i < possibleRows / 2; i++) {
              wallImages.push(horizontalImages[i]);
            }
          } else {
            for (let i = 0; i < possibleRows; i++) {
              wallImages.push(verticalImages.pop());
              wallImages.push(verticalImages.pop());
              i = i + 1;
              if (i < possibleRows) {
                wallImages.push(horizontalImages.pop());
                wallImages.push(horizontalImages.pop());
              }
            }
          }
        }
      }
      //remove wallImages from this.images
      for (let i = 0; i < wallImages.length; i++) {
        let key = wallImages[i].key;
        let index = this.images.findIndex((image) => image.key === key);
        this.images.splice(index, 1);
      }
      //add wallImages to this.images at the beginning
      this.images = wallImages.concat(this.images);
      this.wallImages = wallImages;
    },
    getMeta: async function (url) {
      let img = await this.getImageFromUrl(url);
      let meta = {
        width: img.width,
        height: img.height,
        ratio: img.width / img.height,
      };
      return meta;
    },
    getImageFromUrl: function (url) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => reject();
        img.src = url;
      });
    },
    shuffleArray: function (array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      //add index as property to every item in array
      array.forEach((item, index) => {
        item.index = index;
      });

      return array;
    },
    showVideo: function () {
      if (this.$vuetify.breakpoint.mdAndDown) {
        window.open(this.project.video_url, "_blank");
      } else {
        let payload = {
          url: this.project.video_url,
          thumbnail: this.customThumbnail,
        };
        this.$emit("showVideo", payload);
      }
    },
    getTitleImageUrl: async function () {
      let { data, error } = await supabase
        .from("image")
        .select("key")
        .eq("project_id", this.project.id)
        .eq("title", true)
        .single();
      if (error) {
        console.log("Error getting title image", error);
      } else {
        return (
          this.$cdnUrl +
          data.key.replace("BREAKPOINT", this.$vuetify.breakpoint.name)
        );
      }
    },

    getProjectImages: async function () {
      let response = await supabase
        .from("image")
        .select()
        .match({ project_id: this.project.id });
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Loading Images",
          message: "Failed loading Images. Please try again later",
        };
        this.$globalState.addNotification(notify);
      } else if (response.data && response.data.length > 0) {
        this.images = response.data;
      }
    },
    onMainSlideClick: function () {
      let currentImageIndex = this.$refs.carousel.currentIndex;
      let selectedItem = this.images[currentImageIndex];

      let emitObject = {
        selected: selectedItem,
        images: this.images,
      };

      this.$emit("overlay", emitObject);
    },
    copyProjectUrl: function () {
      this.$clipboard(
        this.$appUrl + this.$route.path + "?p=" + this.project.id
      );
      let notify = {
        duration: 4000,
        type: "success",
        headline: "Successfully copied Link for '" + this.project.title + "'",
        message: "",
      };
      this.$globalState.addNotification(notify);
    },
  },
};
</script>
<style scoped>
.detailimg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  line-height: 0;
  display: block;
}
.Item {
  overflow: hidden;
  width: 100%;
}
.top-left-text {
  position: absolute;
  top: 20px;
  left: 20px;
}
.top-right-text {
  position: absolute;
  top: 20px;
  right: 20px;
}
.left-gradient {
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 40%
  );
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 40%
  );
}
.right-gradient {
  mask-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 40%
  );
  -webkit-mask-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 40%
  );
}
.bottom-gradient {
  mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 40%
  );
  -webkit-mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 40%
  );
}
.video-thumbnail {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.imageBorder {
  display: block;
  width: auto;
  height: auto;

  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.slide {
  background-color: transparent;
}
.heading4 {
  font-size: 34px;
  font-weight: bold;
}
.heading5 {
  font-size: 26px;
  font-weight: bold;
}
.current {
  cursor: pointer;
}

.video-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  height: 40vh;
  /* margin-top: 5vh;
  margin-bottom: 5vh; */
}
.video-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  height: 40vh;
  /* margin-top: 5vh;
  margin-bottom: 5vh; */
}
.video-col-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  height: 50vh;
  /* margin-top: 5vh;
  margin-bottom: 5vh; */
}
.video-frame {
  position: relative;
  text-align: center;
  width: 70%;
  height: 100%;
}
.video-frame-mobile {
  position: relative;
  text-align: center;
  width: 100%;
  height: 60%;
}
.video-description {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.video-description-mobile {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.video-description-col-mobile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
