<template>
  <div>
    <div class="full-size-img-wrapper">
      <img
        class="landing-img"
        :src="
          'https://m8motion-cdn.fra1.cdn.digitaloceanspaces.com/public/static/home/landing/' +
          getBiggerBreakpoint(3) +
          '.webp'
        "
      />
      <div
        :class="
          $vuetify.breakpoint.mdAndUp ? 'menu-top-right' : 'menu-top-center'
        "
      >
        <div class="menu-top-right-item">
          <router-link class="text--text text-decoration-none" to="/portfolio"
            >Portfolio
          </router-link>
        </div>
        <div class="menu-top-right-item">
          <router-link class="text--text text-decoration-none" to="/team"
            >Über</router-link
          >
        </div>

        <div class="menu-top-right-item">
          <router-link class="text--text text-decoration-none" to="/contact"
            >Kontakt</router-link
          >
        </div>
        <div class="menu-top-right-item">
          <v-btn icon href="https://instagram.com/m8motion.media">
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
        </div>
        <div class="menu-top-right-item">
          <v-btn icon href="https://www.youtube.com/@m8motion">
            <v-icon>mdi-youtube</v-icon>
          </v-btn>
        </div>
      </div>
      <div
        :class="
          $vuetify.breakpoint.mdAndUp
            ? 'brand-top-center'
            : 'brand-top-center-block'
        "
      >
        <div class="slogan-wrapper" :style="'width: ' + sloganWidth + 'px'">
          <img
            class="fog-img"
            :src="
              'https://m8motion-cdn.fra1.cdn.digitaloceanspaces.com/public/static/home/landing-fog/' +
              ($vuetify.breakpoint.mdAndUp || isMobileLandscape
                ? 'bar/'
                : 'block/') +
              $vuetify.breakpoint.name +
              '.webp'
            "
          />
          <sloganIcon
            v-if="$vuetify.breakpoint.mdAndUp || isMobileLandscape"
            class="slogan-in-fog"
            colorClass="text"
            :width="sloganWidth * 0.58"
          />
          <sloganIconBlock
            v-else
            class="slogan-in-fog-block"
            colorClass="text"
            :width="sloganWidth * 0.7"
          />
        </div>
      </div>
      <div class="scroll-bottom-center">
        <svg
          class="arrows"
          :style="
            'transform: scale(' +
            ($vuetify.breakpoint.mdAndUp
              ? 1.0
              : isMobileLandscape
              ? 0.5
              : 0.7) +
            '); bottom: ' +
            ($vuetify.breakpoint.mdAndUp ? 20 : isMobileLandscape ? 0 : 30) +
            'px'
          "
          @click="scrollToInfo"
        >
          <path class="a1" d="M0 0 L30 32 L60 0"></path>
          <path class="a2" d="M0 20 L30 52 L60 20"></path>
          <path class="a3" d="M0 40 L30 72 L60 40"></path>
        </svg>
      </div>
    </div>
    <div id="info" class="prevent-full-width mx-auto py-8">
      <div class="text-center italic1 py-3 my-2">
        „Photography is the story we fail to put into words.“
      </div>
      <div class="d-flex justify-center">
        <div class="text1 mx-4">
          Wir sind M8Motion! Ein Team aus leidenschaftlichen Foto- und
          Videografen, die es sich zur Aufgabe gemacht haben diese Werkzeuge zu
          nutzen und deine Geschichte zu erzählen. In einer Zeit, in welcher
          digitale Präsenz und Darstellung immer mehr in den Mittelpunkt rückt,
          möchten wir unsere Kreativität und Fähigkeiten mit der Kamera zur
          Verfügung stellen und dich auf diesem Weg unterstützen. In den letzten
          Jahren haben wir unseren eigenen Stil entwickelt und uns dabei auf die
          folgenden Genres spezialisiert: Automotive, Lifestyle & Portrait. Wie
          wir M8MOTION in ein paar Worten beschreiben würden? Kreativität,
          Leidenschaft, Spaß. Genau das möchten wir mit unseren Fotos und Videos
          vermitteln!
          <br />
          Ist dein Interesse geweckt? Dann stöbere doch gerne durch unsere
          <router-link to="/portfolio">Portfolios</router-link>
          und überzeuge dich von unserem Style.
        </div>
      </div>
    </div>

    <div class="banners hidden-sm-and-down prevent-full-width mx-auto mx-2">
      <div
        class="banner draw-outline banner-animate-hidden"
        v-for="banner in banners"
        :key="banner.img"
        @click="routeTo(banner.link)"
      >
        <div class="banner-content text-uppercase">{{ banner.headline }}</div>
        <img class="banner-img" :src="banner.img" />
      </div>
    </div>
    <div class="banners hidden-md-and-up mx-2">
      <div
        class="banner-mobile"
        v-for="banner in banners"
        :key="banner.img"
        @click="routeTo(banner.link)"
      >
        <img class="banner-img-mobile" :src="banner.img" />
        <div class="banner-content-mobile">
          <div>{{ banner.headline }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sloganIcon from "@/components/sloganIcon.vue";
import sloganIconBlock from "@/components/sloganIconBlock.vue";
export default {
  name: "Home",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Home",
      meta: [
        {
          name: "description",
          content: "Home of " + this.$applicationName,
        },
      ],
    };
  },
  components: {
    sloganIcon,
    sloganIconBlock,
  },

  data() {
    return {
      banners: [
        {
          headline: "Portfolio",
          img:
            "https://m8motion-cdn.fra1.cdn.digitaloceanspaces.com/public/static/home/banner/portfolio/" +
            this.$vuetify.breakpoint.name +
            ".webp",
          link: "/portfolio",
        },
        {
          headline: "Über uns",
          img:
            "https://m8motion-cdn.fra1.cdn.digitaloceanspaces.com/public/static/home/banner/about/" +
            this.$vuetify.breakpoint.name +
            ".webp",
          link: "/team",
        },
        {
          headline: "Kontakt",
          img:
            "https://m8motion-cdn.fra1.cdn.digitaloceanspaces.com/public/static/home/banner/contact/" +
            this.$vuetify.breakpoint.name +
            ".webp",
          link: "/contact",
        },
      ],
    };
  },
  computed: {
    isMobileLandscape() {
      if (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      ) {
        if (this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.height) {
          return true;
        }
      }
      return false;
    },
    sloganWidth() {
      let screenRelatedWidth = this.$vuetify.breakpoint.width - 20;
      let maxWidth = 1100;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          if (this.isMobileLandscape) {
            return screenRelatedWidth;
          }
          return 250;
        case "sm":
          if (this.isMobileLandscape) {
            return screenRelatedWidth;
          }
          return 350;
        case "md":
          return screenRelatedWidth > maxWidth ? maxWidth : screenRelatedWidth;
        case "lg":
          maxWidth = 1200;
          return screenRelatedWidth > maxWidth ? maxWidth : screenRelatedWidth;
        case "xl":
          maxWidth = 1300;
          return screenRelatedWidth > maxWidth ? maxWidth : screenRelatedWidth;
        default:
          return screenRelatedWidth > maxWidth ? maxWidth : screenRelatedWidth;
      }
    },
  },

  mounted() {
    if (this.$route.query.message) {
      let notify = {
        duration: 4000,
        type: "info",
        headline: this.$route.query.message,
        message: "",
      };
      this.$globalState.addNotification(notify);
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("banner-animate-show");
        }
      });
    });
    const hiddenElements = document.querySelectorAll(".banner-animate-hidden");
    hiddenElements.forEach((element) => {
      observer.observe(element);
    });
  },
  methods: {
    getBiggerBreakpoint: function (steps) {
      let breakpoints = ["og", "xl", "lg", "md", "sm", "xs"];
      let currentBreakpoint = this.$vuetify.breakpoint.name;
      let currentBreakpointIndex = breakpoints.indexOf(currentBreakpoint);
      if (currentBreakpointIndex - steps < 1) {
        return breakpoints[1];
      }
      let nextBiggerBreakpoint = breakpoints[currentBreakpointIndex - steps];
      return nextBiggerBreakpoint;
    },
    scrollToInfo() {
      this.$router.push({ path: "/#info" });
    },
    routeTo: function (link) {
      this.$router.push(link);
    },
  },
};
</script>

<style scoped>
.brand-top-center {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
}
.brand-top-center-block {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
}

.text1 {
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 300;
  color: #fff;
  margin: 0 auto;
  max-width: 800px;
  text-align: justify;
  hyphens: auto;
}

.italic1 {
  font-size: 1.3rem;
  line-height: 1.5;
  font-weight: 300;
  color: #fff;
  margin: 0 auto;
  max-width: 800px;
  font-style: italic;
}
.heading4 {
  font-size: 34px;
  font-weight: bold;
}
.banners {
  padding-top: 20px;
}
.draw-outline {
  outline: 1px solid var(--v-background-base);
}
.draw-outline:hover {
  outline: 1px solid var(--v-text-base);
}
.banner {
  width: 100%;
  height: 30vh;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.banner:hover {
  transform: scale(1.01);
}
.banner-animate-hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  /* transform: translateY(50px); */
  transition: opacity 1s ease-in-out;
  transition: filter 1s ease-in-out;
  transition: transform 1s ease-in-out;
}

@media (prefer-reduced-motion) {
  .banner-animate-hidden {
    transition: none;
  }
}
.banner-animate-show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
  /* transform: translateY(0); */
}

.banner-mobile {
  width: 100%;
  height: 40vh;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  outline: 1px solid var(--v-text-base);
}

.banner-content {
  width: 50%;
  height: 100%;
  color: white;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-content-mobile {
  width: 100%;
  height: 40%;
  color: white;
  font-size: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.banner-img {
  width: 70%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 50%
  );
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 50%
  );
}
.banner-img-mobile {
  width: 100%;
  height: 60%;
  object-fit: cover;
  border-radius: 10px;
  mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 50%
  );
  -webkit-mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 50%
  );
}
.arrows {
  width: 60px;
  height: 72px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  bottom: 20px;
  cursor: pointer;
}

.arrows path {
  stroke: var(--v-text-base);
  fill: transparent;
  stroke-width: 2px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s; /* Safari 和 Chrome */
}
.scroll-bottom-center {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.brand {
  font-size: 5rem;
  font-weight: bold;
  color: #fff;
  margin-left: -1rem;
  line-height: 90%;
}
.branding-left {
  position: absolute;
  top: 25%;
  left: 30px;
}
.branding-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.menu-top-right {
  position: absolute;
  top: 20px;
  right: 30px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.menu-top-center {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.landing-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.full-size-img-wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
}
.slogan-wrapper {
  position: relative;
}
.fog-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slogan-in-fog {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.slogan-in-fog-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
