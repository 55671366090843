<template>
  <div id="highlights" class="prevent-full-width mx-auto mt-4">
    <div class="img-row hidden-sm-and-down">
      <SloganCard
        v-for="card in categoryCards"
        :key="card.path"
        :imgSrc="card.imgSrc"
        :title="card.title"
        :subTitle="card.subTitle"
        :path="card.path"
        maxHeight="55vh"
        style="border-radius: 3px"
        class="slogan-card slogan-card-animate-hidden"
      />
    </div>
    <div class="img-col hidden-md-and-up">
      <SloganCard
        v-for="card in categoryCards"
        :key="card.path"
        :imgSrc="card.imgSrc"
        :title="card.title"
        :subTitle="card.subTitle"
        :path="card.path"
        maxHeight="27vh"
        :mobile="true"
        style="border-radius: 3px"
        class="ma-2"
      />
    </div>
    <div class="divider-wrapper">
      <v-divider class="text"></v-divider>
      <span style="max-width: 70%; text-align: center">
        {{
          $vuetify.breakpoint.mdAndUp
            ? "Unentschlossen? Schau dir unsere Highlights an!"
            : "Unsere Highlights"
        }}

        <!-- Can't decide yet? Scroll through our Highlights! -->
      </span>
      <v-divider class="text"></v-divider>
    </div>

    <highlightGrid class="px-3" />
  </div>
</template>
<script>
import highlightGrid from "../components/highlightGrid.vue";
import SloganCard from "../components/sloganCard.vue";
export default {
  name: "Portfolio",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Highlights",
      meta: [
        {
          name: "description",
          content: "Portfolio view of " + this.$applicationName,
        },
      ],
    };
  },
  components: {
    highlightGrid,
    SloganCard,
  },
  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("slogan-card-animate-show");
        }
      });
    });
    const hiddenElements = document.querySelectorAll(
      ".slogan-card-animate-hidden"
    );
    hiddenElements.forEach((element) => {
      observer.observe(element);
    });
  },
  data() {
    return {
      categoryCards: [
        {
          imgSrc:
            this.$cdnUrl +
            "public/static/portfolio/genre/photography/" +
            this.$vuetify.breakpoint.name +
            ".webp",
          title: "Photography",
          subTitle: "Automotive",
          path: "/portfolio/photography",
        },
        {
          imgSrc:
            this.$cdnUrl +
            "public/static/portfolio/genre/videography/" +
            this.$vuetify.breakpoint.name +
            ".webp",
          title: "Videography",
          path: "/portfolio/videography",
        },
        //TODO change more image and save for breakpoints in cdn
        {
          imgSrc:
            this.$cdnUrl +
            "public/static/portfolio/genre/more/" +
            this.$vuetify.breakpoint.name +
            ".webp",
          title: "Photography",
          subTitle: "Lifestyle - Portrait - Street",
          path: "/more",
        },
      ],
    };
  },
  computed: {
    headlineStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return " font-size: 0.9em;";
        case "sm":
          return " font-size: 1.0em;";
        case "md":
          return " font-size: 1.1em;";
        case "lg":
          return " font-size: 1.2em;";
        case "xl":
          return " font-size: 1.4em;";
      }
      return " font-size: 1.4em;";
    },
  },
};
</script>

<style scoped lang="scss">
.divider-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1em 0;
  .text {
    margin: 0 1em;
  }
}
.img-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin: 0 auto;
  padding: 0 12px;
  object-fit: cover;
}
.slogan-card-animate-hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  /* transform: translateY(50px); */
  transition: all 1s ease-in-out;
}

@media (prefer-reduced-motion) {
  .slogan-card-animate-hidden {
    transition: none;
  }
}
.slogan-card-animate-show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
  /* transform: translateY(0); */
}
.slogan-card:nth-child(2) {
  transition-delay: 300ms;
}
.slogan-card:nth-child(3) {
  transition-delay: 600ms;
}
</style>
