<template>
  <v-card elevation="0" color="deepBlack">
    <v-container>
      <v-row>
        <v-col class="text-h5 font-weight-bold"> ToDos </v-col>
        <v-col v-if="ticketView" class="d-flex justify-end">
          <v-dialog v-model="showAddTodoDialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" color="success" fab small>
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <createTodoPanel
              :availableWorkers="availableWorkers"
              v-on:addedTodo="showAddTodoDialog = false"
              v-on:getTodos="getTodos"
              v-on:getEntries="$emit('getEntries')"
            />
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            class="deepBlack"
            :headers="todoTable.headers"
            :items="todoTable.items"
            hide-default-footer
          >
            <template v-slot:[`item.done`]="{ item }">
              <v-btn icon @click="changeTodoState(item)">
                <v-icon small :color="item.done ? 'success' : 'error'">
                  {{
                    item.done
                      ? "mdi-checkbox-marked-outline"
                      : "mdi-checkbox-blank-outline"
                  }}
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.worker`]="{ item }">
              <span v-if="item.worker"> {{ item.worker }}</span>
              <span v-else>TBD</span>
            </template>
            <template v-slot:[`item.created`]="{ item }">
              <span class="ml-n2">
                {{ createDate(item.created) }}
              </span>
            </template>
            <template v-slot:[`item.updated`]="{ item }">
              <span class="ml-n2">
                {{ createDate(item.updated) }}
              </span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                @click="
                  (showChangeResponsibilityDialog = true) &&
                    (selectedTodo = item) &&
                    (newResponsibleWorker = item.workerId)
                "
              >
                <v-icon color="info" small>mdi-account-switch</v-icon>
              </v-btn>
              <v-btn
                v-if="!ticketView"
                icon
                :to="'/ticket/' + item.ticketId"
                target="_blank"
              >
                <v-icon color="success" small>mdi-ticket</v-icon>
              </v-btn>
              <v-btn
                icon
                @click="(showDeleteDialog = true) && (selectedTodo = item)"
              >
                <v-icon color="error" small>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-dialog v-model="showChangeResponsibilityDialog">
        <v-card style="width: 20vw">
          <v-card-title>Change Responsibility</v-card-title>
          <v-container>
            <v-form v-model="validEdit">
              <v-row>
                <v-col>
                  <v-autocomplete
                    color="secondary"
                    label="Responsible Worker"
                    v-model="newResponsibleWorker"
                    :items="availableWorkers"
                    item-text="display_name"
                    item-value="id"
                    :rules="[notEmpty]"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col>
                <v-col class="d-flex justify-end">
                  <v-btn
                    :disabled="!validEdit"
                    @click="changeResponsibility(selectedTodo)"
                    outlined
                    color="success"
                    >Update</v-btn
                  >
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showDeleteDialog">
        <v-card outlined class="overlay">
          <v-container>
            <v-row>
              <v-col>
                <span class="text-h6 font-weight-bold secondary--text"
                  >Delete ToDo</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span v-if="selectedTodo">
                  Are you sure you want to delete this Todo?<br />
                  '<span class="font-weight-bold">{{ selectedTodo.task }}</span>
                  ' <br />This action can not be reverted!
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  @click="showDeleteDialog = !showDeleteDialog"
                  class="mr-3 cancel text--text"
                  text
                  outlined
                  >Cancel</v-btn
                >
                <v-btn
                  @click="deleteTodo(selectedTodo)"
                  color="error"
                  text
                  outlined
                  >Yes</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-card>
</template>
<script>
import { supabase } from "../plugins/supabase/supabase";
import { createDate } from "../plugins/vuetify/helpers";

import createTodoPanel from "./createTodoPanel.vue";

export default {
  name: "todoTable",
  props: {
    ticketView: Boolean,
  },
  components: {
    createTodoPanel,
  },
  mounted() {
    if (this.ticketView) {
      this.getTodos();
    } else {
      this.getPersonalTodos();
    }
    this.getTodos();
    this.getUsers();
  },
  data() {
    return {
      showAddTodoDialog: false,
      availableWorkers: [],
      showChangeResponsibilityDialog: false,
      newResponsibleWorker: null,
      validEdit: false,
      showDeleteDialog: false,
      selectedTodo: null,
      todoTable: {
        items: [],
        headers: [
          {
            text: "State",
            align: "center",
            sortable: true,
            value: "done",
          },
          {
            text: "Todo",
            align: "center",
            sortable: true,
            value: "task",
          },

          {
            text: "Added by",
            align: "center",
            sortable: true,
            value: "added_by",
          },
          {
            text: "Responsible",
            align: "center",
            sortable: true,
            value: "worker",
          },
          {
            text: "Updated",
            align: "center",
            sortable: true,
            value: "updated",
          },
          {
            text: "Created",
            align: "center",
            sortable: true,
            value: "created",
          },

          {
            text: "Actions",
            align: "center",
            sortable: false,
            value: "actions",
          },
        ],
      },
    };
  },
  methods: {
    changeTodoState: async function (todo) {
      let response = null;
      if (!todo.done) {
        response = await supabase
          .from("todo")
          .update({
            done: true,
            updated: Date.now(),
          })
          .match({ id: todo.id });
      } else if (todo.done) {
        response = await supabase
          .from("todo")
          .update({
            done: false,
            updated: Date.now(),
          })
          .match({ id: todo.id });
      }
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "ToDo",
          message: "Failed updating Todo",
        };
        this.$globalState.addNotification(notify);
      } else {
        response = await supabase.from("entry").insert({
          ticket_id: todo.ticketId,
          user_id: supabase.auth.user().id,
          mark: "todo",
          message:
            "[U-" +
            supabase.auth.user().id +
            "] set Todo: '" +
            todo.task +
            "' to [S-" +
            (todo.done ? "open" : "done") +
            "]",
          created: Date.now(),
          updated: Date.now(),
        });

        if (!response.error) {
          let notify = {
            duration: 4000,
            type: "success",
            headline: "ToDo",
            message: "Todo updated",
          };
          this.$globalState.addNotification(notify);
          if (this.ticketView) {
            await this.getTodos();
            this.$emit("getEntries");
          } else {
            await this.getPersonalTodos();
          }
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "ToDo",
            message: "Failed adding Entry to updated Todo to Ticket",
          };
          this.$globalState.addNotification(notify);
        }
      }
    },
    createDate: function (epoch) {
      return createDate(epoch);
    },
    getTodos: async function () {
      let payload = {
        token: supabase.auth.session().access_token,
        ticketId: this.$route.params.id,
      };
      let response = await fetch(this.$apiUrl + "/ticket/todo/list", {
        method: "POST",
        body: JSON.stringify(payload),
      });
      let responseData = await response.json();
      if (responseData.code == 200) {
        this.todoTable.items = responseData.todos;
      }
    },
    getPersonalTodos: async function () {
      let payload = {
        token: supabase.auth.session().access_token,
        userId: supabase.auth.user().id,
      };
      let response = await fetch(this.$apiUrl + "/ticket/todo/personal/list", {
        method: "POST",
        body: JSON.stringify(payload),
      });
      let responseData = await response.json();
      if (responseData.code == 200) {
        this.todoTable.items = responseData.todos;
      }
    },
    getUsers: async function () {
      let payload = {
        token: supabase.auth.session().access_token,
      };
      let response = await fetch(this.$apiUrl + "/ticket/users/list", {
        method: "POST",
        body: JSON.stringify(payload),
      });
      let responseData = await response.json();
      if (responseData.code == 200) {
        this.availableWorkers = responseData.users;
      }
    },
    deleteTodo: async function (todo) {
      let response = await supabase
        .from("todo")
        .delete()
        .match({ id: todo.id });
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "ToDo",
          message: "Failed deleting Todo",
        };
        this.$globalState.addNotification(notify);
      } else {
        response = await supabase.from("entry").insert({
          ticket_id: todo.ticketId,
          user_id: supabase.auth.user().id,
          mark: "todo",
          message:
            "[U-" +
            supabase.auth.user().id +
            "] deleted Todo: '" +
            todo.task +
            "'",
          created: Date.now(),
          updated: Date.now(),
        });

        if (!response.error) {
          let notify = {
            duration: 4000,
            type: "success",
            headline: "ToDo",
            message: "Todo deleted",
          };
          this.$globalState.addNotification(notify);
          if (this.ticketView) {
            await this.getTodos();
            this.$emit("getEntries");
          } else {
            await this.getPersonalTodos();
          }
          this.showDeleteDialog = false;
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "ToDo",
            message: "Failed adding Entry to deleted Todo to Ticket",
          };
          this.$globalState.addNotification(notify);
        }
      }
    },
    changeResponsibility: async function (todo) {
      let response = await supabase
        .from("todo")
        .update({
          worker_id: this.newResponsibleWorker,
          updated: Date.now(),
        })
        .match({ id: todo.id });
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "ToDo",
          message: "Failed updating Todo",
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "ToDo",
          message: "Todo updated",
        };
        this.$globalState.addNotification(notify);
        if (this.ticketView) {
          await this.getTodos();
        } else {
          await this.getPersonalTodos();
        }
        this.showChangeResponsibilityDialog = false;
      }
    },

    //rules
    notEmpty: function (value) {
      return !!value || "This field is required!";
    },
  },
};
</script>
<style scoped></style>
