<template>
  <v-img
    :src="imgSrc"
    :lazy-src="lazySrc"
    @click="$router.push({ path: path })"
    :style="{
      maxHeight: maxHeight ? maxHeight : '100%',
    }"
  >
    <div style="height: 100%; position: relative" class="growblur">
      <div
        :class="mobile ? '' : 'portfoliocard mb-n4'"
        class="center text-uppercase ml-3"
      >
        <div :style="titleStyle" style="font-weight: bold" class="">
          {{ title }}
        </div>
        <div
          v-if="subTitle || !mobile"
          :style="
            $vuetify.breakpoint.mdAndUp
              ? 'margin-top: -5rem'
              : 'margin-top: -2rem'
          "
        >
          {{ subTitle }}
        </div>
      </div>
    </div>
  </v-img>
</template>
<script>
export default {
  name: "sloganCard",
  props: {
    title: String,
    subTitle: String,
    imgSrc: String,
    lazySrc: String,
    path: String,
    maxHeight: String,
    mobile: Boolean,
  },
  computed: {
    titleStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return " font-size: 1.4em;";
        case "sm":
          return " font-size: 1.4em;";
        case "md":
          return " font-size: 1.2em;";
        case "lg":
          return " font-size: 1.5em;";
        case "xl":
          return " font-size: 1.5em;";
      }
      return " font-size: 1.4em;";
    },
    subTitleStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return " font-size: 1.3em;";
        case "sm":
          return " font-size: 1.3em;";
        case "md":
          return "font-size: 1.1em;";
        case "lg":
          return "font-size: 1.4em;";
        case "xl":
          return " font-size: 1.4em;";
      }
      return "font-size: 1.3em;";
    },
  },
};
</script>
<style scoped>
.growblur {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.growblur:hover {
  transform: scale(1.1);
  backdrop-filter: blur(3px);
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}
.spacedletters6 {
  letter-spacing: 0.7em;
  font-size: 1.4vw;
}
.center {
  height: 100%;
  display: grid;
  place-items: center;
}
.portfoliocard {
  height: 30%;
  width: 100%;

  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
