<template>
  <div v-if="gearCategories" class="prevent-full-width mx-auto">
    <div
      class="gear-category category-animate-hidden"
      v-for="(category, catIndex) in gearCategories"
      :key="category.id"
    >
      <div class="gear-title" v-if="category.gear.length > 0">
        {{ category.title }}
      </div>
      <div class="item-container">
        <div class="item" v-for="(gear, index) in category.gear" :key="index">
          <imageDetail
            :src="gear.img_key"
            :hoverText="gear.brand + ' - ' + gear.model"
            :link="gear.shop_link"
            :maxWidth="250"
          />
        </div>
      </div>
      <div class="py-6" v-if="catIndex != gearCategories.length - 1">
        <v-divider class="white"></v-divider>
      </div>
    </div>
    <div class="affiliate-flag">
      Die Produkt-Links auf dieser Seite sind Amazon-Affiliate-Links. Der
      Einkauf darüber kostet Sie nicht mehr, aber Sie unterstützen uns damit.
    </div>
  </div>
</template>
<script>
import { supabase } from "../plugins/supabase/supabase";

import imageDetail from "../components/imageDetail.vue";

export default {
  name: "Gear",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Gear",
      meta: [
        {
          name: "description",
          content:
            "Complete overview of all the Gear used by " +
            this.$applicationName,
        },
      ],
    };
  },
  components: {
    imageDetail,
  },
  data() {
    return {
      gearCategories: null,
    };
  },
  computed: {
    gridContainerStyle: function () {
      if (this.$vuetify.breakpoint.xsOnly) {
        return "display: grid; grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); grid-gap: 10px; grid-auto-rows: minmax(150px, 1fr);";
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return "display: grid; grid-template-columns: repeat(auto-fit, minmax(170px, 1fr)); grid-gap: 10px; grid-auto-rows: minmax(170px, 1fr);";
      } else {
        return "display: grid; grid-template-columns: repeat(6, 1fr); grid-gap: 10px; grid-auto-rows: minmax(300px, 1fr); justify-content:center;";
      }
    },
  },
  async mounted() {
    await this.getGear();

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("category-animate-show");
        }
      });
    });
    const hiddenElements = document.querySelectorAll(
      ".category-animate-hidden"
    );
    hiddenElements.forEach((element) => {
      observer.observe(element);
    });
  },
  methods: {
    getGearCategories: async function () {
      let response = await supabase.from("gearcategory").select();
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Error loading Gear Categories",
          message: "Please try again later!",
        };
        this.$globalState.addNotification(notify);
      } else {
        return response.data;
      }
    },
    getGear: async function () {
      let gearCategories = await this.getGearCategories();
      let response = await supabase.from("gear").select();
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Error loading Gear",
          message: "Please try again later!",
        };
        this.$globalState.addNotification(notify);
      } else {
        for (let i = 0; i < gearCategories.length; i++) {
          gearCategories[i].gear = [];
        }
        for (let i = 0; i < response.data.length; i++) {
          let gear = response.data[i];
          for (let j = 0; j < gearCategories.length; j++) {
            if (gearCategories[j].id == gear.gearcategory_id) {
              gearCategories[j].gear.push(gear);
            }
          }
        }
        this.gearCategories = gearCategories;
      }
    },
  },
};
</script>
<style scoped>
.gear-title {
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem;
  display: flex;
  justify-content: center;
}
.item-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.item {
  flex: 0 0 16%;
  width: 40vw;
  margin: 5px;
}
.category-animate-hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(100%);
  /* transform: translateY(50px); */
  transition: all 1s ease-in-out;
}

@media (prefer-reduced-motion) {
  .category-animate-hidden {
    transition: none;
  }
}
.category-animate-show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
  /* transform: translateY(0); */
}

.affiliate-flag {
  text-align: center;
  font-size: small;
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
