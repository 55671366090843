<template>
  <div :id="'e-' + entry.id">
    <v-card
      v-if="entry.mark === null || entry.mark === 'quote'"
      :color="cardColor"
      :elevation="recursive ? 0 : ''"
    >
      <v-container>
        <v-row>
          <v-col
            v-if="recursive === false"
            class="
              pb-0
              pt-1
              d-flex
              align-center
              justify-start
              text-caption text--text
            "
          >
            <span v-if="entry.userId != $globalState.profile.id">
              {{ entry.firstName }} {{ entry.lastName }} on
              {{ buildDate(entry.created) }}</span
            >
            <span v-else>You on {{ buildDate(entry.created) }}</span>
            <span v-if="entry.created != entry.updated">
              &nbsp;--- Edited on {{ buildDate(entry.updated) }}</span
            >
            <v-btn icon>
              <v-icon small color="text" @click="copyLink">mdi-link</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon small color="text" @click="quoteEntry">
                mdi-comment-quote</v-icon
              >
            </v-btn>
          </v-col>
          <v-col
            class="pb-0 pt-1 d-flex align-center justify-end"
            cols="1"
            v-if="
              entry.userId === $globalState.profile.id &&
              !entry.mail &&
              recursive === false
            "
          >
            <v-dialog v-model="deleteConfirmDialog">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-on="on" v-bind="attrs">
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </template>
              <v-card color="background" outlined class="overlay">
                <v-container>
                  <v-row>
                    <v-col>
                      <span class="text-h6 font-weight-bold secondary--text"
                        >Delete Entry</span
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span
                        >Are you sure you want to delete this Entry?<br />This
                        action can not be reverted!
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex justify-end">
                      <v-btn
                        @click="deleteConfirmDialog = !deleteConfirmDialog"
                        class="mr-3 cancel text--text"
                        text
                        >Cancel</v-btn
                      >
                      <v-btn
                        @click="deleteEntry(entry.id)"
                        color="error"
                        text
                        class="delete"
                        >Yes</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>

            <v-btn
              v-if="editEntry"
              :disabled="editEntryMessage === entry.message"
              icon
            >
              <v-icon small @click="saveEditEntry(entry.id)">
                mdi-content-save
              </v-icon>
            </v-btn>
            <v-btn
              icon
              @click="
                (editEntry = !editEntry) && (editEntryMessage = entry.message)
              "
            >
              <v-icon small v-if="!editEntry"> mdi-pencil </v-icon>
              <v-icon v-else small> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!editEntry" style="white-space: pre-wrap">
            <div v-if="entry.mark === 'quote'">
              <div v-for="(segment, index) in quoteEntrySegments" :key="index">
                <div v-if="segment.quote">
                  <quotePanel :tag="segment.text" :entries="entries" />
                </div>
                <div v-else v-linkified>{{ segment.text }}</div>
              </div>
            </div>
            <div v-else v-linkified>{{ entry.message }}</div>
          </v-col>
          <v-col v-else>
            <v-textarea
              @keyup.esc="editEntry = false"
              v-model="editEntryMessage"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <div v-else>
      <v-tooltip top color="info">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-container
              v-if="entry.mark === 'open' || entry.mark === 'done'"
              @click="copyLink"
              style="cursor: pointer"
            >
              <v-row>
                <v-col cols="1" class="d-flex justify-end align-center py-0">
                  <v-icon large v-if="entry.mark === 'done'" color="success"
                    >mdi-check-all</v-icon
                  >
                  <v-icon large v-else-if="entry.mark === 'open'" color="error"
                    >mdi-alert-circle</v-icon
                  >
                </v-col>

                <v-col class="d-flex justify-start align-center py-0">
                  <v-divider></v-divider>
                  <span :class="'px-4 ' + textColor">
                    {{ markMessage }}
                  </span>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="1"></v-col>
              </v-row>
            </v-container>

            <v-container
              v-else-if="entry.mark === 'attachment'"
              style="cursor: pointer"
              @click="copyLink"
            >
              <v-row>
                <v-col cols="1" class="d-flex justify-end align-center py-0">
                  <v-icon large color="info">mdi-file</v-icon>
                </v-col>

                <v-col class="d-flex justify-start align-center py-0">
                  <v-divider></v-divider>
                  <span :class="'px-4 ' + textColor">
                    {{ markMessage }}
                    <a
                      @click.stop="openAttachment"
                      target="_blank"
                      class="secondary--text"
                      >{{ attachmentName }}</a
                    >
                  </span>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="1"></v-col>
              </v-row>
            </v-container>
            <v-container v-else-if="entry.mark === 'mail'">
              <v-row>
                <v-col cols="2">
                  <v-row no-gutters class="pb-2">
                    <v-col class="d-flex justify-center align-end">
                      <v-icon large>mdi-mail</v-icon>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="d-flex justify-center align-start">
                      {{ entry.firstName }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-card :color="cardColor">
                    <v-container>
                      <v-row no-gutters class="pb-2">
                        <v-col
                          class="
                            d-flex
                            align-center
                            justify-start
                            text-caption text--text
                          "
                        >
                          <span v-if="entry.userId != $globalState.profile.id">
                            {{ entry.firstName }} {{ entry.lastName }} contacted
                            the Client on {{ buildDate(entry.created) }}</span
                          >
                          <span v-else
                            >You contacted the Client on
                            {{ buildDate(entry.created) }}</span
                          >
                          <v-btn icon>
                            <v-icon small color="text" @click="copyLink"
                              >mdi-link</v-icon
                            >
                          </v-btn>
                          <v-btn icon>
                            <v-icon small color="text" @click="quoteEntry">
                              mdi-comment-quote</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col v-linkified>
                          {{ entry.message }}
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <v-container
              v-else-if="entry.mark === 'todo'"
              style="cursor: pointer"
              @click="copyLink"
            >
              <v-row>
                <v-col cols="1" class="d-flex justify-end align-center py-0">
                  <v-icon large color="success" v-if="todoState === 'done'"
                    >mdi-checkbox-marked-outline</v-icon
                  >
                  <v-icon large color="error" v-else-if="todoState === 'open'"
                    >mdi-checkbox-blank-outline</v-icon
                  >
                  <v-icon v-else large color="info"
                    >mdi-checkbox-blank-badge-outline</v-icon
                  >
                </v-col>

                <v-col class="d-flex justify-start align-center py-0">
                  <v-divider></v-divider>
                  <span :class="'px-4 ' + textColor">
                    {{ markMessage }}
                  </span>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="1"></v-col>
              </v-row>
            </v-container>
          </div>
        </template>
        <span>{{ buildDate(entry.created) }}</span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
import { createDate } from "@/plugins/vuetify/helpers.js";
import { s3SignedLink } from "../plugins/s3/s3";

import quotePanel from "./quotePanel.vue";
export default {
  name: "entryCard",
  props: {
    entry: Object,
    entries: Array,
    recursive: Boolean,
  },
  data() {
    return {
      editEntry: false,
      editEntryMessage: "",
      deleteConfirmDialog: false,
      attachmentPath: "",
      attachmentName: "",
      markMessage: "",
      quoteEntrySegments: [],
      todoState: "",
    };
  },
  async mounted() {
    if (this.entry.mark) {
      await this.personalisedActionText(this.entry);
    }
  },
  components: {
    quotePanel,
  },
  computed: {
    cardColor: function () {
      let entryId = this.$route.hash.replace("#e-", "");
      if (this.entry.id === entryId) {
        return "orange";
      } else if (this.recursive) {
        return "grey";
      } else if (this.entry.mark === "mail") {
        return "purple";
      } else if (this.entry.userId === this.$globalState.profile.id) {
        return "blue";
      } else {
        return "green";
      }
    },
    textColor: function () {
      let entryId = this.$route.hash.replace("#e-", "");
      if (this.entry.id === entryId) {
        return "orange--text";
      } else {
        return "grey--text";
      }
    },
  },
  methods: {
    generateQuoteEntry: function (entry) {
      let message = entry.message;

      //split message into array of words
      let words = message.split(" ");

      let segments = [];
      let tempSegment = {
        quote: false,
        text: "",
      };

      for (let i = 0; words.length > i; i++) {
        if (words[i].includes("[Q-") && words[i].includes("-Q/]")) {
          segments.push({
            quote: false,
            text: tempSegment.text,
          });
          tempSegment = {
            quote: false,
            text: "",
          };
          segments.push({ quote: true, text: words[i] });
        } else {
          tempSegment.text = tempSegment.text + words[i] + " ";
        }

        if (i === words.length - 1) {
          segments.push({
            quote: false,
            text: tempSegment.text,
          });
          tempSegment = {
            quote: false,
            text: "",
          };
        }
      }

      this.quoteEntrySegments = segments;
    },
    quoteEntry: function () {
      this.$emit("quoteEntry", this.entry.id);
    },
    copyLink: function () {
      this.$clipboard(this.$appUrl + this.$route.path + "#e-" + this.entry.id);
      let notify = {
        duration: 4000,
        type: "success",
        headline: "Successfully copied Link to message!",
        message: "",
      };
      this.$globalState.addNotification(notify);
      this.$router.push({
        path: this.$route.path,
        hash: "#e-" + this.entry.id,
      });
    },
    personalisedActionText: async function (entry) {
      if (entry.mark === "attachment") {
        //replace userId with name
        let userId = entry.message
          .substring(
            entry.message.lastIndexOf("[U-") + 1,
            entry.message.indexOf("]")
          )
          .replace("U-", "");

        let attachmentId = entry.message
          .substring(
            entry.message.lastIndexOf("[Att-") + 1,
            entry.message.lastIndexOf("]")
          )
          .replace("Att-", "");

        let message = entry.message.replace(
          "[U-" + userId + "]",
          entry.firstName + " " + entry.lastName
        );
        if (userId === this.$globalState.profile.id) {
          message = entry.message.replace("[U-" + userId + "]", "You");
        }
        message = message.replace("[Att-" + attachmentId + "]", "");

        await this.getAttachmentData(attachmentId);

        this.markMessage = message;
      } else if (entry.mark === "open" || entry.mark === "done") {
        let userId = entry.message
          .substring(
            entry.message.lastIndexOf("[U-") + 1,
            entry.message.indexOf("]")
          )
          .replace("U-", "");

        let message = entry.message.replace(
          "[U-" + userId + "]",
          entry.firstName + " " + entry.lastName
        );
        if (userId === this.$globalState.profile.id) {
          message = entry.message.replace("[U-" + userId + "]", "You");
        }

        this.markMessage = message;
      } else if (entry.mark === "quote") {
        this.generateQuoteEntry(entry);
      } else if (entry.mark === "todo") {
        let userId = entry.message
          .substring(
            entry.message.lastIndexOf("[U-") + 1,
            entry.message.indexOf("]")
          )
          .replace("U-", "");

        let message = entry.message.replace(
          "[U-" + userId + "]",
          entry.firstName + " " + entry.lastName
        );
        if (userId === this.$globalState.profile.id) {
          message = entry.message.replace("[U-" + userId + "]", "You");
        }

        if (message.includes("[S-done]") || message.includes("[S-open]")) {
          let state = message
            .substring(message.indexOf("[S-") + 1, message.indexOf("]"))
            .replace("S-", "");
          this.todoState = state;
          message = message.replace("[S-" + state + "]", "'" + state + "'");
        }

        this.markMessage = message;
      } else {
        return "";
      }
    },
    getAttachmentData: async function (attachmentId) {
      let response = await supabase
        .from("attachment")
        .select()
        .match({ id: attachmentId });
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Failed getting attachment!",
          message: "",
        };
        this.$globalState.addNotification(notify);
      } else {
        this.attachmentPath = response.data[0].path;
        this.attachmentName = response.data[0].file_name;
      }
    },
    openAttachment: async function () {
      let link = await s3SignedLink(this.attachmentPath);
      window.open(link);
    },

    buildDate: function (epoch) {
      return createDate(epoch);
    },
    saveEditEntry: async function (entryId) {
      let response = await supabase
        .from("entry")
        .update({ message: this.editEntryMessage, updated: Date.now() })
        .match({ id: entryId });

      if (response.error === null) {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Successfully edited Entry",
          message: "",
        };
        this.$globalState.addNotification(notify);
        this.$emit("getEntries");
        this.editEntry = false;
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Failed Editing Entry",
          message: "",
        };
        this.$globalState.addNotification(notify);
      }
    },
    deleteEntry: async function (entryId) {
      let response = await supabase
        .from("entry")
        .delete()
        .match({ id: entryId });
      if (response.error === null) {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Successfully deleted Entry",
          message: "",
        };
        this.$globalState.addNotification(notify);
        this.$emit("getEntries");
        this.deleteConfirmDialog = false;
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Failed Deleting Entry",
          message: "",
        };
        this.$globalState.addNotification(notify);
      }
    },
  },
};
</script>
<style scoped>
.cancel {
  outline: solid;
  outline-width: 2px;
  outline-color: var(--v-primary-base);
}
.delete {
  outline: solid;
  outline-width: 2px;
  outline-color: var(--v-error-base);
}
.linkified::after {
  color: red !important;
}
</style>
