<template>
  <div class="prevent-full-width mx-auto mt-4">
    <div class="glow">
      <v-card
        :elevation="$vuetify.breakpoint.smAndDown ? 0 : 3"
        color="deepBlack"
      >
        <v-container>
          <v-row>
            <v-col cols="1"> </v-col>
            <v-col class="d-flex justify-center">
              <span :style="headlineTextStyle">Project Manager</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center"> </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card elevation="0" color="deepBlack">
                <v-card-title>
                  <v-row>
                    <v-col class="d-fley align-center">
                      <v-text-field
                        label="Search Projects"
                        v-model="projectSearch"
                        prepend-icon="mdi-magnify"
                        color="secondary"
                      ></v-text-field>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-dialog v-model="linkProjectsDialog">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="info"
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            rounded
                            class="mx-4"
                          >
                            <v-icon color="info">mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                        <v-card color="deepBlack">
                          <v-card-title>
                            Link Photography & Videography Projects
                          </v-card-title>
                          <v-container>
                            <v-form ref="linkProjectsForm" v-model="validLink">
                              <v-row>
                                <v-col>
                                  <v-autocomplete
                                    label="Photography-Project"
                                    :rules="[notEmpty]"
                                    :items="linkablePhotographyProjects"
                                    return-object
                                    item-text="title"
                                    v-model="linkProjectPair.photography"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col>
                                  <v-autocomplete
                                    label="Videography-Project"
                                    :rules="[notEmpty]"
                                    :items="linkableVideographyProjects"
                                    return-object
                                    item-text="title"
                                    v-model="linkProjectPair.videography"
                                  ></v-autocomplete>
                                </v-col>

                                <v-col
                                  class="d-flex justify-center align-center"
                                >
                                  <v-btn
                                    color="info"
                                    :disabled="!validLink"
                                    @click="linkProjects"
                                    >Link</v-btn
                                  >
                                </v-col>
                              </v-row>
                            </v-form>
                            <v-row>
                              <v-divider></v-divider>
                            </v-row>
                            <v-row>
                              <v-col class="font-weight-bold">
                                Linked Projects
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-data-table
                                  class="deepBlack"
                                  disable-pagination
                                  hide-default-footer
                                  :headers="linkTable.headers"
                                  :items="linkTable.items"
                                >
                                  <template
                                    v-slot:[`item.photography`]="{ item }"
                                  >
                                    <span> {{ item.photography.title }} </span>
                                  </template>
                                  <template
                                    v-slot:[`item.videography`]="{ item }"
                                  >
                                    <span> {{ item.videography.title }} </span>
                                  </template>
                                  <template v-slot:[`item.actions`]="{ item }">
                                    <v-btn icon @click="unlinkProject(item)">
                                      <v-icon color="error"
                                        >mdi-link-variant-off</v-icon
                                      >
                                    </v-btn>
                                  </template>
                                </v-data-table>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="createProjectDialog" persistent>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="success"
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            rounded
                            class="mx-4"
                          >
                            <v-icon color="success">mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <v-card
                          color="deepBlack"
                          :style="
                            $vuetify.breakpoint.name === 'lg' ||
                            $vuetify.breakpoint.name === 'xl' ||
                            $vuetify.breakpoint.name === 'md'
                              ? 'width:20vw'
                              : ''
                          "
                        >
                          <v-card-title> Create a Project </v-card-title>

                          <v-container>
                            <v-form ref="projectForm" v-model="validProject">
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    label="Title"
                                    v-model="newProject.title"
                                    :rules="[notEmpty]"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-select
                                    :items="genres"
                                    label="Genre"
                                    v-model="newProject.genre_id"
                                    item-value="id"
                                    item-text="name"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-textarea
                                    label="Description"
                                    v-model="newProject.description"
                                    :rules="[notEmpty]"
                                  ></v-textarea>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    label="Video-URL"
                                    v-model="newProject.video_url"
                                    :rules="[validUrl]"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                            <v-row>
                              <v-col>
                                <v-checkbox
                                  label="Highlight Project"
                                  v-model="newProject.highlight"
                                ></v-checkbox>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-col class="d-flex justify-end">
                                  <v-btn
                                    outlined
                                    color="error"
                                    @click="createProjectDialog = false"
                                    class="mr-4"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    :disabled="!validProject"
                                    @click="createProject"
                                    :loading="createLoading"
                                    outlined
                                    color="success"
                                    >Create</v-btn
                                  >
                                </v-col>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-dialog>
                      <v-btn icon @click="getProjects" class="mr-4">
                        <v-icon :class="rotateClass" color="secondary"
                          >mdi-refresh</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-data-table
                  class="deepBlack"
                  disable-pagination
                  multi-sort
                  hide-default-footer
                  :search="projectSearch"
                  :loading="projectTable.loading"
                  :headers="projectTable.headers"
                  :items="projectTable.items"
                >
                  <template v-slot:[`item.description`]="{ item }">
                    <v-tooltip bottom max-width="50vw">
                      <template v-slot:activator="{ on, attrs }">
                        <td v-bind="attrs" v-on="on" class="truncate">
                          {{ item.description }}
                        </td>
                      </template>
                      <div>{{ item.description }}</div>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.highlight`]="{ item }">
                    <span
                      v-if="item.highlight != false && item.highlight != true"
                      >N.A.</span
                    >
                    <v-icon v-else-if="item.highlight">mdi-check</v-icon>
                    <span v-else>-</span>
                  </template>
                  <template v-slot:[`item.updated`]="{ item }">
                    <span> {{ formatDate(item.updated) }} </span>
                  </template>
                  <template v-slot:[`item.created`]="{ item }">
                    <span> {{ formatDate(item.created) }} </span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                      icon
                      @click="
                        (showEditDialog = true) &&
                          (selectedProject = item) &&
                          fillEditProject(item)
                      "
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      @click="
                        (showDeleteDialog = true) && (selectedProject = item)
                      "
                    >
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <v-dialog v-model="showEditDialog">
      <v-card style="width: 20vw">
        <v-card-title>Edit Project</v-card-title>
        <v-container>
          <v-form v-model="validEdit">
            <v-row>
              <v-col>
                <v-text-field
                  label="Title"
                  v-model="editProject.title"
                  :rules="[notEmpty]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :items="genres"
                  label="Genre"
                  v-model="editProject.genre_id"
                  item-value="id"
                  item-text="name"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  label="Description"
                  v-model="editProject.description"
                  :rules="[notEmpty]"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Video-URL"
                  v-model="editProject.video_url"
                  :rules="[validUrl]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col>
              <v-checkbox
                label="Highlight Project"
                v-model="editProject.highlight"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-col class="d-flex justify-end">
                <v-btn
                  :disabled="!validEdit"
                  @click="updateProject(selectedProject)"
                  :loading="updateLoading"
                  outlined
                  color="success"
                  >Update</v-btn
                >
              </v-col>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDeleteDialog">
      <v-card>
        <v-card-title> Delete Confirmation </v-card-title>
        <v-container>
          <v-row>
            <v-col>
              Are you sure you want to delete project '{{
                selectedProject.title
              }}' and all of its {{ selectedProject.imageCount }} images?
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn
                outlined
                color="primary"
                class="mr-4"
                @click="showDeleteDialog = false"
                >No, Cancel!</v-btn
              >
              <v-btn
                outlined
                @click="deleteProject(selectedProject)"
                color="error"
                :loading="deleteLoading"
                >Yes, delete it!</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { supabase } from "../plugins/supabase/supabase";
import { DeleteObjectCommand } from "@aws-sdk/client-s3";
import { createClient, bucketName } from "../plugins/s3/s3client";
export default {
  name: "ProjectManager",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Project-Manager",
      meta: [
        {
          name: "description",
          content: this.$applicationName + "'s Project Manager",
        },
      ],
    };
  },
  async mounted() {
    await this.getGenres();
    this.getProjects();
  },
  data() {
    return {
      genres: [],
      createProjectTab: 0,
      createProjectDialog: false,
      validProject: false,
      validEdit: false,
      createLoading: false,
      updateLoading: false,
      deleteLoading: false,
      projectSearch: null,
      showDeleteDialog: false,
      showEditDialog: false,
      selectedProject: {
        name: "",
      },
      newProject: {
        title: null,
        genre_id: null,
        description: null,
        highlight: false,
        video_url: null,
      },
      editProject: {
        title: null,
        genre_id: null,
        description: null,
        highlight: false,
        video_url: null,
      },
      projectTable: {
        loading: false,
        items: [],
        headers: [
          {
            text: "Title",
            align: "center",
            sortable: true,
            value: "title",
          },
          {
            text: "Description",
            align: "center",
            sortable: true,
            value: "description",
          },
          {
            text: "Genre",
            align: "center",
            sortable: true,
            value: "genre",
          },
          {
            text: "Images",
            align: "center",
            sortable: true,
            value: "imageCount",
          },
          {
            text: "Highlight",
            align: "center",
            sortable: true,
            value: "highlight",
          },
          {
            text: "Updated",
            align: "center",
            sortable: true,
            value: "updated",
          },
          {
            text: "Created",
            align: "center",
            sortable: true,
            value: "created",
          },
          {
            text: "Actions",
            align: "center",
            sortable: false,
            value: "actions",
          },
        ],
      },
      linkProjectsDialog: false,
      validLink: false,
      linkProjectPair: {
        photography: null,
        videography: null,
      },
    };
  },
  computed: {
    linkTable: function () {
      let refreshKey = this.refreshLinkTableKey + 1;
      let table = {
        refreshKey: refreshKey,
        headers: [
          {
            text: "Photography",
            align: "center",
            sortable: true,
            value: "photography",
          },
          {
            text: "Videography",
            align: "center",
            sortable: true,
            value: "videography",
          },
          {
            text: "Actions",
            align: "center",
            sortable: true,
            value: "actions",
          },
        ],
        items: [],
      };
      let projects = this.projectTable.items;

      let genres = this.$globalState.genres;

      //get genreid for photography
      let photographyGenre = genres.find(
        (genre) => genre.name == "Photography"
      );
      let photographyGenreId = photographyGenre.id;

      //get all photography projects that have a linkproject_id
      let linkedProjects = projects.filter(
        (project) =>
          project.genre_id == photographyGenreId && project.linkproject_id
      );

      for (let i = 0; i < linkedProjects.length; i++) {
        let project = linkedProjects[i];
        let linkedProject = projects.find(
          (p) => p.id == project.linkproject_id
        );
        if (linkedProject != null) {
          table.items.push({
            photography: project,
            videography: linkedProject,
          });
        }
      }
      return table;
    },
    linkablePhotographyProjects: function () {
      let photographyGenre = this.$globalState.genres.filter(
        (genre) => genre.name === "Photography"
      );
      return this.projectTable.items.filter(
        (project) =>
          project.genre_id === photographyGenre[0].id && !project.linkproject_id
      );
    },
    linkableVideographyProjects: function () {
      let videographyGenre = this.$globalState.genres.filter(
        (genre) => genre.name === "Videography"
      );
      return this.projectTable.items.filter(
        (project) =>
          project.genre_id === videographyGenre[0].id && !project.linkproject_id
      );
    },

    rotateClass: function () {
      if (this.projectTable.loading) {
        return "rotate";
      }
      return "";
    },
    headlineTextStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "letter-spacing: 0.3em; font-size: 1.5em; font-weight: bold;";
        case "sm":
          return "letter-spacing: 0.3em; font-size: 1.7em; font-weight: bold;";
        case "md":
          return "letter-spacing: 0.3em; font-size: 2.3em; font-weight: bold;";
        case "lg":
          return "letter-spacing: 0.3em; font-size: 2.5em; font-weight: bold;";
        case "xl":
          return "letter-spacing: 0.3em; font-size: 2.5em; font-weight: bold;";
      }
      return "letter-spacing: 0.3em; font-size: 2.5em; font-weight: bold;";
    },
  },
  methods: {
    linkProjects: async function () {
      let response = {
        photography: null,
        videography: null,
      };
      response.photography = await supabase
        .from("project")
        .update({
          updated: Date.now(),
          linkproject_id: this.linkProjectPair.videography.id,
        })
        .eq("id", this.linkProjectPair.photography.id);

      response.videography = await supabase
        .from("project")
        .update({
          updated: Date.now(),
          linkproject_id: this.linkProjectPair.photography.id,
        })
        .eq("id", this.linkProjectPair.videography.id);

      if (response.photography.error || response.videography.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Project Linking Failed",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Project Linking Successful",
          message: "The projects have been linked successfully.",
        };
        this.$globalState.addNotification(notify);
        this.$refs.linkProjectsForm.reset();
        await this.getProjects();
      }
    },
    unlinkProject: async function (link) {
      let response = {
        photography: null,
        videography: null,
      };
      response.photography = await supabase
        .from("project")
        .update({
          updated: Date.now(),
          linkproject_id: null,
        })
        .eq("id", link.photography.id);

      response.videography = await supabase
        .from("project")
        .update({
          updated: Date.now(),
          linkproject_id: null,
        })
        .eq("id", link.videography.id);

      if (response.photography.error || response.videography.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Project Unlinking Failed",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Project Unlinking Successful",
          message: "The projects have been unlinked successfully.",
        };
        this.$globalState.addNotification(notify);
        await this.getProjects();
      }
    },
    createProject: async function () {
      this.createLoading = true;
      this.newProject.created = Date.now();
      this.newProject.updated = Date.now();
      let response = await supabase.from("project").insert([this.newProject]);

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Project Creation",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Project Creation",
          message:
            "Successfully created Project '" + this.newProject.title + "'",
        };
        this.createLoading = false;
        this.$globalState.addNotification(notify);
        this.getProjects();
        this.createProjectDialog = false;
        this.$refs.projectForm.reset();
        this.$refs.projectVideoForm.reset();
      }
      this.createLoading = false;
    },
    getProjects: async function () {
      this.projectTable.loading = true;
      let response = await supabase.from("project").select();

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Loading Projects",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        this.projectTable.items = response.data;
        for (let i = 0; i < this.projectTable.items.length; i++) {
          this.projectTable.items[i].showDeleteDialog = false;

          response = await supabase
            .from("genre")
            .select()
            .match({ id: this.projectTable.items[i].genre_id });

          if (response.data) {
            this.projectTable.items[i].genre = response.data[0].name;
          } else {
            this.projectTable.items[i].genre = "N/A";
          }

          response = await supabase
            .from("image")
            .select("*", { count: "exact" })
            .match({ project_id: this.projectTable.items[i].id });
          if (response.count != null) {
            this.projectTable.items[i].imageCount = response.count;
          } else {
            this.projectTable.items[i].imageCount = "N/A";
          }
          if (response.data && response.data.length > 0) {
            this.projectTable.items[i].images = response.data;
          } else {
            this.projectTable.items[i].images = [];
          }
        }
      }
      this.projectTable.loading = false;
    },
    deleteProject: async function (project) {
      this.deleteLoading = true;

      //check if project is linked and if so delete link from other project
      if (project.linkproject_id) {
        let response = await supabase
          .from("project")
          .update({
            updated: Date.now(),
            linkproject_id: null,
          })
          .eq("id", project.linkproject_id);

        if (response.error) {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "Project Unlinking Failed",
            message: response.error.message,
          };
          this.$globalState.addNotification(notify);
          return false;
        } else {
          let notify = {
            duration: 4000,
            type: "success",
            headline: "Project Unlinking Successful",
            message: "The projects have been unlinked successfully.",
          };
          this.$globalState.addNotification(notify);
        }
      }

      let response = await supabase
        .from("project")
        .delete()
        .match({ id: project.id });
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Deleting Project",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        for (let i = 0; i < project.images.length; i++) {
          await this.deleteFile(project.images[i]);
        }
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Deleting Project",
          message: "Successfully deleted Project",
        };
        this.$globalState.addNotification(notify);
        this.getProjects();
        this.showDeleteDialog = false;
      }
      this.deleteLoading = false;
    },
    deleteFile: async function (item) {
      //CDN
      let s3Client = await createClient();
      for (let i = 0; i < this.$globalState.imageVariants.length; i++) {
        const bucketParams = {
          Bucket: bucketName,
          Key: item.key.replace(
            "BREAKPOINT",
            this.$globalState.imageVariants[i].breakpoint
          ),
        };
        try {
          await s3Client.send(new DeleteObjectCommand(bucketParams));
        } catch (err) {
          console.log("Error", err);
        }
      }

      item.showDeleteDialog = false;
    },
    updateProject: async function (project) {
      this.updateLoading = true;
      let response = await supabase
        .from("project")
        .update({
          title: this.editProject.title,
          genre_id: this.editProject.genre_id,
          description: this.editProject.description,
          highlight: this.editProject.highlight,
          video_url: this.editProject.video_url,
          updated: Date.now(),
        })
        .match({ id: project.id });

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Updating Project",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Updating Project",
          message: "Successfully updated Project",
        };
        this.$globalState.addNotification(notify);
        this.getProjects();
      }
      this.showEditDialog = false;
      this.updateLoading = false;
    },
    getGenres: async function () {
      let response = await supabase.from("genre").select();

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Loading Genres",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        this.genres = response.data;
      }
    },
    fillEditProject: function (project) {
      this.editProject.title = project.title;
      this.editProject.genre_id = project.genre_id;
      this.editProject.description = project.description;
      this.editProject.highlight = project.highlight;
      this.editProject.video_url = project.video_url;
    },
    closeDialog: function (project) {
      project.showDeleteDialog = false;
    },
    formatDate: function (epoch) {
      let date = new Date(epoch);
      let day = ("0" + date.getDate()).slice(-2);
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let year = date.getFullYear();
      let hours = ("0" + date.getHours()).slice(-2);
      let minutes = ("0" + date.getMinutes()).slice(-2);
      let seconds = ("0" + (date.getSeconds() + 1)).slice(-2);

      let timestamp =
        day +
        "." +
        month +
        "." +
        year +
        " - " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;

      return timestamp;
    },

    //rules
    notEmpty: function (input) {
      if (input) {
        return true;
      }
      return "This field is required";
    },
    validUrl: function (input) {
      //check with regular expressions if input is valid url
      if (input == null || input == "") {
        return true;
      }
      if (input.match(/^(http|https):\/\/[^ "]+$/)) {
        return true;
      }
      return "Please enter a valid URL";
    },
  },
};
</script>
<style scoped>
.glow {
  -webkit-box-shadow: 0px 0px 15px 10px var(--v-secondary-base);
  box-shadow: 0px 0px 15px 10px var(--v-secondary-base);
  border-radius: 5px;
  margin-bottom: 20px;
}
.rotate {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.truncate {
  max-width: 16vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
