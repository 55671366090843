<template>
  <div>
    <v-row>
      <v-col>
        <span class="text-h6 font-weight-bold pl-4">Gear Categories</span>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-dialog v-model="newCategoryDialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" v-on="on" v-bind="attrs">
              <v-icon>mdi-plus</v-icon><span class="pl-2">Add</span>
            </v-btn>
          </template>
          <v-card style="min-width: 25vw" color="deepBlack">
            <v-container>
              <v-row>
                <v-col>
                  <span>Add new Category</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-form ref="newCategoryForm" v-model="validCategory">
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Title"
                      color="secondary"
                      :rules="[notEmpty]"
                      v-model="newCategory.title"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      label="Description"
                      color="secondary"
                      :rules="[notEmpty]"
                      v-model="newCategory.description"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
              <v-row>
                <v-col>
                  <v-checkbox
                    label="Highlight"
                    color="secondary"
                    v-model="newCategory.highlight"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-end">
                  <v-btn
                    color="error"
                    @click="cancelCategoryDialog"
                    class="mr-2"
                  >
                    Cancel</v-btn
                  >
                  <v-btn
                    color="success"
                    :disabled="!validCategory"
                    @click="addCategory"
                    >Save</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="deepBlack"
          :items="categoryTable.items"
          :headers="categoryTable.headers"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:[`item.title`]="{ item }">
            <v-tooltip top color="info">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">{{ item.title }}</div>
              </template>
              <span>{{ item.description }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.highlight`]="{ item }">
            <v-icon v-if="item.highlight">mdi-check</v-icon>
            <span v-else>-</span>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            {{ createDate(item.created) }}
          </template>
          <template v-slot:[`item.updated`]="{ item }">
            {{ createDate(item.updated) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              icon
              @click="
                (showEditDialog = true) &&
                  (selectedCategory = item) &&
                  fillEditCategory(item)
              "
            >
              <v-icon color="info" small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="(showDeleteDialog = true) && (selectedCategory = item)"
            >
              <v-icon color="error" small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="showDeleteDialog">
      <v-card outlined class="overlay" color="deepBlack">
        <v-container>
          <v-row>
            <v-col>
              <span class="text-h6 font-weight-bold secondary--text"
                >Delete Category</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span v-if="selectedCategory">
                Are you sure you want to delete this Category?<br />
                '<span class="font-weight-bold">{{
                  selectedCategory.title
                }}</span>
                ' <br />This action can not be reverted!
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn
                @click="showDeleteDialog = !showDeleteDialog"
                class="mr-3 cancel text--text"
                text
                outlined
                >Cancel</v-btn
              >
              <v-btn
                @click="deleteCategory(selectedCategory)"
                color="error"
                text
                outlined
                >Yes</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEditDialog">
      <v-card outlined class="overlay" color="deepBlack">
        <v-container>
          <v-row>
            <v-col>
              <span class="text-h6 font-weight-bold secondary--text"
                >Edit Category</span
              >
            </v-col>
          </v-row>
          <div v-if="editCategory">
            <v-form ref="newCategoryForm" v-model="validEditCategory">
              <v-row>
                <v-col>
                  <v-text-field
                    label="Title"
                    color="secondary"
                    :rules="[notEmpty]"
                    v-model="editCategory.title"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    label="Description"
                    color="secondary"
                    :rules="[notEmpty]"
                    v-model="editCategory.description"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col>
                <v-checkbox
                  label="Highlight"
                  color="secondary"
                  v-model="editCategory.highlight"
                ></v-checkbox>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn
                @click="showEditDialog = !showEditDialog"
                class="mr-3 cancel text--text"
                text
                outlined
                >Cancel</v-btn
              >
              <v-btn
                @click="updateCategory(selectedCategory)"
                color="success"
                text
                outlined
                :disabled="!validEditCategory"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
import { createDate } from "../plugins/vuetify/helpers";
export default {
  name: "gearCategoryTable",
  data() {
    return {
      editCategory: {
        title: "",
        description: "",
        highlight: false,
      },
      showEditDialog: false,
      validEditCategory: false,
      selectedCategory: null,
      showDeleteDialog: false,
      newCategoryDialog: false,
      newCategory: {
        title: "",
        description: "",
        highlight: false,
      },
      validCategory: false,
      categoryTable: {
        items: [],
        headers: [
          {
            text: "Title",
            align: "center",
            sortable: true,
            value: "title",
          },
          {
            text: "Highlight",
            align: "center",
            sortable: true,
            value: "highlight",
          },
          {
            text: "Created",
            align: "center",
            sortable: true,
            value: "created",
          },
          {
            text: "Updated",
            align: "center",
            sortable: true,
            value: "updated",
          },
          {
            text: "Actions",
            align: "center",
            sortable: false,
            value: "actions",
          },
        ],
      },
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    updateCategory: async function (category) {
      let response = await supabase
        .from("gearcategory")
        .update({
          title: this.editCategory.title,
          description: this.editCategory.description,
          highlight: this.editCategory.highlight,
          updated: Date.now(),
        })
        .match({ id: category.id });

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Updating Category",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Updating Category",
          message: "Successfully updated Category",
        };
        this.$globalState.addNotification(notify);
        this.getCategories();
      }
      this.showEditDialog = false;
    },
    fillEditCategory: function (category) {
      this.editCategory.title = category.title;
      this.editCategory.description = category.description;
      this.editCategory.highlight = category.highlight;
    },
    deleteCategory: async function (category) {
      let response = await supabase
        .from("gearcategory")
        .delete()
        .match({ id: category.id });
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Category",
          message: "Failed deleting Category",
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "ToDo",
          message: "Todo deleted",
        };
        this.$globalState.addNotification(notify);
        this.showDeleteDialog = false;
        this.getCategories();
      }
    },

    createDate: function (epoch) {
      return createDate(epoch);
    },
    cancelCategoryDialog: function () {
      this.newCategoryDialog = false;
      this.$refs.newCategoryForm.reset();
    },
    addCategory: async function () {
      let category = {
        title: this.newCategory.title,
        description: this.newCategory.description,
        highlight: this.newCategory.highlight,
        created: Date.now(),
        updated: Date.now(),
      };
      let response = await supabase.from("gearcategory").insert(category);

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Failed to add category",
          message: "Please try again later.",
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Category added",
          message: "",
        };
        this.$globalState.addNotification(notify);
        this.getCategories();
        this.$refs.newCategoryForm.reset();
        this.newCategoryDialog = false;
      }
    },
    getCategories: async function () {
      let response = await supabase.from("gearcategory").select();
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Failed loading gear categories",
          message: "Please try again later.",
        };
        this.$globalState.addNotification(notify);
      } else {
        this.categoryTable.items = response.data;
      }
    },

    //RULES
    notEmpty: function (value) {
      return !!value || "This field is Required";
    },
  },
};
</script>
<style scoped></style>
