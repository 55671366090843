<template>
  <div>
    <v-card>
      <v-card-title>Change Title-Image</v-card-title>
      <v-container class="scrollable">
        <v-row>
          <v-col class="font-weight-bold"> Current Title-Image </v-col>
        </v-row>
        <v-row>
          <v-col>
            <imageDetail :src="titleImageSrc" :width="maxWidthImages" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="font-weight-bold"> Select new Title-Image </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-item-group v-if="projectImages" v-model="newTitleImageIndex">
              <div :style="gridContainerStyle">
                <div v-for="(image, index) in projectImages" :key="index">
                  <v-item v-slot="{ active, toggle }">
                    <v-img
                      :src="$cdnUrl + image.key.replace('BREAKPOINT', 'md')"
                      contain
                      @click="toggle"
                    >
                      <v-container v-if="active">
                        <v-row>
                          <v-col>
                            <v-btn icon dark>
                              <v-icon color="success" large>
                                mdi-check-decagram
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-img>
                  </v-item>
                </div>
              </div>
            </v-item-group>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col>
            <v-btn color="error" outlined @click="$emit('closeDialog')"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              class="mx-4"
              :disabled="newTitleImageIndex === null"
              @click="changeTitleImage"
              :loading="changeLoading"
              >Change</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { supabase } from "../plugins/supabase/supabase";

export default {
  name: "titleImageChange",
  props: {
    projectId: String,
    titleImageSrc: String,
  },
  components: {
    imageDetail: () => import("./imageDetail.vue"),
  },
  data() {
    return {
      titleImage: null,
      projectImages: null,
      changeLoading: false,
      newTitleImageIndex: null,
    };
  },
  async mounted() {
    this.projectImages = await this.getProjectImages();
  },
  computed: {
    gridContainerStyle: function () {
      return (
        "display: grid; grid-template-columns: repeat(auto-fill, minmax(" +
        this.maxWidthImages +
        "px, 1fr)); grid-gap: 10px; grid-auto-rows: minmax(" +
        this.maxWidthImages +
        "px, 1fr);"
      );
    },
    maxWidthImages: function () {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 150;
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return 170;
      } else {
        return 300;
      }
    },
  },
  methods: {
    getProjectImages: async function () {
      let response = await supabase
        .from("image")
        .select()
        .match({ project_id: this.projectId });
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Loading Images",
          message: "Failed loading Images. Please try again later",
        };
        this.$globalState.addNotification(notify);
      } else if (response.data && response.data.length > 0) {
        return response.data;
      }
    },
    changeTitleImage: async function () {
      this.changeLoading = true;
      let responseRemove = await supabase
        .from("image")
        .update({
          title: false,
        })
        .match({
          key: this.titleImageSrc,
        });
      let responseAdd = await supabase
        .from("image")
        .update({
          title: true,
        })
        .match({
          key: this.projectImages[this.newTitleImageIndex].key,
        });
      if (responseRemove.error || responseAdd.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Changing Title-Image",
          message: "Failed changing Title-Image. Please try again later",
        };
        this.$globalState.addNotification(notify);
      } else {
        this.$globalState.addNotification({
          duration: 4000,
          type: "success",
          headline: "Changing Title-Image",
          message: "Title-Image changed successfully",
        });
        setTimeout(() => {
          this.$emit("closeDialog");
          this.changeLoading = false;
          location.reload();
        }, 4000);
      }
    },
  },
};
</script>
<style scoped>
.scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 70vh;
}
</style>
