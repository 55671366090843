<template>
  <div class="prevent-full-width mx-auto mt-4">
    <div>
      <span
        class="px-2"
        :class="$vuetify.breakpoint.mdAndUp ? 'heading4' : 'heading5'"
        >Besuch uns auf anderen Kanälen</span
      >
    </div>
    <div
      id="banners "
      class="banners hidden-sm-and-down prevent-full-width mx-auto"
    >
      <div
        class="banner draw-outline"
        v-for="banner in banners"
        :key="banner.img"
        @click="linkTo(banner.link)"
      >
        <div class="banner-content">
          <v-icon size="50">{{ banner.icon }}</v-icon>
          <div>{{ banner.headline }}</div>
        </div>
        <img class="banner-img" :src="banner.img" />
      </div>
    </div>
    <div class="banners hidden-md-and-up">
      <div
        class="banner-mobile"
        v-for="banner in banners"
        :key="banner.img"
        @click="linkTo(banner.link)"
      >
        <img class="banner-img-mobile" :src="banner.img" />
        <div class="banner-content-mobile">
          <v-icon size="50" class="mt-n2 pr-4">{{ banner.icon }}</v-icon>
          <div>{{ banner.headline }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Socials",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Socials",
      meta: [
        {
          name: "description",
          content: "Socials of " + this.$applicationName,
        },
      ],
    };
  },
  data() {
    return {
      banners: [
        {
          img:
            this.$cdnUrl +
            "public/static/about/social/instagram/" +
            this.$vuetify.breakpoint.name +
            ".webp",
          icon: "mdi-instagram",
          headline: "Instagram",
          link: "https://www.instagram.com/m8motion.media/",
        },

        {
          img:
            this.$cdnUrl +
            "public/static/about/social/youtube/" +
            this.$vuetify.breakpoint.name +
            ".webp",
          icon: "mdi-youtube",
          headline: "YouTube",
          link: "https://www.youtube.com/@m8motion",
        },
      ],
    };
  },
  methods: {
    linkTo(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
<style scoped>
.banners {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.draw-outline {
  outline: 1px solid var(--v-background-base);
}
.draw-outline:hover {
  outline: 1px solid var(--v-text-base);
}
.banner {
  width: 100%;
  height: 30vh;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.banner:hover {
  transform: scale(1.01);
}
.banner-mobile {
  width: 100%;
  height: 40vh;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  outline: 1px solid var(--v-text-base);
}

.banner-content {
  width: 30%;
  height: 100%;
  color: white;
  font-size: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.banner-content-mobile {
  width: 100%;
  height: 30%;
  color: white;
  font-size: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.banner-img {
  width: 70%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 50%
  );
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 50%
  );
}
.banner-img-mobile {
  width: 100%;
  height: 70%;
  object-fit: cover;
  border-radius: 10px;
  mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 50%
  );
  -webkit-mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 50%
  );
}
.heading4 {
  font-size: 34px;
  font-weight: bold;
}
.heading5 {
  font-size: 26px;
  font-weight: bold;
}
</style>
