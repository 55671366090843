import { supabase } from "./supabase";
import { s3SignedUpload } from "../s3/s3";
import Vue from "vue";

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[]]/g, "\\$&");
  const regex = new RegExp("[?&#]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

async function handleInvite() {
  let email = parseJwt(supabase.auth.session().access_token).email;
  let response = await supabase.auth.api.resetPasswordForEmail(email);
  if (response.error === null) {
    return true;
  }
  return false;
}

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
async function uploadTicketFile(file, fileName, ticketId) {
  //PREPARE Supabase for upload

  let payload = {
    ticketId: ticketId,
    fileName: fileName,
    fileType: file.type,
    fileSize: file.size,
  };

  if (Vue.prototype.$globalState.isLoggedIn) {
    payload.token = supabase.auth.session().access_token;
  }

  let response = await fetch(
    Vue.prototype.$apiUrl + "/ticket/attachment/prepare",
    {
      method: "POST",
      body: JSON.stringify(payload),
    }
  );

  let responseData = await response.json();

  if (!responseData.state) {
    let notify = {
      duration: 4000,
      type: "error",
      headline: "Failed to prepare attachment for upload",
      message: "",
    };
    Vue.prototype.$globalState.addNotification(notify);
    return false;
  } else {
    let attachmentId = responseData.attachmentId;

    if (Vue.prototype.$globalState.isLoggedIn) {
      // //UPLOAD FILE
      response = await s3SignedUpload(
        responseData.folder,
        file,
        responseData.attachmentId
      );
      if (response) {
        return true;
      } else {
        return false;
      }
    } else {
      response = await fetch(
        Vue.prototype.$apiUrl + "/ticket/attachment/upload",
        {
          method: "POST",
          body: file,
          headers: {
            "Content-Type": file.type,
            x_attachment_id: attachmentId,
          },
        }
      );
    }
  }
}
async function uploadTicketFile2(file, fileName, ticketId) {
  //SUPABASE
  let userId = Vue.prototype.$ticketSystemUser.id;
  if (Vue.prototype.$globalState.isLoggedIn) {
    userId = supabase.auth.user().id;
  }

  let folder = "private/ticket/" + ticketId + "/attachments/";

  //insert attachment to supabase
  let response = await supabase.from("attachment").insert({
    ticket_id: ticketId,
    file_name: fileName,
    path: "REPLACE",
    type: file.type,
    size: file.size,
    created: Date.now(),
    updated: Date.now(),
  });

  if (response.error) {
    let notify = {
      duration: 4000,
      type: "error",
      headline: "Failed to insert attachment into database",
      message: "",
    };
    Vue.prototype.$globalState.addNotification(notify);
    return false;
  } else {
    let attachmentId = response.data[0].id;

    //update path of attachment
    response = await supabase
      .from("attachment")
      .update({ path: folder + attachmentId })
      .match({ id: attachmentId });

    if (response.error) {
      let notify = {
        duration: 4000,
        type: "error",
        headline: "Failed to update attachment path",
        message: "",
      };
      Vue.prototype.$globalState.addNotification(notify);
    } else {
      let message = "[U-" + userId + "] attached [Att-" + attachmentId + "]";

      //add entry to supabase
      response = await supabase.from("entry").insert({
        user_id: userId,
        ticket_id: ticketId,
        message: message,
        created: Date.now(),
        updated: Date.now(),
        mark: "attachment",
      });

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Failed to insert entry into database",
          message: "",
        };
        Vue.prototype.$globalState.addNotification(notify);
      } else {
        response = await s3SignedUpload(folder, file, attachmentId);

        if (response) {
          let notify = {
            duration: 4000,
            type: "success",
            headline: "Attachment uploaded",
            message: "",
          };
          Vue.prototype.$globalState.addNotification(notify);
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "Failed to upload attachment",
            message: "",
          };
          Vue.prototype.$globalState.addNotification(notify);
        }
      }
    }
  }
}

export {
  getParameterByName,
  handleInvite,
  parseJwt,
  uploadTicketFile,
  uploadTicketFile2,
};
