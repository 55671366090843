<template>
  <div class="prevent-full-width mx-auto mt-4">
    <div>
      <span
        class="px-2 text-uppercase"
        :class="$vuetify.breakpoint.mdAndUp ? 'heading4' : 'heading5'"
        >Das Team hinter M8Motion
      </span>
    </div>
    <div class="members hidden-sm-and-down">
      <div class="member" v-for="member in members" :key="member.img">
        <div class="img-wrapper">
          <img class="member-image" :src="member.img" alt="" />
          <div class="member-info">
            <div class="member-name">{{ member.name }}</div>
            <div class="member-role">{{ member.role }}</div>
          </div>
        </div>
        <div class="member-quote text-center pt-4">"{{ member.quote }}"</div>
        <div class="member-description">
          {{ member.bio }}
        </div>
      </div>
    </div>
    <div class="hidden-md-and-up">
      <div class="mt-4" v-for="member in members" :key="member.img">
        <div class="img-wrapper-mobile">
          <img class="member-image" :src="member.img" alt="" />
          <div class="member-info">
            <div class="member-name">{{ member.name }}</div>
            <div class="member-role">{{ member.role }}</div>
          </div>
        </div>
        <div class="member-quote text-center pt-2">"{{ member.quote }}"</div>
        <div class="member-description">
          {{ member.bio }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Team",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Team",
      meta: [
        {
          name: "description",
          content: "Team of " + this.$applicationName,
        },
      ],
    };
  },
  data() {
    return {
      members: [
        {
          name: "Andreas",
          role: "Co-Founder",
          img:
            this.$cdnUrl +
            "public/static/about/team/andreas/" +
            this.$vuetify.breakpoint.name +
            ".webp",
          quote: "Change your perspective in order to change your experience.",
          bio: "Moin! Mein Name ist Andreas, ich bin ein professioneller Fotograf und Videograf aus Hamburg. Und ich liebe meine Kamera aus verschiedensten Gründen. Seit ich meine erste Kamera in die Hand genommen habe, hat sich die Art und Weise wie ich meine Umgebung wahrnehme komplett verändert. Mit dieser Kamera bin ich in der Lage die Schönheit des Augenblicks und die damit verbundenen Gefühle in einem Bild festzuhalten. Ich persönlich bin der Meinung, dass Kameras mehr als nur ein Werkzeug sind, sie sind eine Möglichkeit um die eigenen Visionen zum Ausdruck zu bringen. Ich habe vor einiger Zeit den Schritt ins Unbekannte gewagt ohne große Erwartungen daran zu haben. Aber dieser Schritt führte mich zur Entdeckung meiner wahren Leidenschaft: Fotografie und Videografie. Ich strebe immer danach mein Bestes zu geben, mich zu verbessern und etwas Bedeutsames zu kreieren. Und das mit den bestmöglichen Visuals. Während ich meine Reise fortsetze, bleibt eine einfache Frage offen: Willst du mich auf dem Weg begleiten?",
        },
        {
          name: "Philipp",
          role: "Co-Founder",
          img:
            this.$cdnUrl +
            "public/static/about/team/philipp/" +
            this.$vuetify.breakpoint.name +
            ".webp",
          quote: "Wer fotografiert, hat mehr vom Leben.",
          bio: "Hi, ich bin Philipp. Da ich in einer Familie von Foto- & Videografen aufgewachsen bin haben mich diese Themen schon sehr früh begleitet. Angefangen beim sporadischen Aushelfen bei Aufträgen meiner Eltern bis hin zu eigenen Hobbyprojekten habe ich mich in den letzten Jahren intensiv mit der Thematik auseinandergesetzt und ein „Auge für den richtigen Winkel“ entwickelt. Trotzdem bin ich bei Weitem noch nicht am Ende. Ich lerne stetig dazu und wachse mit meinen Aufgaben und Herausforderungen durch neue Projekte und Inspirationen. Durch meine Faszination für Fahrzeuge aller Art, und vor allem die sportlicher Natur, kann ich mich besonders für die Automotive-Fotografie begeistern. Mit M8Motion kombiniere ich diese beiden Hobbys und mache meine Leidenschaft für das Fotografieren und den Videoschnitt zu meinem Beruf.",
        },
      ],
    };
  },
};
</script>
<style scoped>
.members {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.member {
  width: 50%;
  margin-top: 40px;
}

.img-wrapper {
  position: relative;
  width: 100%;
  height: 40%;
}
.img-wrapper-mobile {
  position: relative;
  width: 100%;
  height: 40vh;
  padding: 10px;
}
.member-info {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.member-quote {
  font-style: italic;
}
.member-image {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 50%
  );
  -webkit-mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 50%
  );
}
.member-name {
  font-size: 2.5rem;
  font-weight: bold;
}
.member-role {
  font-size: 1.3rem;
  margin-top: -0.8rem;
}
.member-description {
  font-size: 1rem;
  font-weight: lighter;
  margin-top: 1rem;
  padding: 10px;
  text-align: justify;
  hyphens: auto;
}
.heading4 {
  font-size: 34px;
  font-weight: bold;
}
.heading5 {
  font-size: 26px;
  font-weight: bold;
}
</style>
