//USED for attachment uploads via presigned URL

import { getS3Credentials } from "./s3client";

const aws = require("aws-sdk");

const bucketName = "m8motion-cdn";

async function createClient() {
  aws.config.update({
    endpoint: "https://fra1.digitaloceanspaces.com",
    secretAccessKey: await getS3Credentials("DO_SPACES_SECRET"),
    accessKeyId: await getS3Credentials("DO_SPACES_KEY"),
  });

  const s3 = new aws.S3({
    signatureVersion: "v4",
    region: "fra1",
  });

  return s3;
}

async function s3SignedUpload(folder, file, fileName) {
  let s3 = await createClient();
  if (file) {
    const key = folder + fileName;
    const params = {
      Bucket: bucketName,
      Key: key,
      Expires: 60,
      ContentType: file.type,
    };
    const url = s3.getSignedUrl("putObject", params);

    let response = await fetch(url, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
      },
    });

    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  }
}

async function s3SignedLink(path) {
  let s3 = await createClient();
  if (path) {
    const params = {
      Bucket: bucketName,
      Key: path,
      Expires: 60,
    };

    const url = s3.getSignedUrl("getObject", params);
    return url;
  }
}

export { createClient, bucketName, s3SignedUpload, s3SignedLink };
