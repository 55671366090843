<template>
  <div
    @click="handleClick"
    :style="'width:' + width + 'px; cursor:pointer; max-width: ' + maxWidth"
  >
    <v-img
      contain
      :width="width"
      :max-width="maxWidth"
      :src="$cdnUrl + src.replace('BREAKPOINT', getSmallerBreakpoint(3))"
      :lazy-src="$cdnUrl + src.replace('BREAKPOINT', 'sm')"
      class="grow"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
    >
      <div
        class="center text-center darkenblur pa-6"
        v-if="hover && hoverText"
        :style="
          'width:' +
          width +
          'px; height:' +
          width +
          'px; max-width: ' +
          maxWidth +
          'max-height: ' +
          maxWidth
        "
      >
        {{ hoverText }}
      </div>
    </v-img>
    <v-overlay
      v-model="overlay"
      :opacity="$vuetify.breakpoint.mdAndDown ? 0.9 : 0.8"
    >
      <v-row>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" class="d-flex align-center">
          <v-btn
            v-if="array"
            class="hidden-sm-and-down"
            icon
            @click.stop="handlePreviousImg"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <v-img
            :src="$cdnUrl + dataSrc.replace('BREAKPOINT', 'xl')"
            :lazy-src="$cdnUrl + dataSrc.replace('BREAKPOINT', 'md')"
            :width="imageSize"
            contain
            class="pa-8"
          >
          </v-img>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" class="d-flex align-center">
          <v-btn
            v-if="array"
            class="hidden-sm-and-down"
            icon
            @click.stop="handleNextImg"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="d-flex justify-center">
          <v-btn
            v-if="array"
            class="hidden-md-and-up"
            icon
            @click.stop="handlePreviousImg"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            icon
            v-if="
              $globalState.isLoggedIn &&
              indexInArray === dataIndexInArray &&
              highlight
            "
            @mouseenter="hoverDislike = true"
            @mouseleave="hoverDislike = false"
            @click.stop="changeHighlightState(false)"
          >
            <v-icon v-if="hoverDislike">mdi-heart-off</v-icon>
            <v-icon v-else>mdi-heart</v-icon>
          </v-btn>
          <v-btn
            icon
            v-else-if="
              $globalState.isLoggedIn &&
              indexInArray === dataIndexInArray &&
              !highlight
            "
            @mouseenter="hoverLike = true"
            @mouseleave="hoverLike = false"
            @click.stop="changeHighlightState(true)"
          >
            <v-icon v-if="hoverLike">mdi-heart</v-icon>
            <v-icon v-else>mdi-heart-outline</v-icon>
          </v-btn>
          <v-dialog v-model="titleChangeOverlay" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="
                  $globalState.isLoggedIn &&
                  indexInArray === dataIndexInArray &&
                  titleImage
                "
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-swap-horizontal </v-icon>
              </v-btn>
            </template>
            <titleImageChange
              :projectId="parseProjectId(dataSrc)"
              :titleImageSrc="dataSrc"
              v-on:closeDialog="titleChangeOverlay = false"
            />
          </v-dialog>
          <v-btn
            v-if="array"
            class="hidden-md-and-up"
            icon
            @click.stop="handleNextImg"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-overlay>
  </div>
</template>
<script>
import { supabase } from "../plugins/supabase/supabase";

import titleImageChange from "./titleImageChange.vue";
export default {
  name: "imageDetail",
  props: {
    src: String,
    width: Number,
    maxWidth: Number,
    hoverText: String,
    titleImage: Boolean,
    indexInArray: Number,
    array: Array,
    link: String,
  },
  components: {
    titleImageChange,
  },
  data() {
    return {
      dataSrc: "",
      dataIndexInArray: 0,
      dataArray: [],
      overlay: false,
      hover: false,
      highlight: false,
      hoverLike: false,
      hoverDislike: false,
      titleChangeOverlay: false,
    };
  },
  mounted() {
    this.dataSrc = this.src;
    this.dataIndexInArray = this.indexInArray;
    this.dataArray = this.array;
  },
  computed: {
    imageSize: function () {
      let size = this.$vuetify.breakpoint.width;
      if (this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.height) {
        size = this.$vuetify.breakpoint.height;
      }
      return size - size * 0.1;
    },
  },
  methods: {
    handleNextImg: function () {
      this.dataIndexInArray = this.dataIndexInArray + 1;
      this.dataSrc = this.dataArray[this.dataIndexInArray].key;
    },
    handlePreviousImg: function () {
      this.dataIndexInArray = this.dataIndexInArray - 1;
      this.dataSrc = this.dataArray[this.dataIndexInArray].key;
    },

    handleClick: function () {
      if (this.link) {
        window.open(this.link, "_blank");
      } else if (this.hoverText) {
        let pathItems = this.src.split("/");
        this.$router.push({
          path: "portfolio/" + pathItems[3],
          hash: "p-" + pathItems[4],
          // hash: "test1",
        });
      } else {
        if (this.overlay === false) {
          if (this.$globalState.isLoggedIn) {
            this.getHighlightState();
          }
          let notify = {
            duration: 2000,
            type: "info",
            headline: "",
            message: "Click anywhere to close this view",
          };
          this.$globalState.addNotification(notify);
        }
        this.overlay = !this.overlay;
      }
    },
    getHighlightState: async function () {
      let pathItems = this.src.split("/");
      let imageId = pathItems[5];

      let response = await supabase
        .from("image")
        .select("highlight")
        .match({ id: imageId });

      if (response.error) {
        let notify = {
          duration: 2000,
          type: "error",
          headline: "Error",
          message: "Couldn't get image highlight state",
        };
        this.$globalState.addNotification(notify);
      } else {
        this.highlight = response.data[0].highlight;
      }
    },
    changeHighlightState: async function (state) {
      let response = await supabase
        .from("image")
        .update({ highlight: state, updated: Date.now() })
        .match({ id: this.src.split("/")[5] });
      if (response.error) {
        let notify = {
          duration: 2000,
          type: "error",
          headline: "Error",
          message: "Couldn't change image highlight state",
        };
        this.$globalState.addNotification(notify);
      } else {
        if (state) {
          let notify = {
            duration: 2000,
            type: "success",
            headline: "Success",
            message: "Image has been highlighted",
          };
          this.$globalState.addNotification(notify);
        } else {
          let notify = {
            duration: 2000,
            type: "success",
            headline: "Success",
            message: "Image has been unhighlighted",
          };
          this.$globalState.addNotification(notify);
        }
        this.getHighlightState();
      }
    },
    parseProjectId: function (src) {
      let pathItems = src.split("/");
      return pathItems[4];
    },
    getSmallerBreakpoint: function (steps) {
      let breakpoints = ["og", "xl", "lg", "md", "sm", "xs"];
      let currentBreakpoint = this.$vuetify.breakpoint.name;
      if (currentBreakpoint === "xs") {
        return "xs";
      }
      let nextSmallerBreakpoint =
        breakpoints[breakpoints.indexOf(currentBreakpoint) + steps];
      return nextSmallerBreakpoint;
    },
  },
};
</script>
<style scoped>
.grow {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.grow:hover {
  transform: scale(1.1);
  z-index: 2;
}
.center {
  height: 100%;
  display: grid;
  place-items: center;
}
.darkenblur {
  backdrop-filter: blur(3px);
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}
</style>
