<template>
  <div>
    <v-container fluid class="mt-16"> Videography </v-container>
  </div>
</template>
<script>
export default {
  name: "Photography",
};
</script>
<style scoped></style>
