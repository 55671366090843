<template>
  <v-card elevation="0" min-width="400" color="deepBlack">
    <v-container>
      <v-row>
        <v-col class="text-h5 font-weight-bold"> Create Todo </v-col>
      </v-row>
      <v-form v-model="validTodo" ref="todoForm">
        <v-row>
          <v-col>
            <v-text-field
              label="Task"
              v-model="todo.task"
              :rules="[notEmpty]"
              color="secondary"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              label="Responsible"
              v-model="todo.worker"
              color="secondary"
              :items="availableWorkers"
              item-text="display_name"
              item-value="id"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn color="success" :disabled="!validTodo" @click="addTodo"
            >Add</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { supabase } from "../plugins/supabase/supabase";
export default {
  name: "createTodoPanel",
  props: {
    availableWorkers: Array,
  },
  data() {
    return {
      todo: {
        task: "",
        worker: "",
      },
      validTodo: false,
    };
  },
  methods: {
    addTodo: async function () {
      if (this.todo && this.todo.task) {
        let payload = {
          task: this.todo.task,
          worker: this.todo.worker,
          token: supabase.auth.session().access_token,
          ticketId: this.$route.params.id,
        };
        let response = await fetch(this.$apiUrl + "/ticket/todo/create", {
          method: "POST",
          body: JSON.stringify(payload),
        });
        let responseData = await response.json();

        if (responseData.state) {
          response = await supabase.from("entry").insert({
            ticket_id: this.$route.params.id,
            user_id: supabase.auth.user().id,
            mark: "todo",
            message:
              "[U-" +
              supabase.auth.user().id +
              "] added Todo: " +
              this.todo.task,
            created: Date.now(),
            updated: Date.now(),
          });

          if (!response.error) {
            let notify = {
              duration: 4000,
              type: "success",
              headline: "ToDo",
              message: responseData.msg,
            };
            this.$globalState.addNotification(notify);
            this.$emit("addedTodo");
            this.$emit("getTodos");
            this.$emit("getEntries");
            this.$refs.todoForm.reset();
          } else {
            let notify = {
              duration: 4000,
              type: "error",
              headline: "ToDo",
              message: "Failed adding Entry to created Todo to Ticket",
            };
            this.$globalState.addNotification(notify);
          }
        }
      }
    },

    //rules
    notEmpty: function (value) {
      return !!value || "This field is required!";
    },
  },
};
</script>
<style scoped></style>
