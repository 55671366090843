<template>
  <div class="prevent-full-width mx-auto mt-4">
    <createTicketForm
      title="Interesse oder Fragen? Schreib uns eine Nachricht!"
      :contactForm="true"
    />
  </div>
</template>
<script>
import createTicketForm from "../components/createTicketForm.vue";
export default {
  name: "Contact",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Contact",
      meta: [
        {
          name: "description",
          content: "Contact " + this.$applicationName,
        },
      ],
    };
  },
  components: {
    createTicketForm,
  },
};
</script>
<style scoped></style>
