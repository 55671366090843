<template>
  <div class="prevent-full-width mx-auto mt-4">
    <div class="contact-card hidden-sm-and-down">
      <div class="form-col">
        <div class="form-row" v-if="title">
          <span style="font-size: 1.7rem; font-weight: bold">
            {{ title }}
          </span>
        </div>
        <v-form v-model="validTicket" ref="ticketForm">
          <div class="form-row">
            <v-text-field
              color="secondary"
              label="First Name*"
              :rules="[notEmpty]"
              v-model="ticket.firstName"
            ></v-text-field>

            <v-text-field
              color="secondary"
              label="Last Name*"
              :rules="[notEmpty]"
              v-model="ticket.lastName"
            ></v-text-field>
          </div>
          <div class="form-row">
            <v-text-field
              color="secondary"
              label="E-Mail*"
              type="email"
              :rules="[notEmpty, validEmail]"
              v-model="ticket.email"
            ></v-text-field>
            <v-text-field
              color="secondary"
              label="Phone"
              type="tel"
              :rules="[validPhone]"
              v-model="ticket.phone"
            ></v-text-field>
          </div>
          <div class="form-row">
            <v-text-field
              color="secondary"
              label="Subject*"
              :rules="[notEmpty]"
              v-model="ticket.subject"
            ></v-text-field>
          </div>
          <div class="form-row">
            <v-textarea
              color="secondary"
              label="Message*"
              :rules="[notEmpty]"
              v-model="ticket.message"
            ></v-textarea>
          </div>
        </v-form>
        <div class="form-row" v-if="contactForm">
          <v-file-input
            label="Attachments"
            :show-size="true"
            multiple
            color="secondary"
            v-model="attachments"
            :rules="[max10Mb]"
          ></v-file-input>
        </div>
        <div
          :class="
            cancelButton
              ? 'form-row d-flex justify-end'
              : 'form-row d-flex justify-center'
          "
        >
          <v-btn
            v-if="cancelButton"
            class="mr-4"
            outlined
            color="error"
            @click="$emit('cancelNewTicket')"
            >Abbrechen</v-btn
          >
          <v-btn
            color="secondary"
            :disabled="!validTicket"
            @click="sendTicket"
            :loading="ticketLoading"
          >
            Senden
          </v-btn>
        </div>
      </div>
      <div class="image-col">
        <img
          class="contact-img"
          :src="
            this.$cdnUrl +
            'public/static/contact/formimage/' +
            getSmallerBreakpoint(2) +
            '.webp'
          "
        />
      </div>
    </div>
    <div class="px-4 hidden-md-and-up">
      <div class="contact-card-mobile">
        <div class="image-row-mobile">
          <img
            class="contact-img-mobile"
            :src="
              this.$cdnUrl +
              '/public/static/contact/formimage/' +
              $vuetify.breakpoint.name +
              '.webp'
            "
          />
        </div>
        <div class="form-row-mobile">
          <div class="form-col-mobile">
            <div class="form-row" v-if="title">
              <span style="font-size: 1.5rem; font-weight: bold">
                {{ title }}
              </span>
            </div>
            <v-form v-model="validTicket" ref="ticketFormMobile">
              <div class="form-row">
                <v-text-field
                  color="secondary"
                  label="First Name*"
                  :rules="[notEmpty]"
                  v-model="ticket.firstName"
                ></v-text-field>

                <v-text-field
                  color="secondary"
                  label="Last Name*"
                  :rules="[notEmpty]"
                  v-model="ticket.lastName"
                ></v-text-field>
              </div>
              <div class="form-row">
                <v-text-field
                  color="secondary"
                  label="E-Mail*"
                  type="email"
                  :rules="[notEmpty, validEmail]"
                  v-model="ticket.email"
                ></v-text-field>
                <v-text-field
                  color="secondary"
                  label="Phone"
                  type="tel"
                  :rules="[validPhone]"
                  v-model="ticket.phone"
                ></v-text-field>
              </div>
              <div class="form-row">
                <v-text-field
                  color="secondary"
                  label="Subject*"
                  :rules="[notEmpty]"
                  v-model="ticket.subject"
                ></v-text-field>
              </div>
              <div class="form-row">
                <v-textarea
                  color="secondary"
                  label="Message*"
                  :rules="[notEmpty]"
                  v-model="ticket.message"
                ></v-textarea>
              </div>
            </v-form>
            <div class="form-row" v-if="contactForm">
              <v-file-input
                label="Attachments"
                :show-size="true"
                multiple
                color="secondary"
                v-model="attachments"
                :rules="[max10Mb]"
              ></v-file-input>
            </div>
            <div
              :class="
                cancelButton
                  ? 'form-row d-flex justify-end'
                  : 'form-row d-flex justify-center'
              "
            >
              <v-btn
                v-if="cancelButton"
                class="mr-4"
                outlined
                color="error"
                @click="$emit('cancelNewTicket')"
                >Abbrechen</v-btn
              >
              <v-btn
                color="secondary"
                :disabled="!validTicket"
                @click="sendTicket"
                :loading="ticketLoading"
              >
                Senden
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { uploadTicketFile } from "../plugins/supabase/helpers";

export default {
  name: "createTicketForm",
  props: {
    title: String,
    cancelButton: Boolean,
    contactForm: Boolean,
  },
  data() {
    return {
      validTicket: false,
      ticketLoading: false,

      ticket: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      },
      // TODO remove placeholder object
      // ticket: {
      //   firstName: "Philipp",
      //   lastName: "Katzenberger",
      //   email: "flip.the.second@gmail.com",
      //   phone: "01716876827",
      //   subject: "Test-Ticket",
      //   message:
      //     "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.   Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet,",
      // },
      attachments: null,
    };
  },
  methods: {
    //Methods
    getSmallerBreakpoint: function (steps) {
      let breakpoints = ["og", "xl", "lg", "md", "sm", "xs"];
      let currentBreakpoint = this.$vuetify.breakpoint.name;
      if (currentBreakpoint === "xs") {
        return "xs";
      }
      let nextSmallerBreakpoint =
        breakpoints[breakpoints.indexOf(currentBreakpoint) + steps];
      return nextSmallerBreakpoint;
    },
    resetForm: function () {
      this.$refs.ticketForm.reset();
      this.$refs.ticketFormMobile.reset();
    },
    sendTicket: async function () {
      this.ticketLoading = true;
      let response = await fetch(this.$apiUrl + "/ticket/new", {
        method: "POST",
        body: JSON.stringify(this.ticket),
      });

      let responseData = await response.json();

      if (responseData.state) {
        //upload attachment
        if (this.attachments) {
          let ticketId = responseData.ticketId;
          for (let i = 0; i < this.attachments.length; i++) {
            let attachment = this.attachments[i];
            await uploadTicketFile(attachment, attachment.name, ticketId);
          }
        }

        let notify = {
          duration: 4000,
          type: "success",
          headline: "Ticket",
          message: responseData.msg,
        };
        this.$globalState.addNotification(notify);
        this.$emit("createdTicket");
        this.$refs.ticketForm.reset();
        this.$refs.ticketFormMobile.reset();
        this.ticketLoading = false;
        this.attachments = null;
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Ticket",
          message: responseData.msg,
        };
        this.$globalState.addNotification(notify);
      }
      this.ticketLoading = false;
    },

    //RULES
    notEmpty: function (input) {
      if (input && input.length > 0) {
        return true;
      }
      return "This field is required!";
    },
    validPhone: function (input) {
      const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
      if (re.test(String(input).toLowerCase())) {
        return true;
      } else if (!input) {
        return true;
      }
      return "Invalid Phone";
    },
    validEmail: function (input) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(input).toLowerCase())) {
        return true;
      }
      return "Invalid E-Mail";
    },
    max10Mb: function (input) {
      if (input) {
        let totalSize = 0;

        for (let i = 0; i < input.length; i++) {
          totalSize += input[i].size;
        }
        if (totalSize <= 10485760) {
          return true;
        }
        return "File size must be less than 10MB";
      }
      return true;
    },
  },
};
</script>
<style scoped>
/* .background {
  background-color: red;
} */
.submit {
  justify-content: center;
}
.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.contact-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 50%
  );
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 50%
  );
}
.contact-img-mobile {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 50%
  );
  -webkit-mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    var(--v-background-base) 50%
  );
}
.contact-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70vh;
  border-radius: 10px;
  background-color: var(--v-background-base);
  outline: 1px solid var(--v-text-base);
}
.contact-card-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  border-radius: 10px;
  background-color: var(--v-background-base);
  outline: 1px solid var(--v-text-base);
}
.form-col {
  width: 55%;
  height: 100%;
  padding-left: 20px;
  /* align items center */

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form-col-mobile {
  width: 100%;
  height: 100%;
  /* align items center */

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form-row-mobile {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}
.image-col {
  width: 45%;
  height: 100%;
}
.image-row-mobile {
  width: 100%;
  height: 30%;
}
.center-full-size {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
