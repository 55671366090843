import { render, staticRenderFns } from "./AdminDashboard.vue?vue&type=template&id=6de7c3b5&scoped=true&"
import script from "./AdminDashboard.vue?vue&type=script&lang=js&"
export * from "./AdminDashboard.vue?vue&type=script&lang=js&"
import style0 from "./AdminDashboard.vue?vue&type=style&index=0&id=6de7c3b5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6de7c3b5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VDataTable,VIcon})
