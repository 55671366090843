<template>
  <div class="border-top">
    <div class="hidden-sm-and-down prevent-full-width mx-auto footer-row">
      <div class="name-col" @click="$router.push('/')">
        <textIcon
          colorClass="text"
          :width="$vuetify.breakpoint.lgAndUp ? 300 : 230"
          class="pr-2 mb-n1"
        />
      </div>
      <div class="content-row">
        <div
          class="content-col"
          v-for="contentCategory in contentCategorys"
          :key="contentCategory.category"
        >
          <div class="content-category">{{ contentCategory.category }}</div>
          <div
            class="content-item"
            v-for="(contentItem, index) in contentCategory.items"
            :key="index"
          >
            <router-link
              class="link"
              v-if="!contentItem.externalLink"
              :to="contentItem.link"
              >{{ contentItem.name }}</router-link
            >
            <a v-else :href="contentItem.link" target="_blank" class="link">{{
              contentItem.name
            }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden-md-and-up hidden-xs-only">
      <div class="d-flex justify-center pt-4" @click="$router.push('/')">
        <textIcon colorClass="text" :width="250" />
      </div>
      <div class="items-row pt-3">
        <div
          class="content-col"
          v-for="halfCategories in getHalfCategories()"
          :key="halfCategories[0].category"
        >
          <div
            class="col-item pt-3"
            v-for="contentCategory in halfCategories"
            :key="contentCategory.category"
          >
            <div class="content-category text-center">
              {{ contentCategory.category }}
            </div>
            <div
              class="content-item d-flex justify-center"
              v-for="(contentItem, index) in contentCategory.items"
              :key="index"
            >
              <router-link
                class="link"
                v-if="!contentItem.externalLink"
                :to="contentItem.link"
                >{{ contentItem.name }}</router-link
              >
              <a v-else :href="contentItem.link" target="_blank" class="link">{{
                contentItem.name
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden-sm-and-up">
      <div class="d-flex justify-center pt-4" @click="$router.push('/')">
        <textIcon colorClass="text" :width="250" />
      </div>
      <div
        class="content-col pt-3"
        v-for="contentCategory in contentCategorys"
        :key="contentCategory.category"
      >
        <div class="content-category text-center">
          {{ contentCategory.category }}
        </div>
        <div
          class="content-item d-flex justify-center"
          v-for="(contentItem, index) in contentCategory.items"
          :key="index"
        >
          <router-link
            class="link"
            v-if="!contentItem.externalLink"
            :to="contentItem.link"
            >{{ contentItem.name }}</router-link
          >
          <a v-else :href="contentItem.link" target="_blank" class="link">{{
            contentItem.name
          }}</a>
        </div>
      </div>
    </div>
    <div class="hidden-xs-only text-caption mt-5 caption-row">
      <span class="left caption-item">
        © M8Motion Media {{ new Date().getFullYear() }}
        <router-link class="text--text px-4" to="/imprint">Imprint</router-link>
        <router-link class="text--text px-4" to="/privacy">Privacy</router-link>
      </span>
      <span class="right caption-item">
        Website by&nbsp;
        <a class="text--text" href="https://fantasyflip.de" target="_blank">
          FantasyFlip</a
        >
      </span>
    </div>
    <div class="hidden-sm-and-up text-caption mt-5 caption-row">
      <div>
        <span class="caption-item">
          © M8Motion Media {{ new Date().getFullYear() }}
          <router-link class="text--text px-4" to="/imprint"
            >Imprint</router-link
          >
          <router-link class="text--text px-4" to="/privacy"
            >Privacy</router-link
          >
        </span>
      </div>
      <div>
        <span class="caption-item mt-n5">
          Website by&nbsp;
          <a class="text--text" href="https://fantasyflip.de" target="_blank">
            FantasyFlip</a
          >
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import textIcon from "@/components/textIcon.vue";
export default {
  name: "mainFooter",
  components: {
    textIcon,
  },
  data() {
    return {
      contentCategorys: [
        {
          category: "Portfolio",
          items: [
            { link: "/portfolio", name: "Highlights" },
            { link: "/portfolio/photography", name: "Photography" },
            { link: "/portfolio/videography", name: "Videography" },
          ],
        },
        {
          category: "About",
          items: [
            { link: "/team", name: "Meet the Team" },
            { link: "/gear", name: "Our Gear" },
            // { link: "/services", name: "Services" },
            { link: "/socials", name: "Socials" },
          ],
        },

        {
          category: "Social",
          items: [
            {
              link: "https://www.youtube.com/@m8motion",
              name: "YouTube",
              externalLink: true,
            },
            {
              link: "https://www.instagram.com/m8motion.media/",
              name: "Instagram",
              externalLink: true,
            },
          ],
        },
        {
          category: "Other",
          items: [
            { link: "/contact", name: "Contact" },
            { link: "/login", name: "Login" },
          ],
        },
      ],
    };
  },
  methods: {
    getHalfCategories() {
      let firstHalf = this.contentCategorys.slice(
        0,
        Math.ceil(this.contentCategorys.length / 2)
      );
      let secondHalf = this.contentCategorys.slice(
        Math.ceil(this.contentCategorys.length / 2),
        this.contentCategorys.length
      );
      return [firstHalf, secondHalf];
    },
  },
};
</script>
<style scoped>
.border-top {
  border-top: 1px solid #eaeaea;
  margin-top: 40px;
}
.content-item {
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
}
.link {
  color: #fff;
}
.link:hover {
  color: var(--v-primary-base);
}
.content-category {
  font-weight: bold;
  margin-bottom: 10px;
}
.content-row {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.items-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20%;
}
.name-col {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  cursor: pointer;
}
.footer-row {
  display: flex;
  padding: 1rem 0;
}
.caption-row {
  width: 100%;
}
.caption-item {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.left {
  float: left;
}
.right {
  float: right;
}
</style>
