<template>
  <div>
    <svg
      viewBox="0 0 1200 1350"
      :width="svgWidth"
      :height="svgHeight"
      @click="handleLink()"
    >
      <!-- <svg
        id="Ebene_1"
        data-name="Ebene 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 1350"
      > -->
      <path
        :class="colorClass"
        d="M601.03,284.37C699.49,213.75,797.61,143.37,895.74,73c1.78,0.62,3.29,1.09,4.76,1.68c28.89,11.64,54.57,28.35,77.64,49.17
		c20.01,18.05,37.11,38.64,52.48,60.68c25.29,36.28,45.46,75.24,60.84,116.7c0.61,1.65,1.09,3.36,1.59,4.93
		c-4.09,3.28-66.13,38.87-72.16,41.41c-1.29-1.49-1.54-3.48-2.07-5.33c-5.35-18.45-11.59-36.59-19.07-54.29
		c-9.99-23.66-21.88-46.27-37.44-66.8c-9.96-13.14-21.22-24.98-34.7-34.61c-9.69-6.92-20.37-11.78-31.39-15.52
		c-0.44,0.09-0.64,0.12-0.83,0.17c-0.19,0.05-0.41,0.09-0.56,0.2c-59.78,42.57-119.55,85.15-179.32,127.73
		c-0.65,0.46-1.28,0.96-1.9,1.46c-0.15,0.12-0.27,0.3-0.36,0.48c-0.08,0.17-0.09,0.37-0.2,0.85c1.13,0,2.22,0.13,3.27-0.02
		c33.23-4.65,65.83-2.1,97.67,8.7c66.49,22.55,117.14,76.85,134.64,144.43c6.02,23.24,7.85,46.86,5.79,70.8
		c-4.6,53.38-26.67,98.39-65.7,134.81c-30.93,28.86-67.6,47.08-109.57,52.96c-71.86,10.06-133.77-10.83-184.15-63.24
		c-28.87-30.03-46.73-66.22-53.3-107.46c-7.53-47.28-0.57-92.41,22.23-134.81c1.98-3.68,4.14-7.26,6.16-10.77
		c-13.16-19.06-53.02-41.05-85.36-46.72c-43.15-7.56-83.22,0.57-119.49,25.27c-72.6,49.44-91.62,148.76-42.14,221.29
		c27.04,39.63,64.75,63.05,112.46,69.04c47.66,5.98,89.71-7.79,126.41-38.97c3.91,4.08,6.42,8.88,9.82,12.98
		c3.32,4,6.53,8.1,9.97,11.99c3.57,4.03,7.35,7.87,11.3,12.07c-1.24,1.22-2.25,2.4-3.44,3.37c-29.05,23.82-61.88,39.5-99.08,45.57
		c-38.98,6.35-76.96,2.68-113.61-12.32c-61.27-25.07-106.93-77.54-123.05-141.3c-5.53-21.89-7.24-44.11-5.69-66.65
		c1.84-26.66,8.35-52.05,19.97-76.09c13.36-27.66,32.6-50.69,56.09-70.26c30.31-25.26,65.34-39.84,104.27-45.16
		c18.92-2.59,37.9-2.28,56.83,0.27c1.09,0.15,2.19,0.19,3.54,0.31c-0.75-2.33-2.56-2.98-3.96-3.98
		c-26.51-18.97-53.04-37.91-79.58-56.83c-30.94-22.06-61.89-44.1-92.84-66.14c-6.73-4.79-4.86-4.12-11.86-1.74
		c-14.24,4.85-26.53,12.95-37.62,22.98c-14.51,13.13-26.21,28.53-36.41,45.14c-13.87,22.6-24.57,46.68-33.31,71.66
		c-3.43,9.81-6.39,19.78-9.56,29.68c-0.48,1.51-0.97,3.01-1.59,4.93c-1.06-0.37-1.99-0.54-2.76-0.98
		c-22.4-12.79-44.79-25.59-67.17-38.42c-0.84-0.48-1.6-1.12-2.53-1.79c0.41-1.5,0.67-2.86,1.15-4.14
		c19.28-51.72,45.31-99.67,80.04-142.72c18.96-23.5,40.63-44.1,65.82-60.87c13.18-8.77,27.01-16.37,41.62-22.5
		c1.11-0.46,2.27-0.82,3.32-1.38c3.2-1.7,5.87-1.15,8.81,1.01c11.93,8.76,24.01,17.3,36.04,25.92
		c54.28,38.92,108.56,77.84,162.84,116.77c28.27,20.28,56.53,40.58,84.8,60.87C596.21,280.98,598.34,282.47,601.03,284.37z
		M906.44,507.89c0.36-87.67-70.95-159.65-159.17-159.99c-87.7-0.34-160.18,70.59-160.4,159.41
		c-0.23,89.6,72.72,160.16,159.52,160.3C834.2,667.74,906.63,596.29,906.44,507.89z"
      />
      <path
        :class="colorClass"
        d="M895.79,1007.63c-32.55-30.02-65.01-59.96-97.42-89.86c-0.92-2.88-0.42-5.69-0.5-8.46c-0.09-3-0.02-6-0.02-9
		c0-2.8,0-5.6,0-8.4c0-3,0-6,0-9c0-2.8,0-5.6,0-8.4c0-2.8,0-5.6,0-8.4c0-3,0-6,0-9c0-2.8,0-5.6,0-8.4c0-3-0.02-6,0.01-9
		c0.02-2.71-0.14-5.44,0.28-8.96c6.54,5.04,11.66,10.28,17.05,15.22c5.46,4.99,10.83,10.08,16.25,15.12
		c5.42,5.04,10.85,10.06,16.27,15.09c5.28,4.89,10.57,9.77,15.85,14.67c5.42,5.03,10.86,10.04,16.24,15.12
		c5.36,5.06,10.66,10.2,16.15,15.46c2.32,0.19,4.52-0.92,6.74-1.7c13.8-4.83,25.78-12.67,36.59-22.38
		c15.01-13.49,27.06-29.38,37.47-46.56c12.9-21.28,23.06-43.85,31.47-67.23c0.94-2.62,1.93-5.22,3.03-8.18
		c1.1,0.39,2.05,0.57,2.84,1.03c20.32,11.58,40.63,23.19,60.93,34.81c0.68,0.39,1.29,0.9,2.26,1.59c-0.26,0.89-0.43,1.84-0.81,2.69
		c-16.79,37.84-37.49,73.31-64.05,105.19c-18.77,22.54-39.79,42.7-63.93,59.48c-14.67,10.19-30.23,18.75-46.82,25.38
		C899.84,1006.27,897.95,1006.86,895.79,1007.63z"
      />
      <path
        :class="colorClass"
        d="M193.37,781.23c0.87,2.17,1.63,3.98,2.32,5.81c8.32,22.34,18.15,43.97,30.6,64.34c10.26,16.8,22.07,32.37,36.73,45.63
		c10.32,9.34,21.74,16.96,34.8,21.95c2.05,0.78,4.19,1.35,6.24,2.11c1.92,0.72,3.6,0.48,4.84-1.16
		c4.62-6.12,10.64-10.82,16.19-15.99c24.73-23.03,49.52-45.99,74.3-68.96c1.31-1.21,2.68-2.35,3.91-3.43
		c1.58,0.31,1.48,1.39,1.51,2.35c0.05,1.6,0.03,3.2,0.03,4.8c0,23.8,0,47.59,0,71.39c0,1-0.11,2.01,0.02,3
		c0.41,3.11-1,5.22-3.24,7.26c-14.33,13.07-28.56,26.24-42.82,39.4c-15.87,14.64-31.73,29.3-47.6,43.95
		c-1.32,1.21-2.68,2.38-4.07,3.62c-2.55,0.08-4.66-1.18-6.84-2.06c-23.57-9.51-44.85-22.81-64.41-38.94
		c-29.11-24-53.27-52.35-73.76-83.93c-12.21-18.82-22.83-38.51-32.29-58.83c-0.67-1.44-1.2-2.93-1.9-4.64
		c0.75-0.69,1.36-1.5,2.16-1.96c20.47-11.72,40.96-23.41,61.45-35.1C191.87,781.64,192.28,781.58,193.37,781.23z"
      />
      <path
        :class="colorClass"
        d="M108.93,770.02c-5.61-10.29-18.21-56.56-23.77-83.78c-8.29-40.65-13.65-95.67-12.64-110.78c0.86-0.16,1.8-0.48,2.74-0.48
		c26.18-0.03,52.35-0.03,78.53-0.01c0.96,0,1.92,0.23,3.2,0.4c1.48,10.44,1.03,20.84,2.11,31.12c1.08,10.33,1.7,20.71,2.85,31.03
		c1.16,10.52,2.54,21.02,4.15,31.48c1.58,10.26,3.42,20.49,5.41,30.68c1.99,10.16,4.31,20.26,6.56,30.71
		c-7.53,4.84-15.29,8.92-22.85,13.31c-7.77,4.52-15.59,8.95-23.42,13.38C124.17,761.39,116.65,765.89,108.93,770.02z"
      />
      <path
        :class="colorClass"
        d="M1045.55,576.36c0.85-0.31,1.39-0.68,1.94-0.68c26.97-0.03,53.94-0.03,80.91-0.01c0.54,0,1.08,0.36,1.56,0.53
		c1.06,7.14-0.87,32.73-3.54,54.34c-5.6,45.31-15.02,89.74-29.87,133.38c-1.17-0.37-2.13-0.5-2.91-0.94
		c-21.86-12.47-43.71-24.96-65.55-37.47c-0.51-0.29-0.95-0.71-1.74-1.32c0.14-1.16,0.18-2.52,0.48-3.82
		c5.83-25.33,10.31-50.9,13.23-76.71c1.78-15.67,2.83-31.43,4.12-47.15c0.46-5.57,0.61-11.17,0.94-16.75
		C1045.19,578.59,1045.41,577.44,1045.55,576.36z"
      />
      <path
        :class="colorClass"
        d="M1130.85,524.28c-5.6,0.89-43.69,1.17-80,0.61c-1.57-0.02-3.13-0.23-5.06-0.38c-1.02-8.52-0.67-16.9-1.41-25.21
		c-0.73-8.16-1.05-16.35-1.74-24.52c-0.71-8.36-1.53-16.72-2.52-25.05c-0.97-8.13-2.13-16.24-3.35-24.34
		c-1.24-8.27-2.66-16.51-4.04-24.96c5.05-3.38,10.19-6.01,15.16-8.91c5-2.92,10.06-5.76,15.09-8.63c5.03-2.87,10.07-5.73,15.1-8.61
		c5.03-2.87,10.04-5.8,15.1-8.61c5-2.78,9.76-6.06,15.1-8.22c2.77,5.86,8.84,33.58,12.48,54.44
		C1128.54,456.2,1131.79,509.17,1130.85,524.28z"
      />
      <path
        :class="colorClass"
        d="M157.05,523.81c-5.5,0.75-68.29,1.13-82.2,0.57c-0.95-0.04-1.9-0.29-2.76-0.43c-4.01-23.68,11.09-137.78,22.02-166.41
		c0.85,0.26,1.82,0.39,2.6,0.83c23.8,13.57,47.58,27.17,71.35,40.77c0.51,0.29,0.93,0.73,1.67,1.32
		c-0.35,6.82-2.27,13.57-3.14,20.45c-0.85,6.74-2.05,13.44-2.94,20.18c-0.92,6.94-1.73,13.89-2.44,20.85
		c-0.69,6.76-1.23,13.54-1.74,20.32c-0.52,6.98-0.96,13.96-1.37,20.95C157.71,509.98,157.41,516.75,157.05,523.81z"
      />
      <path
        :class="colorClass"
        d="M126.92,1136.5c-0.97-1.58-1.73-2.75-2.43-3.96c-12.76-21.85,3.23-48.77,27.36-49.02c11.59-0.12,23.19-0.25,34.78,0.03
		c24.56,0.61,39.49,27.27,27.39,48.64c-0.78,1.38-1.63,2.72-2.6,4.35c0.88,1.48,1.83,2.97,2.69,4.51
		c11.76,20.89-2.56,47.35-26.47,48.38c-12.37,0.53-24.8,0.55-37.17-0.02c-23.37-1.07-37.69-26.8-26.75-47.49
		C124.65,1140.17,125.73,1138.5,126.92,1136.5z M169.23,1107.95c0-0.01,0-0.02,0-0.03c-3.59,0-7.19,0-10.78,0c-2,0-4-0.09-5.99,0.04
		c-4.59,0.29-7.92,3.73-7.98,8.1c-0.06,4.43,3.19,7.85,7.82,8.17c1.19,0.08,2.39,0.03,3.59,0.03c8.58,0,17.17,0,25.75,0
		c1.6,0,3.2,0.08,4.79-0.06c3.5-0.3,5.92-2.36,6.96-5.63c1.74-5.45-2.71-10.67-7.4-10.64
		C180.41,1107.98,174.81,1107.95,169.23,1107.95z M169.75,1148.85c-0.8,0-1.6,0-2.4,0c-4.79,0-9.58,0.02-14.37,0.01
		c-3-0.01-5.47,1.02-7.14,3.6c-3.48,5.39,0.03,12.28,6.52,12.67c2.98,0.18,5.99,0.04,8.98,0.04c7.98,0,15.97,0.08,23.95-0.04
		c5-0.07,8.45-3.51,8.49-8.13c0.04-4.67-3.28-7.86-8.48-8.13c-0.8-0.04-1.6-0.02-2.39-0.02
		C178.54,1148.85,174.14,1148.85,169.75,1148.85z"
      />
      <path
        :class="colorClass"
        d="M619.26,1189.59c-28.03,0.52-52.93-22.66-52.82-53.02c0.1-27.31,21.7-52.51,52.85-52.47
		c32.17,0.04,53.05,26.48,52.83,53.11C671.89,1166.4,647.81,1190.08,619.26,1189.59z M619.52,1106.19
		c-15.24-0.78-30.99,12.39-31.08,30.62c-0.08,16.74,13.62,30.44,30.31,30.91c15.61,0.43,31.14-12.5,31.38-30.44
		C650.38,1119.1,634.71,1105.61,619.52,1106.19z"
      />
      <path
        :class="colorClass"
        d="M380.76,1189.59c-26.46,0.58-52.56-20.68-52.82-52.4c-0.23-29.19,22.77-53.15,52.8-53.11
		c32.21,0.05,53.01,26.57,52.84,53.09C433.4,1165.88,409.68,1190.05,380.76,1189.59z M380.74,1106.19
		c-16.07-0.67-30.89,13.96-30.8,30.88c0.09,16.44,14.41,30.73,30.56,30.63c17.12-0.1,30.88-13.65,31.1-30.69
		C411.81,1120.12,396.97,1105.53,380.74,1106.19z"
      />
      <path
        :class="colorClass"
        d="M300.85,1132.94c-9.16,8.22-16.38,16.48-24.73,24.12c-16.24-15.94-32.07-31.73-47.64-47.52
		c-0.68-5.85-0.59-21.11,0.14-26.04c2.56-1.12,4.87-0.85,6.94,1.3c1.94,2.02,3.95,3.96,5.94,5.94
		c10.06,10.02,20.11,20.05,30.19,30.06c1.39,1.38,2.88,2.65,4.54,4.18c1.71-1.6,3.18-2.9,4.56-4.29
		c11.04-11.02,22.07-22.06,33.1-33.09c0.99-0.99,2-1.96,2.97-2.97c1.72-1.79,3.69-2.23,6.28-1.11c0.15,1.01,0.48,2.14,0.48,3.28
		c0.03,32.8,0.02,65.6,0.01,98.4c0,1.17-0.17,2.34-0.24,3.38c-4.31,1.24-15.28,1.42-21.94,0.36c-0.11-1.64-0.33-3.37-0.33-5.11
		c-0.03-14.6-0.01-29.2-0.03-43.8C301.09,1138.1,300.96,1136.16,300.85,1132.94z"
      />
      <path
        :class="colorClass"
        d="M109.99,1188.9c-5.94,1.04-17.4,0.93-22.09-0.21c-0.46-4.41-0.15-8.98-0.2-13.53c-0.05-4.59-0.01-9.19-0.01-13.78
		c0-4.39,0-8.79,0-13.18c0-4.58,0-9.17,0-13.53c-1.44-1.05-2.09-0.12-2.75,0.53c-6.38,6.33-12.75,12.68-19.12,19.01
		c-0.84,0.84-1.72,1.64-2.79,2.66c-1.15-0.95-2.26-1.73-3.2-2.67c-13.86-13.82-27.65-27.72-41.61-41.44
		c-2.66-2.61-3.79-5.26-3.64-8.96c0.27-6.56,0.07-13.15,0.07-19.76c2.73-1.75,5.04-1.56,7.22,0.45c1.03,0.95,1.99,1.96,2.98,2.95
		c11.18,11.14,22.35,22.28,33.54,33.41c1.39,1.38,2.86,2.69,4.54,4.26c1.84-1.78,3.42-3.27,4.96-4.8
		c11.59-11.58,23.19-23.14,34.73-34.76c1.96-1.97,3.92-3.43,7.15-2.04c0.14,0.75,0.48,1.69,0.48,2.62
		c0.03,33.36,0.02,66.71,0.01,100.07C110.25,1186.97,110.11,1187.75,109.99,1188.9z"
      />
      <path
        :class="colorClass"
        d="M767.33,1189.01c-3.73,1.68-5.99-0.49-8.35-2.85c-22.16-22.2-44.38-44.35-66.57-66.52c-2.69-2.68-5.3-5.44-8.04-8.06
		c-1.69-1.62-2.55-3.38-2.51-5.8c0.12-7.19,0.04-14.38,0.04-21.46c3.47-1.91,4.84-1.91,7.08,0.04c1.2,1.05,2.29,2.23,3.42,3.36
		c15.82,15.82,31.64,31.65,47.47,47.47c1.38,1.38,2.84,2.68,4.24,3.99c1.81-1.13,1.18-2.67,1.18-3.94
		c0.05-14.79,0.02-29.57,0.04-44.36c0-2.17,0.11-4.33,0.16-6.39c4.12-1.23,14.44-1.45,21.67-0.54c1.32,1.93,0.71,4.2,0.71,6.31
		c0.06,19.58,0.03,39.16,0.03,58.74c0,11.39,0.02,22.78-0.02,34.17C767.88,1185.15,768.31,1187.18,767.33,1189.01z"
      />
      <path
        :class="colorClass"
        d="M534.59,1083.61c7.58-0.15,14.52-0.23,21.49,0.04c1.17,2.41,0.72,4.65,0.73,6.78c0.04,30.8,0.03,61.6,0.02,92.39
		c0,1.96-0.13,3.93-0.2,5.79c-4.3,1.21-15.52,1.39-21.79,0.38c-0.17-1.06-0.51-2.2-0.51-3.35c-0.03-32.6-0.03-65.2-0.02-97.79
		C534.3,1086.67,534.46,1085.5,534.59,1083.61z"
      />
      <path
        :class="colorClass"
        d="M524.06,1083.95c1.01,5.26,1.01,16.82,0.06,21.6c-0.16,0.12-0.31,0.26-0.48,0.36c-0.17,0.1-0.35,0.21-0.54,0.21
		c-2.2,0.07-4.39,0.16-6.59,0.16c-28.38-0.03-56.76-0.07-85.13-0.13c-0.36,0-0.72-0.27-1.08-0.41c-1.07-3.94-1.28-14.03-0.42-21.4
		c1.91-1.33,4.18-0.71,6.3-0.72c21.58-0.05,43.17-0.03,64.75-0.03c6.2,0,12.39-0.02,18.59,0.02
		C521.07,1083.62,522.61,1083.84,524.06,1083.95z"
      />
      <path
        :class="colorClass"
        d="M868.91,1188.98c-2.28,1.13-4.62,0.42-7.01,0.48c-1.38-2.25-0.92-4.48-0.93-6.6c-0.04-15.78-0.02-31.57-0.02-47.35
		c0-5.59,0.02-11.19-0.02-16.78c-0.01-1.55-0.2-3.1-0.3-4.56c-2.08-0.73-2.74,0.86-3.63,1.74c-8.52,8.44-16.97,16.94-25.46,25.4
		c-2.4,2.39-4.86,4.71-7.08,6.84c-0.6,0-0.79,0.03-0.97-0.01c-0.19-0.04-0.41-0.11-0.54-0.24c-1.31-1.23-2.64-2.44-3.91-3.71
		c-13.84-13.84-27.67-27.69-41.43-41.47c0-3.66,0-7.03,0-10.36c1.98-0.84,2.59,0.83,3.46,1.69c12.61,12.54,25.17,25.12,37.75,37.69
		c1.54,1.54,3.13,3.04,5,4.85c5.17-5.13,10.11-10.04,15.05-14.95c4.96-4.93,9.91-9.86,14.87-14.8c4.87-4.86,9.65-9.81,15.42-15.47
		c0.21,2.7,0.39,4.03,0.41,5.35C870.17,1136.83,869.83,1184.21,868.91,1188.98z"
      />
      <path
        :class="colorClass"
        d="M964.66,1181.7c1.21,2.69,1.05,4.97,0.27,7.49c-1.47,0.14-2.83,0.36-4.19,0.39c-32.12,0.59-75.99,0.3-81.77-0.57
		c-0.16-1.04-0.48-2.18-0.48-3.32c-0.03-22.79-0.02-45.58-0.01-68.37c0-0.98,0.11-1.96,0.19-3.16c2.54-1.23,4.97-0.95,7.81-0.24
		c0.62,5.8-0.12,11.58,0.49,17.44c2.34,0.87,4.54,0.59,6.68,0.6c18.19,0.02,36.38,0,54.57,0.03c2.16,0,4.38-0.36,6.71,0.7
		c0,2.19,0,4.33,0,6.58c-1.86,1.59-4.16,0.88-6.27,0.89c-18.19,0.06-36.38,0.03-54.57,0.04c-2.17,0-4.33,0.12-6.84,0.19
		c-1.05,6.99-0.37,13.74-0.5,20.45c-0.12,6.58-0.03,13.16-0.03,19.5c2.33,1.78,4.61,1.17,6.73,1.17
		c21.59,0.05,43.18,0.03,64.77,0.04C960.39,1181.55,962.56,1181.65,964.66,1181.7z"
      />
      <path
        :class="colorClass"
        d="M970.62,1098.2c0-2.81,0-4.95,0-7.36c1.11-0.27,2.03-0.69,2.95-0.68c13.2,0.1,26.39,0.12,39.59,0.43
		c21.41,0.49,46.87,20.33,45.86,51.36c-0.77,23.78-17.82,41.83-37.51,46.52c-2.51,0.6-5.12,1.05-7.69,1.07
		c-13.2,0.11-26.4,0.05-39.6,0.04c-0.97,0-1.95-0.16-3.05-0.26c-1.34-2.38-0.62-4.67-0.61-6.87c2.2-1.28,4.24-0.88,6.18-0.89
		c11.2-0.04,22.42-0.44,33.6,0.07c18.79,0.86,43.33-17.44,40.28-46.08c-2.2-20.7-20.01-37.22-41.69-37.1
		c-10.6,0.06-21.2,0.02-31.8-0.01C975.15,1098.45,973.19,1098.31,970.62,1098.2z"
      />
      <path
        :class="colorClass"
        d="M465.86,1122.07c7.24-0.05,14.13-0.41,21.28,0.27c0.15,1.44,0.35,2.58,0.38,3.73c0.56,20.19,0.25,57.34-0.58,63.13
		c-3.36,0.82-6.93,0.35-10.44,0.38c-3.53,0.02-7.1,0.33-10.94-0.34c-0.12-1.43-0.28-2.57-0.31-3.72
		C464.72,1163.61,465.01,1128.15,465.86,1122.07z"
      />
      <path
        :class="colorClass"
        d="M250.29,1154.07c0.54,6.73,0.41,29.85-0.25,34.99c-3.3,1.08-6.88,0.4-10.38,0.51c-3.57,0.11-7.14,0.03-10.93,0.03
		c-0.28-1.48-0.68-2.6-0.68-3.72c-0.04-16.73-0.03-33.47-0.02-50.2c0-0.9,0.17-1.81,0.28-2.91
		C232.11,1135.06,244.41,1147.07,250.29,1154.07z"
      />
      <path
        :class="colorClass"
        d="M36.96,1153.89c0,11.62,0,23.18,0,34.67c-4.02,1.27-14.73,1.5-21.7,0.49c-0.18-0.85-0.56-1.78-0.57-2.72
		c-0.04-17.2-0.03-34.4-0.01-51.6c0-0.54,0.24-1.09,0.35-1.59c0.52-0.03,1.07-0.23,1.24-0.06
		C23.19,1139.99,30.08,1146.95,36.96,1153.89z"
      />
      <path
        :class="colorClass"
        d="M703.85,1189.02c-6.2,0.67-16.7,0.59-21.4-0.17c-1.14-4.42-0.39-9.01-0.52-13.54c-0.13-4.79-0.03-9.59-0.03-14.38
		c0-4.6-0.02-9.19,0-13.79c0.02-4.55-0.09-9.1,0.09-13.93c0.97,0.17,1.67,0.07,1.97,0.37c6.74,6.8,13.44,13.65,20.21,20.55
		C704.73,1161.78,704.56,1184.03,703.85,1189.02z"
      />
      <path
        :class="colorClass"
        d="M1131.71,1100.46c1.32-3.02,2.56-5.87,4.51-8.3c0.68,0.38,1.15,0.47,1.29,0.74c16.34,31.35,32.66,62.71,48.96,94.08
		c0.25,0.49,0.23,1.12,0.39,2.03c-2.98,0.94-5.83,0.68-8.99,0.18C1162.49,1159.62,1147.11,1130.06,1131.71,1100.46z"
      />
      <path
        :class="colorClass"
        d="M64.33,1241.25c3,0,5.34,0,7.72,0c-0.76,3.95-10.1,25.87-15.04,35.35c-0.33,0.62-0.97,1.08-1.67,1.85
		c-2.17-1.83-2.63-4.26-3.66-6.32c-0.98-1.96-1.72-4.05-2.71-6c-1.04-2.05-1.49-4.47-3.58-6.49c-2.39,3.2-3.3,6.67-4.9,9.78
		c-1.52,2.94-2.37,6.26-4.43,8.72c-0.65-0.11-0.86-0.09-1.01-0.19c-0.32-0.21-0.75-0.42-0.9-0.74c-5-11.09-9.97-22.2-14.92-33.32
		c-0.31-0.7-0.35-1.51-0.55-2.46c2.71-0.8,5.21-0.36,7.8-0.34c3.39,6.13,5.44,12.59,9.02,18.78c4.12-6.1,5.5-13.16,9.8-19.04
		c2.39,1.64,2.77,4.14,3.81,6.16c1,1.95,1.78,4.01,2.71,6c0.99,2.12,2.05,4.21,3.3,6.77c2-1.93,2.46-4.08,3.38-5.93
		c1.07-2.14,1.94-4.38,2.94-6.55C62.35,1245.3,63.32,1243.36,64.33,1241.25z"
      />
      <path
        :class="colorClass"
        d="M1068.39,1090.2c2.66,0,4.8,0,6.89,0c1.58,1.56,1.03,3.47,1.04,5.19c0.05,12.6,0.03,25.2,0.03,37.8
		c0,16.6,0.01,33.2-0.01,49.8c0,1.96-0.15,3.91-0.23,5.94c-2.64,0.91-4.94,0.82-7.53,0.16c-0.16-1.17-0.46-2.31-0.46-3.46
		c-0.02-30.6-0.02-61.2-0.01-91.8C1068.1,1092.86,1068.25,1091.88,1068.39,1090.2z"
      />
      <path
        :class="colorClass"
        d="M1110.89,1277.95c-3.3-5.29-6.45-10.35-9.51-15.27c-3.05,0-5.79,0-8.8,0c-1.01,5.13,0.06,10.15-0.78,15.17
		c-2.3,0.79-4.41,0.57-6.71,0.31c-1.45-2.49-0.76-5.12-0.86-7.62c-0.11-2.59-0.07-5.19-0.01-7.79c0.06-2.56-0.32-5.16,0.56-7.97
		c1.24-0.15,2.38-0.37,3.52-0.4c3.19-0.08,6.39,0.02,9.59-0.12c3.11-0.13,5.7-1.34,7.34-3.88c-0.12-0.54-0.11-0.77-0.23-0.9
		c-0.12-0.14-0.34-0.26-0.53-0.26c-6.38-0.13-12.75-0.25-19.07-0.37c-1.35-3.42-1.35-3.42-0.37-7.38
		c5.69-0.86,25.92-0.75,30.24,0.16c1.09,6.39,0.11,9.09-6.48,17.42c3.21,6.14,7.46,11.91,10.77,18.27
		C1115.99,1278.51,1115.99,1278.51,1110.89,1277.95z"
      />
      <path
        :class="colorClass"
        d="M914.31,1251.61c-3.4,0.09-6.39,0.98-8.7-1.91c-0.39-0.49-1.47-0.58-2.24-0.59c-3.39-0.06-6.78-0.04-10.17-0.01
		c-0.59,0-1.19,0.12-1.76,0.28c-1.34,0.38-2.26,1.76-2.14,3.21c0.1,1.31,0.77,2.37,2.04,2.64c1.54,0.32,3.15,0.37,4.73,0.45
		c2.98,0.14,6.02-0.08,8.95,0.38c5.84,0.92,9.56,5.55,9.48,11.28c-0.09,5.71-4.16,10.44-9.86,10.89c-4.36,0.34-8.77,0.14-13.16,0.13
		c-2.72-0.01-5.06-1.03-6.99-2.93c-1.89-1.87-3.1-4.06-3.14-7.13c2.98-0.8,5.67-1.68,8.25,0.93c0.75,0.76,2.07,1.26,3.15,1.32
		c3.18,0.17,6.38,0.08,9.57,0.04c0.59-0.01,1.18-0.16,1.75-0.33c1.38-0.42,2.29-1.73,2.26-3.16c-0.03-1.46-0.96-2.79-2.36-3.06
		c-1.16-0.22-2.37-0.27-3.56-0.29c-2.59-0.04-5.19,0.06-7.78-0.04c-6.64-0.26-11.53-5.08-11.55-11.28
		c-0.02-5.98,4.59-10.96,10.9-11.36c4.17-0.27,8.39-0.27,12.56,0.01C910.33,1241.45,913.7,1245.1,914.31,1251.61z"
      />
      <path
        :class="colorClass"
        d="M756.48,1254.45c4.12-0.04,7.89-0.09,11.66-0.11c2.9-0.02,5.54-0.73,7.65-2.82c0.39-0.38,0.59-0.95,1.09-1.78
		c-1.72-1.13-3.52-0.65-5.18-0.73c-1.79-0.08-3.59-0.02-5.39-0.02c-1.6,0-3.2,0.06-4.79-0.02c-1.75-0.09-3.61,0.43-5.46-0.93
		c0-1.98,0-4.13,0-6.24c4.1-1.1,23.58-1.31,30.38-0.36c1.58,6.06,0.84,8.22-6.13,17.78c3.37,6.09,7.44,11.95,10.98,18.42
		c-3.2,1.12-5.74,0.74-8.69,0.53c-3.21-5.09-6.49-10.3-9.81-15.56c-2.87,0-5.61,0-8.48,0c-1.64,5.01-0.01,10.08-1.14,15.1
		c-2.07,1.1-4.23,0.6-6.45,0.43c-0.27-0.53-0.69-1.03-0.75-1.56C755.48,1272.52,755.79,1258.59,756.48,1254.45z"
      />
      <path
        :class="colorClass"
        d="M878.74,1090.51c1.83-0.17,3.19-0.37,4.56-0.4c30.67-0.59,75.56-0.3,81.52,0.55c1.16,2.13,0.71,4.44,0.47,6.59
		c-1.62,1.77-3.53,1.18-5.24,1.18c-12.56,0.06-25.13,0.03-37.69,0.03c-12.36,0-24.73,0.01-37.09-0.01c-1.97,0-3.94-0.11-6-0.18
		C878.03,1095.79,878.51,1093.46,878.74,1090.51z"
      />
      <path
        :class="colorClass"
        d="M1050.08,1260.33c0.04,8.95-7.67,18.43-19.1,18.06c-10.39-0.34-18.3-8.61-18.15-19.06c0.12-8.33,6.73-18.28,19.14-18.15
		C1042.28,1241.3,1050.3,1249.96,1050.08,1260.33z M1031.27,1249.09c-5.93,0.09-10.52,4.72-10.57,10.68c-0.06,6.05,5,11.08,11,10.94
		c5.76-0.13,10.59-5.02,10.63-10.78C1042.39,1253.88,1037.41,1249,1031.27,1249.09z"
      />
      <path
        :class="colorClass"
        d="M632.98,1278.38c-10.52,0.11-18.55-7.87-18.83-18.31c-0.24-8.94,6.63-18.57,18.37-18.9c10.17-0.29,18.76,8.22,18.92,18.43
		C651.59,1269.9,643.37,1278.27,632.98,1278.38z M643.47,1260.11c0.11-6.01-4.3-10.68-10.19-11.03c-6.3-0.37-11.22,4.74-11.43,10.52
		c-0.22,5.92,4.94,11.13,10.91,11.12C638.55,1270.71,643.36,1265.94,643.47,1260.11z"
      />
      <path
        :class="colorClass"
        d="M712.78,1241.19c2.61,0,4.56,0,6.87,0c0.23,1.33,0.65,2.64,0.66,3.95c0.07,5.98-0.14,11.97,0.08,17.94
		c0.24,6.72-3.24,10.86-8.85,13.62c-6.65,3.27-15.18,1.88-20.64-3.13c-2.47-2.27-4.12-5.01-4.17-8.33
		c-0.12-7.57,0.02-15.14,0.1-22.72c0-0.34,0.37-0.67,0.63-1.12c2.05-0.71,4.19-0.52,6.68-0.03c0.18,1.28,0.51,2.61,0.52,3.95
		c0.07,5.18-0.06,10.37,0.1,15.55c0.05,1.73,0.44,3.6,1.23,5.13c1.59,3.08,4.41,4.66,7.85,4.52c3.69-0.15,6.46-2.09,7.78-5.58
		c0.62-1.63,0.81-3.49,0.86-5.26c0.12-4.18,0.02-8.37,0.05-12.56C712.55,1245.35,712.67,1243.58,712.78,1241.19z"
      />
      <path
        :class="colorClass"
        d="M1095,1189.38c-3.17,0-5.73,0-8.23,0c-1.12-1.95,0.28-3.2,0.94-4.47c8.42-16.31,16.9-32.59,25.37-48.88
		c2.02-3.89,4.03-7.8,6.09-11.68c0.54-1.03,1.22-1.99,2.08-3.39c2.27,2.78,3.42,5.64,4.75,8.8
		C1115.73,1149.49,1105.44,1169.29,1095,1189.38z"
      />
      <path
        :class="colorClass"
        d="M306.23,1262.78c-0.92,2.63-0.72,4.9-0.15,7.48c8.11,0.91,16.1-0.12,24.03,0.64c1.67,2.01,0.66,4.25,0.91,6.52
		c-0.8,0.33-1.51,0.88-2.21,0.88c-9.78,0.03-19.57,0-29.35-0.04c-0.36,0-0.72-0.26-1.09-0.4c-0.99-3.98-1.19-15.44-0.37-22.67
		c2.96-1.42,6.19-0.63,9.32-0.74c3.39-0.13,6.79-0.08,10.19-0.01c3.16,0.07,6.35-0.37,9.57,0.44c0.86,2.46,0.68,4.73,0.13,7.36
		C320.14,1262.95,313.16,1262.03,306.23,1262.78z"
      />
      <path
        :class="colorClass"
        d="M135.45,1262.42c-7.18,0.05-13.92,0.1-20.82,0.14c-1.17,2.53-0.82,4.83-0.43,7.49c1.34,0.19,2.66,0.52,3.99,0.54
		c4.99,0.07,9.98,0.01,14.98,0.04c1.76,0.01,3.53,0.15,5.46,0.25c0.95,2.28,0.6,4.41,0.39,6.76c-3.47,1.47-7.09,0.59-10.59,0.73
		c-3.79,0.15-7.59,0.1-11.38,0.02c-3.54-0.07-7.12,0.39-10.52-0.36c-1-5.02-1.03-17.78-0.12-22.79c4.11-1.05,21.11-1.25,28.8-0.31
		C136.28,1257.04,135.9,1259.35,135.45,1262.42z"
      />
      <path
        :class="colorClass"
        d="M785.34,1189.07c-2.43,0.77-4.75,0.62-7.34,0.14c-0.18-0.96-0.51-1.9-0.52-2.83c-0.03-19.32-0.03-38.65-0.02-57.97
		c0-0.59,0.15-1.18,0.23-1.72c1.19-0.88,1.79,0.14,2.52,0.7c0.16,0.12,0.28,0.28,0.43,0.42c5.27,5.02,5.28,5.02,5.28,12.34
		c0,14.34,0.01,28.69-0.01,43.03C785.91,1185.11,786.23,1187.13,785.34,1189.07z"
      />
      <path
        :class="colorClass"
        d="M1160.22,1241.09c3.54,3.69,5.47,7.98,9.17,11.33c2.93-3.83,5.68-7.44,8.59-11.24c2.68,0,5.44,0,8.64,0
		c-0.33,0.99-0.43,1.95-0.92,2.64c-3.11,4.4-6.2,8.82-9.52,13.05c-2.08,2.65-3.08,5.4-2.93,8.79c0.18,3.95-0.04,7.91-0.08,11.89
		c-2.63,1.21-4.91,0.96-7.33,0.43c-1.13-2.77-0.82-5.64-0.52-8.31c0.7-6.19-1.23-11.22-5.25-15.82c-2.61-2.99-4.77-6.39-7.09-9.64
		c-0.54-0.76-0.91-1.64-1.55-2.82C1154.64,1240.61,1157.36,1240.98,1160.22,1241.09z"
      />
      <path
        :class="colorClass"
        d="M577.92,1241.09c2.76-0.15,5.48-0.42,8.75,0.24c-0.73,1.31-1.18,2.35-1.83,3.25c-3.14,4.37-6.25,8.76-9.53,13.02
		c-1.79,2.32-2.72,4.73-2.57,7.7c0.17,3.38,0.04,6.77-0.01,10.16c-0.01,0.76-0.23,1.51-0.4,2.5c-2.26,0.59-4.37,0.54-6.48,0.12
		c-1.42-1.84-0.79-3.9-0.89-5.83c-0.19-3.77,0.48-7.78-0.63-11.24c-1.04-3.26-3.9-5.93-5.95-8.86c-2.37-3.39-5.01-6.6-7.17-10.09
		c1.3-1.66,2.78-1.01,4.05-1.11c1.38-0.11,2.78-0.02,4.32-0.02c3.58,3.18,5.43,7.8,9.2,11.3
		C572.54,1248.92,574.44,1244.37,577.92,1241.09z"
      />
      <path
        :class="colorClass"
        d="M374.26,1270.36c7.49,0.62,14.26-0.07,21.09,0.49c1.07,2.29,0.65,4.43,0.47,6.84c-4.9,1.46-9.89,0.57-14.79,0.67
		c-4.73,0.11-9.5,0.35-14.15-0.26c-0.3-0.99-0.62-1.54-0.62-2.1c-0.02-10.99-0.02-21.98,0.02-32.97c0-0.55,0.35-1.1,0.63-1.92
		c2.13,0,4.26,0,6.74,0c1.21,4.69,0.45,9.46,0.58,14.17C374.36,1260.06,374.26,1264.84,374.26,1270.36z"
      />
      <path
        :class="colorClass"
        d="M439.07,1270.35c7.47,0.6,14.23,0,21.02,0.43c1.18,2.32,0.69,4.47,0.56,6.89c-4.89,1.48-9.88,0.59-14.77,0.67
		c-4.73,0.08-9.51,0.53-14.21-0.42c-0.25-0.79-0.58-1.34-0.58-1.9c-0.03-10.99-0.03-21.98,0-32.97c0-0.55,0.34-1.1,0.62-1.94
		c2.12,0,4.25,0,6.77,0c0.2,1.3,0.55,2.62,0.57,3.93c0.06,6.19,0.03,12.39,0.03,18.58C439.07,1265.62,439.07,1267.61,439.07,1270.35
		z"
      />
      <path
        :class="colorClass"
        d="M970.83,1113.67c1.3-0.12,2.26-0.28,3.22-0.28c1.17,0,2.33,0.14,3.78,0.24c0.2,1.1,0.51,2.03,0.51,2.97
		c0.03,18.18,0.02,36.36,0,54.55c0,0.59-0.18,1.18-0.3,1.96c-1.87,1.7-4.11,2.8-6.99,3.38c-0.26-0.88-0.66-1.58-0.66-2.28
		c-0.03-19.38-0.02-38.76,0.01-58.14C970.41,1115.49,970.61,1114.91,970.83,1113.67z"
      />
      <path
        :class="colorClass"
        d="M229.11,1248.73c-0.74-2.72-0.86-5,0.04-7.21c5.41-0.77,27.45-0.75,32.36,0c0.89,3.24,0.89,3.24,0.2,6.79
		C257.59,1249.28,238.73,1249.55,229.11,1248.73z"
      />
      <path
        :class="colorClass"
        d="M980.96,1241.6c0.1,1.7,0.25,3.08,0.24,4.46c0,0.76-0.27,1.52-0.41,2.19c-4.33,1.03-22.86,1.3-32.49,0.51
		c-1.43-2.37-1.09-4.82-0.32-7.24C953.36,1240.75,975.39,1240.76,980.96,1241.6z"
      />
      <path
        :class="colorClass"
        d="M106.29,1248.68c-0.72-2.57-0.71-4.82-0.1-7.01c4.87-0.89,26.31-0.99,32.64-0.14c0.13,1,0.37,2.11,0.39,3.23
		c0.02,1.17-0.16,2.35-0.27,3.63c-3.55,1.35-7.15,0.54-10.66,0.67c-3.77,0.14-7.55,0.09-11.32,0.01
		C113.46,1249,109.89,1249.47,106.29,1248.68z"
      />
      <path
        :class="colorClass"
        d="M330.77,1241.29c0,2.81,0,4.93,0,6.99c-4.43,1-25.12,1.2-32.56,0.34c-1.07-2.11-0.64-4.42-0.36-6.89
		c3.45-1.51,7.05-0.58,10.54-0.73c3.77-0.16,7.55-0.07,11.33-0.03C323.26,1241.01,326.82,1240.73,330.77,1241.29z"
      />
      <path
        :class="colorClass"
        d="M249.21,1255.77c0,6.51,0.01,13.07-0.02,19.64c0,0.76-0.27,1.52-0.4,2.2c-3.53,0.79-3.53,0.79-6.98,0.08
		c-0.85-4.83-0.85-17.43,0.04-22.74C244.24,1254.43,246.67,1253.79,249.21,1255.77z"
      />
      <path
        :class="colorClass"
        d="M960.38,1255.09c2.48-0.82,4.59-0.73,6.98-0.16c1.47,3.79,0.64,7.79,0.69,11.67c0.04,3.73,0.61,7.53-0.5,11.18
		c-2.28,0.92-4.43,0.7-6.56,0.27C959.82,1274.24,959.57,1265.36,960.38,1255.09z"
      />
      <path
        :class="colorClass"
        d="M637.71,608.59c-1.04-0.95-2.14-1.76-3-2.77c-11.08-13.08-19.78-27.59-26.27-43.45c-1.04-2.55-1.12-4.84-0.29-7.5
		c3.92-12.45,6.11-25.24,6.85-38.28c1.02-17.94-1.03-35.53-5.87-52.82c-0.32-1.15-0.54-2.36-1.04-3.44
		c-1.09-2.39-0.68-4.58,0.28-6.92c4.24-10.41,9.33-20.37,15.73-29.6c3.41-4.92,7.17-9.61,10.83-14.36c0.71-0.91,1.68-1.62,2.58-2.46
		C671.96,462.64,673.23,550.11,637.71,608.59z"
      />
    </svg>
    <!-- </svg> -->
  </div>
</template>

<script>
export default {
  name: "textIcon",
  props: {
    width: Number,
    height: Number,
    colorClass: String,
    link: String,
  },
  methods: {
    handleLink: function () {
      if (this.link) {
        this.$router.push(this.link);
      }
    },
  },
  computed: {
    svgWidth: function () {
      if (this.width && this.width > 0) {
        return this.width;
      }
      return "100%";
    },
    svgHeight: function () {
      if (this.height && this.height > 0) {
        return this.height;
      }
      return "100%";
    },
  },
};
</script>

<style type="text/css" scoped>
.st1 {
  fill: red;
}
.st0 {
  fill: yellow;
}
.primary {
  fill: var(--v-primary-base);
}
.secondary {
  fill: var(--v-secondary-base);
}
.text {
  fill: var(--v-text-base);
}
.background {
  fill: var(--v-background-base);
}
.white {
  fill: #ffffff;
}
.black {
  fill: #000000;
}
.cls-1 {
  fill: transparent;
}
.cls-2 {
  fill: #162183;
}
.cls-3 {
  fill: #3fad3d;
}
.cls-4 {
  fill: transparent;
}
.cls-5 {
  fill: transparent;
}
.cls-6 {
  fill: transparent;
}
.cls-7 {
  fill: transparent;
}
.cls-8 {
  fill: transparent;
}
.cls-9 {
  fill: transparent;
}
</style>
