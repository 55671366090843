<template>
  <div class="prevent-full-width mx-auto mt-4">
    <div>
      <span class="heading4">Our Services</span>
    </div>
    {{ $vuetify.breakpoint.name }}
  </div>
</template>
<script>
export default {
  name: "Services",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Services",
      meta: [
        {
          name: "description",
          content: "Services of " + this.$applicationName,
        },
      ],
    };
  },
};
</script>
<style scoped>
.heading4 {
  font-size: 34px;
  font-weight: bold;
}
</style>
