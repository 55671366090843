<template>
  <v-app
    class="app"
    :style="{ background: $vuetify.theme.themes.dark.background.base }"
  >
    <Navigation
      v-if="$route.meta.drawer"
      :showPortfolioDrawer="$route.name != 'Home'"
    />
    <v-main>
      <!-- <v-container fluid class="ma-0 pa-0" style="min-height: 100vh"> -->
      <router-view />
      <!-- </v-container> -->
    </v-main>
    <Notfiy />
    <MainFooter />
  </v-app>
</template>

<script>
import Navigation from "./components/navigation.vue";
import Notfiy from "./components/notification.vue";
import MainFooter from "./components/mainFooter.vue";
export default {
  name: "App",
  metaInfo() {
    return {
      // all titles will be injected into this template
      titleTemplate: "%s | " + this.$applicationName,
    };
  },
  components: {
    Navigation,
    Notfiy,
    MainFooter,
  },
};
</script>

<style lang="scss">
body {
  background-color: #000000;
}
.app {
  /* overflow-y: auto; */
  /* overflow-x: hidden; */

  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;

  background: var(--v-background-base);
}

/* FONT */

@font-face {
  font-family: "AvenirNext";
  src: local("AvenirNext"),
    url(./fonts/avenir/AvenirNextLTPro-Regular.otf) format("opentype");
}

.app {
  font-family: "AvenirNext";
}

/* width / */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* / Track / */
::-webkit-scrollbar-track {
  background: var(--v-primary-base);
}

/* / Handle / */
::-webkit-scrollbar-thumb {
  background: var(--v-background-base);
}

/* / Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--v-secondary-base);
}

$width-1: 600px;
$width-2: 680px;
$width-3: 858px;
$width-4: 1100px;
$width-5: 1200px;
$width-6: 1330px;

$breakpoint-width-1: 600px;
$breakpoint-width-2: 700px;
$breakpoint-width-3: 956px;
$breakpoint-width-4: 1264px;
$breakpoint-width-5: 1504px;
$breakpoint-width-6: 1904px;

.prevent-full-width {
  width: 100%;
  @media (min-width: $breakpoint-width-1) {
    width: $width-1;
  }
  @media (min-width: $breakpoint-width-2) {
    width: $width-2;
  }
  @media (min-width: $breakpoint-width-3) {
    width: $width-3;
  }
  @media (min-width: $breakpoint-width-4) {
    width: $width-4;
  }
  @media (min-width: $breakpoint-width-5) {
    width: $width-5;
  }
  @media (min-width: $breakpoint-width-6) {
    width: $width-6;
  }
}
</style>
