<template>
  <div>
    <div class="greeting text-h4">
      {{ greeting() + " " + this.$globalState.profile.first_name }}!
    </div>
    <div class="dashboard">
      <div class="dashboard-row">
        <div class="dashboard-card-wrapper todo-card">
          <div class="dashboard-card">
            <div class="card-headline">
              <span>Last 5 Open ToDos</span>

              <v-btn small outlined color="primary" to="/todos"
                >Show ToDos</v-btn
              >
            </div>
            <v-data-table
              class="deepBlack"
              :headers="todoTable.headers"
              :items="todoTable.items"
              hide-default-footer
            >
              <template v-slot:[`item.done`]="{ item }">
                <v-icon small :color="item.done ? 'success' : 'error'">
                  {{
                    item.done
                      ? "mdi-checkbox-marked-outline"
                      : "mdi-checkbox-blank-outline"
                  }}
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </div>
        <div class="dashboard-card-wrapper tickets-card">
          <div class="dashboard-card">
            <div class="card-headline">
              <span>5 Last Updated Tickets</span>

              <v-btn small outlined color="primary" to="/ticketoverview"
                >Show Tickets</v-btn
              >
            </div>
            <v-data-table
              class="deepBlack"
              :headers="lastUpdatedTicketsTable.headers"
              :items="lastUpdatedTicketsTable.items"
              @click:row="handleTicketView"
              hide-default-footer
            >
              <template v-slot:[`item.done`]="{ item }">
                <span class="ml-n2">
                  <v-icon v-if="item.done" color="success"
                    >mdi-check-all</v-icon
                  >
                  <v-icon v-else color="error">mdi-alert-circle</v-icon>
                </span>
              </template>
              <template v-slot:[`item.client`]="{ item }">
                {{ item.first_name + " " + item.last_name }}
              </template>
            </v-data-table>
          </div>
        </div>
        <div class="dashboard-card-wrapper tickets-card">
          <div class="dashboard-card">
            <div class="card-headline">
              <span>5 Oldest Open Tickets</span>

              <v-btn small outlined color="primary" to="/ticketoverview"
                >Show Tickets</v-btn
              >
            </div>
            <v-data-table
              class="deepBlack"
              :headers="oldestOpenTicketsTable.headers"
              :items="oldestOpenTicketsTable.items"
              @click:row="handleTicketView"
              hide-default-footer
            >
              <template v-slot:[`item.done`]="{ item }">
                <span class="ml-n2">
                  <v-icon v-if="item.done" color="success"
                    >mdi-check-all</v-icon
                  >
                  <v-icon v-else color="error">mdi-alert-circle</v-icon>
                </span>
              </template>
              <template v-slot:[`item.client`]="{ item }">
                {{ item.first_name + " " + item.last_name }}
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
      <div class="dashboard-row">
        <div class="dashboard-card-wrapper last-added-projects-card">
          <div class="dashboard-card">
            <div class="card-headline">
              <span>Last 5 Added Projects</span>

              <v-btn small outlined color="primary" to="/projectmanager"
                >Show Projects</v-btn
              >
            </div>
            <v-data-table
              class="deepBlack"
              :headers="projectTable.headers"
              :items="projectTable.items"
              hide-default-footer
            >
              <template v-slot:[`item.genre_id`]="{ item }">
                {{ getGenreById(item.genre_id) }}
              </template>
            </v-data-table>
          </div>
        </div>
        <div class="dashboard-card-wrapper last-ticket-entries-card">
          <div class="dashboard-card">
            <div class="card-headline">
              <span>Last 5 Ticket Activites</span>

              <v-btn small outlined color="primary" to="/ticketoverview"
                >Show Tickets</v-btn
              >
            </div>
            <v-data-table
              class="deepBlack"
              :headers="lastTicketEntriesTable.headers"
              :items="lastTicketEntriesTable.items"
              @click:row="handleEntryView"
              hide-default-footer
            >
              <template v-slot:[`item.user_id`]="{ item }">
                <div v-if="item.user_id === $globalState.profile.id">You</div>
                <div v-else>{{ getUserById(item.user_id).display_name }}</div>
              </template>
              <template v-slot:[`item.message`]="{ item }">
                {{ parseMessage(item.message, item.mark) }}
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { supabase } from "../plugins/supabase/supabase";
export default {
  name: "AdminDashboard",
  async mounted() {
    await this.getLast5Todos();
    await this.getLast5AddedProjects();
    await this.get5LastUpdatedTickets();
    await this.get5OldestOpenTickets();
    await this.getUsers();
    await this.get5LastTicketEntries();
  },
  data() {
    return {
      users: [],
      todoTable: {
        headers: [
          {
            text: "State",
            align: "center",
            sortable: true,
            value: "done",
          },
          {
            text: "Todo",
            align: "center",
            sortable: true,
            value: "task",
          },
        ],
        items: [],
      },
      projectTable: {
        headers: [
          {
            text: "Project",
            align: "center",
            sortable: true,
            value: "title",
          },
          {
            text: "Genre",
            align: "center",
            sortable: true,
            value: "genre_id",
          },
        ],
        items: [],
      },
      lastUpdatedTicketsTable: {
        headers: [
          {
            text: "State",
            align: "center",
            sortable: true,
            value: "done",
          },
          {
            text: "Subject",
            align: "center",
            sortable: true,
            value: "subject",
          },
          {
            text: "Client",
            align: "center",
            sortable: true,
            value: "client",
          },
        ],
        items: [],
      },
      oldestOpenTicketsTable: {
        headers: [
          {
            text: "State",
            align: "center",
            sortable: true,
            value: "done",
          },
          {
            text: "Subject",
            align: "center",
            sortable: true,
            value: "subject",
          },
          {
            text: "Client",
            align: "center",
            sortable: true,
            value: "client",
          },
        ],
        items: [],
      },
      lastTicketEntriesTable: {
        headers: [
          {
            text: "User",
            align: "center",
            sortable: true,
            value: "user_id",
          },
          {
            text: "Activity",
            align: "center",
            sortable: true,
            value: "message",
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    parseMessage: function (message, mark) {
      let maxLength = 120;
      if (message.includes("[U-")) {
        let userId = message.split("[U-")[1].split("]")[0];
        let user = this.getUserById(userId);
        message = message.replace("[U-" + userId + "]", user.display_name);
      }
      if (message.includes("[S-")) {
        let status = message.split("[S-")[1].split("]")[0];
        message = message.replace("[S-" + status + "]", "'" + status + "'");
      }
      if (message.includes("[Q-")) {
        let quoteId = message.split("[Q-")[1].split("]")[0];
        message = message.replace("[Q-" + quoteId + "]", "QUOTED MESSAGE");
      }
      if (message.includes("[Att-")) {
        let attachmentId = message.split("[Att-")[1].split("]")[0];
        message = message.replace("[Att-" + attachmentId + "]", "ATTACHMENT");
      }
      if (mark === "mail") {
        message = "MAIL TO CLIENT: " + message;
      }
      if (message.length > maxLength) {
        message = message.substring(0, maxLength) + "...";
      }
      return message;
    },
    getUserById: function (id) {
      return this.users.find((user) => user.id === id);
    },
    getUsers: async function () {
      let payload = {
        token: supabase.auth.session().access_token,
      };
      let response = await fetch(this.$apiUrl + "/ticket/users/list", {
        method: "POST",
        body: JSON.stringify(payload),
      });
      let responseData = await response.json();
      if (responseData.code == 200) {
        this.users = responseData.users;
      }
    },
    handleTicketView: function (row) {
      this.$router.push({ name: "Ticket", params: { id: row.id } });
    },
    handleEntryView: function (row) {
      this.$router.push({
        name: "Ticket",
        params: { id: row.ticket_id },
        hash: "#e-" + row.id,
      });
    },
    getGenreById: function (id) {
      let validItems = this.$globalState.genres.filter((genre) => {
        return genre.id === id;
      });
      return validItems[0].name;
    },
    getLast5Todos: async function () {
      let { data: todos, error } = await supabase
        .from("todo")
        .select("*")
        .order("created", { ascending: false })
        .eq("worker_id", this.$globalState.profile.id)
        .eq("done", false)
        .limit(5);
      if (error) {
        console.log(error);
      }
      this.todoTable.items = todos;
    },
    getLast5AddedProjects: async function () {
      let { data: projects, error } = await supabase
        .from("project")
        .select("*")
        .order("created", { ascending: false })
        .limit(5);
      if (error) {
        console.log(error);
      }
      this.projectTable.items = projects;
    },
    get5LastUpdatedTickets: async function () {
      const { data: tickets, error } = await supabase
        .from("ticket")
        .select("*")
        .order("updated", { ascending: false })
        .limit(5);
      if (error) {
        console.log(error);
      } else {
        this.lastUpdatedTicketsTable.items = tickets;
      }
    },
    get5OldestOpenTickets: async function () {
      const { data: tickets, error } = await supabase
        .from("ticket")
        .select("*")
        .order("created", { ascending: true })
        .eq("done", false)
        .limit(5);
      if (error) {
        console.log(error);
      } else {
        this.oldestOpenTicketsTable.items = tickets;
      }
    },
    get5LastTicketEntries: async function () {
      const { data: tickets, error } = await supabase
        .from("entry")
        .select("*")
        .order("updated", { ascending: false })
        .limit(5);
      if (error) {
        console.log(error);
      } else {
        this.lastTicketEntriesTable.items = tickets;
      }
    },
    //function that returns greeting depending on time of day
    greeting() {
      let time = new Date().getHours();
      if (time < 12) {
        return "Guten Morgen";
      } else if (time < 18) {
        return "Hallo";
      } else {
        return "Guten Abend";
      }
    },
  },
};
</script>

<style scoped>
.greeting {
  height: 8vh;
  padding-left: 20px;
  display: flex;
  align-items: center;
}
.dashboard {
  height: 84vh;
  /* align items center */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dashboard-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50%;
}

.dashboard-card-wrapper {
  padding: 10px;
  width: 100%;
  height: 100%;
}
.todo-card {
  width: 20%;
}
.tickets-card {
  width: 40%;
}
.last-added-projects-card {
  width: 35%;
}
.last-ticket-entries-card {
  width: 65%;
}
.dashboard-card {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  outline: 1px solid var(--v-text-base);
}
.card-headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 20px;
}
.card-headline span {
  font-size: 1.2rem;
  font-weight: bold;
}
</style>
