<template>
  <div>
    <v-menu v-if="$globalState.isLoggedIn">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon class="ma-4" v-bind="attrs" v-on="on">
          <v-icon large color="text">mdi-account</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list class="deepBlack">
          <v-list-item>
            <v-list-item-avatar>
              <v-icon large>mdi-account</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-if="$globalState.profile">{{
                $globalState.profile.display_name
              }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn @click="handleSignOut" icon>
                <v-icon>mdi-logout</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider class="secondary"></v-divider>

        <v-list>
          <v-list-item
            v-for="item in $globalState.adminDrawerItems"
            :key="item.link"
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon> {{ item.icon }} </v-icon>
            </v-list-item-icon>
            <v-list-item-title> {{ item.name }} </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <!-- <v-btn class="ma-4" v-else icon to="/login">
      <v-icon large color="text">mdi-account</v-icon>
    </v-btn> -->
  </div>
</template>
<script>
import { supabase } from "../plugins/supabase/supabase";
export default {
  name: "adminMenu",
  methods: {
    handleSignOut: async function () {
      await supabase.auth.signOut();
      this.$globalState.isLoggedIn = false;
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
<style scoped></style>
