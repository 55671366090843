import Vue from "vue";
import { supabase } from "../supabase/supabase";

async function getGenres() {
  let response = await supabase.from("genre").select();
  let genres = [];
  if (response.data && response.data.length > 0) {
    genres = response.data;
  }
  return genres;
}

async function getGenreProjects(genreId) {
  let response = await supabase
    .from("project")
    .select()
    .match({ genre_id: genreId })
    .order("updated", { ascending: false });
  if (response.error) {
    let notify = {
      duration: 4000,
      type: "error",
      headline: "Loading Projects",
      message: "Failed loading Projects. Please try again later",
    };
    Vue.prototype.$globalState.addNotification(notify);
  } else if (response.data && response.data.length > 0) {
    return response.data;
  }
  return [];
}

function createDate(epoch) {
  let date = new Date(epoch);

  let day = ("0" + date.getDate()).slice(-2);
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear();
  let hours = ("0" + date.getHours()).slice(-2);
  let minutes = ("0" + date.getMinutes()).slice(-2);
  let seconds = ("0" + (date.getSeconds() + 1)).slice(-2);

  let timestamp =
    day +
    "." +
    month +
    "." +
    year +
    " - " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds;
  //  + " (GMT+1)";

  return timestamp;
}

export { getGenres, getGenreProjects, createDate };
