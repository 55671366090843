var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.project.video_url)?_c('div',[_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({class:'font-weight-bold ' +
                (_vm.$vuetify.breakpoint.mdAndUp ? 'heading4' : 'heading5'),on:{"click":_vm.copyProjectUrl}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.project.title)+" ")])]}}],null,false,3705481285)},[_c('span',[_vm._v("Click to copy the link to this project")])])],1)],1),_c('v-row',[_c('v-col',[(_vm.$vuetify.breakpoint.smAndUp)?_c('carousel-3d',{key:_vm.images.length,ref:"carousel",attrs:{"on-main-slide-click":_vm.onMainSlideClick,"border":_vm.carouselSettings.border,"autoplay":_vm.carouselSettings.autoplay,"autoplay-timeout":_vm.carouselSettings.autoplayTimeout,"height":_vm.carouselSettings.height,"width":_vm.carouselSettings.width}},_vm._l((_vm.images),function(image,i){return _c('slide',{key:i,staticClass:"slide",attrs:{"index":i}},[_c('img',{staticClass:"imageBorder",style:('max-height: ' +
                _vm.carouselSettings.height +
                'px; max-width: ' +
                _vm.carouselSettings.width +
                'px;'),attrs:{"src":_vm.$cdnUrl +
                image.key.replace('BREAKPOINT', _vm.$vuetify.breakpoint.name),"loading":"lazy"}})])}),1):(_vm.wallImages.length > 0)?_c('masonry-wall',{attrs:{"items":_vm.wallImages,"columnWidth":_vm.$vuetify.breakpoint.width / _vm.colsWallImages - 40,"gap":6},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"Item",style:('aspect-ratio: ' + item.meta.ratio),on:{"click":function($event){return _vm.handleMobileImageClick(item)}}},[_c('img',{staticClass:"detailimg",staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.$cdnUrl +
                  item.key.replace('BREAKPOINT', _vm.$vuetify.breakpoint.name),"loading":"lazy"}})])]}}],null,false,2118333092)}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"mx-2",staticStyle:{"text-align":"justify"}},[_vm._v(" "+_vm._s(_vm.project.description)+" ")])],1),(_vm.project.linkproject_id)?_c('v-row',[_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''},[_c('v-btn',{attrs:{"color":"primary","outlined":"","href":this.$appUrl +
            '/portfolio/videography#p-' +
            _vm.project.linkproject_id}},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.mdAndUp ? "Watch the video to this shoot" : "Project-Video")+" ")])],1)],1):_vm._e()],1):(_vm.project.video_url && _vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',[(_vm.index % 2 == 1)?_c('div',{staticClass:"video-row"},[_c('div',{staticClass:"video-frame"},[_c('img',{staticClass:"video-thumbnail right-gradient",attrs:{"src":_vm.customThumbnail},on:{"click":_vm.showVideo}}),_c('div',{staticClass:"top-left-text"},[_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"primary"},on:{"click":_vm.showVideo}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-play")])],1)]}}],null,false,2936215423)},[_c('span',[_vm._v("Click to play the video ")])])],1)]),_c('div',{staticClass:"video-description"},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"font-weight-bold",class:_vm.$vuetify.breakpoint.lgAndUp ? 'heading4' : 'heading5',on:{"click":_vm.copyProjectUrl}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.project.title)+" ")])]}}],null,false,228010695)},[_c('span',[_vm._v("Click to copy the link to this project")])])],1),_c('div',{staticStyle:{"hyphens":"auto"}},[_vm._v(" "+_vm._s(_vm.project.description)+" ")]),(_vm.project.linkproject_id)?_c('div',{staticClass:"my-4"},[_c('v-btn',{attrs:{"color":"primary","outlined":"","href":this.$appUrl +
              '/portfolio/photography#p-' +
              _vm.project.linkproject_id}},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.lgAndUp ? "See the photos to this shoot" : "Project-Photos")+" ")])],1):_vm._e()])]):_c('div',{staticClass:"video-row"},[_c('div',{staticClass:"video-description"},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"font-weight-bold",class:_vm.$vuetify.breakpoint.lgAndUp ? 'heading4' : 'heading5',on:{"click":_vm.copyProjectUrl}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.project.title)+" ")])]}}])},[_c('span',[_vm._v("Click to copy the link to this project")])])],1),_c('div',{staticStyle:{"hyphens":"auto"}},[_vm._v(" "+_vm._s(_vm.project.description)+" ")]),(_vm.project.linkproject_id)?_c('div',{staticClass:"my-4"},[_c('v-btn',{attrs:{"color":"primary","outlined":"","href":this.$appUrl +
              '/portfolio/photography#p-' +
              _vm.project.linkproject_id}},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.lgAndUp ? "See the photos to this shoot" : "Project-Photos")+" ")])],1):_vm._e()]),_c('div',{staticClass:"video-frame"},[_c('img',{staticClass:"video-thumbnail left-gradient",attrs:{"src":_vm.customThumbnail},on:{"click":_vm.showVideo}}),_c('div',{staticClass:"top-right-text"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"primary"},on:{"click":_vm.showVideo}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-play")])],1)]}}])},[_c('span',[_vm._v("Click to play the video")])])],1)])])]):(_vm.project.video_url && _vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('div',{staticClass:"video-col-mobile"},[_c('div',{staticClass:"video-frame-mobile"},[_c('img',{staticClass:"video-thumbnail bottom-gradient",attrs:{"src":_vm.customThumbnail},on:{"click":_vm.showVideo}}),_c('div',{staticClass:"top-right-text"},[_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"primary"},on:{"click":_vm.showVideo}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-play")])],1)]}}])},[_c('span',[_vm._v("Click to play the video ")])])],1)]),_c('div',{staticClass:"video-description-mobile"},[_c('div',{staticClass:"video-description-col-mobile px-2"},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"heading5 font-weight-bold",on:{"click":_vm.copyProjectUrl}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.project.title)+" ")])]}}])},[_c('span',[_vm._v("Click to copy the link to this project")])])],1),_c('div',{staticStyle:{"hyphens":"auto"}},[_vm._v(" "+_vm._s(_vm.project.description)+" ")]),(_vm.project.linkproject_id)?_c('div',{staticClass:"my-4"},[_c('v-btn',{attrs:{"color":"primary","outlined":"","href":this.$appUrl +
                '/portfolio/photography#p-' +
                _vm.project.linkproject_id}},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.lgAndUp ? "See the photos to this shoot" : "Project-Photos")+" ")])],1):_vm._e()])])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }