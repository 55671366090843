import { render, staticRenderFns } from "./Gear.vue?vue&type=template&id=0aa37c4e&scoped=true&"
import script from "./Gear.vue?vue&type=script&lang=js&"
export * from "./Gear.vue?vue&type=script&lang=js&"
import style0 from "./Gear.vue?vue&type=style&index=0&id=0aa37c4e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0aa37c4e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VDivider})
